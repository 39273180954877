
const translateCloudflareStatus = (cloudFlareStatus, premiumStatus) => {
  let cfVal = '0';
  let epcVal = false;
  if (cloudFlareStatus === 'Active') {
    if (premiumStatus) {
      if (premiumStatus.toLowerCase() === 'premium') {
        cfVal = 'premium';
      } else {
        cfVal = 'basic';
      }
    } else {
      cfVal = 'basic';
    }
  }
  epcVal = !parseInt(cfVal);
  return { cf: cfVal, epc: epcVal };
};
export default translateCloudflareStatus;
