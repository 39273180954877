import { number, oneOfType, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { useSitesSecuritySslStatus } from '@newfold/huapi-js';

import StatusListItem from '~/components/StatusListItem';

const propTypes = {
  siteId: oneOfType([number, string]),
};

const SslStatus = ({ siteId = undefined }) => {
  const { t } = useTranslation(['site', 'security']);

  // GET: SSL status
  const { data, isLoading, isError } = useSitesSecuritySslStatus(siteId, {
    query: { enabled: !!siteId },
  });

  const sslStatus = data?.data?.ssl_status || 'undefined';

  const navigate = useNavigate();

  const handleLink = (url) => {
    navigate(url);
  };

  return (
    <StatusListItem
      isLoading={isLoading}
      isError={isError}
      dataTestId={'active' === sslStatus ? 'sslStatusON' : 'sslStatusOFF'}
      avatarIcon={
        'active' === sslStatus ? LockOutlinedIcon : LockOpenOutlinedIcon
      }
      avatarIconColor={'active' === sslStatus ? 'success' : 'error'}
      tooltipText={
        'active' !== sslStatus
          ? t(`security:sslCertificate.stage.${sslStatus}.tooltipDesc`)
          : ''
      }
      ctaText={t('security:manageBtnLabel')}
      handleCTA={() => handleLink('security#ssl-cert')}
    >
      {t('sslMsg', {
        siteSslStatus: t(
          `security:sslCertificate.stage.${sslStatus}.titleShort`,
        ),
      })}
    </StatusListItem>
  );
};

SslStatus.propTypes = propTypes;

export default SslStatus;
