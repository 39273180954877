// Remove SX props
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import ContentCopy from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useSitesSshUserList } from '@newfold/huapi-js';

import Copy from '~/components/Copy';
import { useSiteContext } from '~/scenes/Site';

import ActionHandlers from './components/ActionsMenu';
import RemoveUser from './components/RemoveUser';
import UserEmpty from './components/UserEmpty';

const SSHUsers = () => {
  const { t } = useTranslation('sshUserManager', { keyPrefix: 'sshUser' });
  const { siteId } = useParams();
  const navigate = useNavigate();

  const { isSiteOrHostingDisabled: isDisabled } = useSiteContext();

  const { data, isLoading, isError } = useSitesSshUserList(siteId);
  const sshUsers = data?.data?.users;

  const SSHUserHeaderCard = () => {
    return (
      <Card>
        <CardHeader title={t('mainHeading')} />
        <CardContent>
          <>
            <Typography>{t('desc')}</Typography>
            <Grid container spacing={3} marginTop={1}>
              <Grid item xs>
                <Stack direction="row" spacing={1}>
                  <Typography>{t('Server')}:</Typography>{' '}
                  <strong>{t('serverVal')}</strong>
                </Stack>
              </Grid>
            </Grid>
          </>
        </CardContent>
      </Card>
    );
  };
  return (
    <Stack spacing={2}>
      <SSHUserHeaderCard />
      <Card>
        <CardHeader
          title={t('heading')}
          action={
            <Button
              disabled={isError || isDisabled}
              variant="contained"
              onClick={() => navigate('users/add')}
            >
              {t('buttonAdd')}
            </Button>
          }
          sx={{ mr: 1 }}
        />

        <CardContent data-testid="users-list">
          <Stack spacing={3}>
            {sshUsers?.length === 0 && !isLoading && !isError ? (
              <UserEmpty />
            ) : (
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  {!isError && (
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('userName')}</TableCell>
                        <TableCell colSpan={3}>{t('command')}</TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {isError && (
                      <Grid
                        sx={{ mt: 2, mb: 2 }}
                        container
                        justifyContent="center"
                        direction="column"
                        columns={1}
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="h4">
                            {t('loadFailed')}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    {isLoading && (
                      <TableRow tabIndex={-1}>
                        <TableCell>
                          <Skeleton width={220} />
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Skeleton width={220} />
                        </TableCell>
                      </TableRow>
                    )}

                    {!isLoading &&
                      sshUsers?.map((user) => (
                        <TableRow key={user}>
                          <TableCell>{user}</TableCell>
                          <TableCell>
                            <Stack spacing={1}>
                              <Copy
                                copyValue={`${user}@ssh.atomicsites.net`}
                                icon={
                                  <ContentCopy
                                    sx={{
                                      fontSize: '12px',
                                    }}
                                  />
                                }
                              >
                                <Typography variant="body2">{`${user}@ssh.atomicsites.net`}</Typography>
                              </Copy>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <ActionHandlers
                              userName={user}
                              isDisabled={isDisabled}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Routes>
              <Route path="users/remove" element={<RemoveUser id={siteId} />} />
            </Routes>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default SSHUsers;
