import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import InfoListItem from '~/components/InfoListItem';
import SsoLink from '~/components/SsoLink';
import DocRoot from '~/scenes/Site/components/DocRoot';

import NameserverInfo from '../../../../Hosting/components/NameserverInfo';

const QuickConnect = () => {
  const { t } = useTranslation('site');
  const { nameservers, ssh } = t('quickConnect.items', { returnObjects: true });

  return (
    <Stack spacing={1}>
      <Typography variant="h3">{t('quickConnect.title')}</Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', lg: 'space-between' }}
        alignItems="flex-start"
        spacing={2}
      >
        <InfoListItem
          key={ssh?.id}
          title={ssh?.secondayLabel}
          tooltipText={ssh?.tooltipText}
        >
          <SsoLink
            variant="text"
            type={'hosting'}
            queryParams={{ app: 'sshkeys' }}
            data-testid="btn-advanced"
            aria-disabled
          >
            {ssh?.secondaryCta}
          </SsoLink>
        </InfoListItem>
        <InfoListItem
          title={t('docRoot.title')}
          tooltipText={t('docRoot.tooltip')}
        >
          <DocRoot />
        </InfoListItem>
        <InfoListItem
          key={nameservers.id}
          title={nameservers.label}
          tooltipText={nameservers.tooltipText}
        >
          <NameserverInfo />
        </InfoListItem>
      </Stack>
    </Stack>
  );
};

export default QuickConnect;
