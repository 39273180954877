import { XyzTransition } from '@animxyz/react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useHostingInfo from '~/hooks/useHostingInfo';

import AlertBanner from './components/AlertBanner';
import HostingLoading from './scenes/HostingLoading';

const Hosting = () => {
  const { t } = useTranslation('hosting');

  const {
    hostingAccount,
    isInitialLoading: isLoadingHostingAccount,
    isError,
    errorStatusCode,
    isLoading,
    isPending,
  } = useHostingInfo();

  return (
    <XyzTransition
      // xyz="fade left"
      // appear
      // duration="auto"
      // mode="out-in"
      data-testid="hosting-detail"
    >
      <Container data-testid="hosting-details-container">
        {isLoading || isError || !hostingAccount || isPending ? (
          <HostingLoading
            loading={isLoading}
            pending={isPending}
            statusCode={errorStatusCode}
            isError={isError}
          />
        ) : (
          <Stack spacing={3}>
            <AlertBanner />
            <Typography variant="h1">
              {isLoadingHostingAccount ? (
                <Skeleton variant="text" width="30%" />
              ) : (
                hostingAccount?.data?.billing?.product_name ?? t('planTitle')
              )}
            </Typography>
            <Outlet />
          </Stack>
        )}
      </Container>
    </XyzTransition>
  );
};

export default Hosting;
