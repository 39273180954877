// Remove SX props
import { arrayOf, func, string } from 'prop-types';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const propTypes = {
  roles: arrayOf(string).isRequired,
  setTextFilter: func.isRequired,
  dropdownFilter: string.isRequired,
  setDropdownFilter: func.isRequired,
};

const TextFilterInput = ({ onChange, ...rest }) => {
  const { t } = useTranslation('users');

  return (
    <TextField
      fullWidth
      id="text-filter-input"
      placeholder={t('filterUsers')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
    />
  );
};

const UsersFilter = ({
  roles,
  setTextFilter,
  dropdownFilter,
  setDropdownFilter,
}) => {
  const { t } = useTranslation('users');

  return (
    <>
      <Grid xs={12} md={4} item>
        <TextFilterInput
          onChange={setTextFilter}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
            mb: 3,
          }}
        />
      </Grid>
      <Grid xs={12} md={8} item>
        <Box display="flex" justifyContent="flex-end">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={dropdownFilter}
              onChange={(e) => setDropdownFilter(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                },
                mb: 3,
              }}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextFilterInput
          fullWidth
          onChange={setTextFilter}
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
            mb: 3,
          }}
        />
      </Grid>
      <Grid xs={12} md={8} item>
        <Box display="flex" justifyContent="flex-end">
          <FormControl fullWidth>
            <Select
              value={dropdownFilter}
              onChange={(e) => setDropdownFilter(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
                mb: 3,
              }}
            >
              <MenuItem value="">{t('all')}</MenuItem>
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </>
  );
};

UsersFilter.propTypes = propTypes;
export default UsersFilter;
