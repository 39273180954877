import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useBrandInfo } from '~/hooks/useBrandInfo';

export default function Error() {
  const { supportLink } = useBrandInfo();

  return (
    <Alert
      //@ts-expect-error
      border="none"
      severity="error"
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <AlertTitle>We were unable to load your hosting packages</AlertTitle>
      <Typography>
        Please try refreshing the page. If the issue persists, please contact{' '}
        <Link href={supportLink} target="_blank">
          support
        </Link>{' '}
        for assistance.
      </Typography>
    </Alert>
  );
}
