import { func, node, oneOfType, shape, string } from 'prop-types';

import GlobalStyles from '@mui/material/GlobalStyles';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import ThemeProvider from '@mui/system/ThemeProvider';

import styleOverrides from './styleOverrides';
import { useNewfoldBrandTheme } from './themeDefault';

const propTypes = {
  children: oneOfType([node, func]),
  theme: shape({}),
  selector: string,
};

const MUI = ({
  children = undefined,
  theme: themeFromProps = undefined,
  mode = 'light',
  selector = 'hosting-app',
}) => {
  const defaultTheme = useNewfoldBrandTheme(mode);
  const theme = themeFromProps || defaultTheme;
  return (
    <>
      <GlobalStyles
        styles={{
          ...styleOverrides(theme, `#${selector}, .mat-typography`),
        }}
      />
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline sx={{ backgroundColor: 'inherit' }}>
          {children}
        </ScopedCssBaseline>
      </ThemeProvider>
    </>
  );
};

MUI.propTypes = propTypes;
export default MUI;
