import { useTranslation } from 'react-i18next';

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SitesDomainsV2200DomainsItem } from '@newfold/huapi-js/src/index.schemas';

type DnsStatusOptions = {
  domain?: SitesDomainsV2200DomainsItem;
  isDisabled?: boolean;
};

const DnsStatus = ({
  domain = undefined,
  isDisabled = false,
}: DnsStatusOptions) => {
  const { t } = useTranslation('domains');

  const pointedStatuses = ['host', 'cdn'];
  const isAutoGenerated = domain?.autogen_id !== null;
  const isPointed =
    pointedStatuses.includes(domain?.dns_status!) || isAutoGenerated;

  return (
    <div data-testid={'domain-row-dns-status'}>
      {isPointed ? (
        <Stack
          direction="row"
          spacing={1}
          alignItems={'center'}
          data-testid={'domain-dns-status'}
        >
          <CheckCircleOutline color="success" />
          <Typography variant={'body2'} component={'span'}>
            {t('pointed')}
          </Typography>
        </Stack>
      ) : (
        <Button
          data-testid={'domain-finish-setup'}
          variant="outlined"
          href={`${domain?.domain}/dns`}
          disabled={isDisabled}
        >
          {t('finishSetup')}
        </Button>
      )}
    </div>
  );
};

export default DnsStatus;
