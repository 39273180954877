import { useBrandInfo } from '~/hooks/useBrandInfo';

export default function Main({ children }: { children: React.ReactNode }) {
  const { hostApplication } = useBrandInfo();

  // some style changes will apply specifically to the dreamscape host application platform
  const isDreamscape = hostApplication === 'dreamscape';

  return (
    <section
      className="main"
      style={{
        maxWidth: isDreamscape ? undefined : '1200px',
        minHeight: '60vh',
        backgroundColor: 'inherit',
        paddingTop: '56px',
        paddingBottom: '56px',
      }}
    >
      {children}
    </section>
  );
}
