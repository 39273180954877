import { isNumber } from 'lodash';
import { ChangeEvent, PropsWithChildren, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useHostingAccount } from '@newfold/huapi-js';
import { SitesInfo200ResourcesStorage } from '@newfold/huapi-js/src/index.schemas';

import useAccount from '~/hooks/useAccount';
import { validateStorage } from '~/scenes/Cloud/utils/validations';
import { blockInvalidNumberChar } from '~/utils/blockInvalidNumberChar';

import { calculateMaxStorageAllowed } from '../utils/manage-resources';

interface StorageTypes {
  showLabel?: boolean;
  showStats?: boolean;
  defaultValue: number | undefined | null;
  siteId?: number | undefined;
  siteStorage?: SitesInfo200ResourcesStorage;
  isLoading?: boolean;
  isStorageDisabled?: boolean;
  hideHelperText?: boolean;
}

const Storage = ({
  showLabel = false,
  showStats = false,
  defaultValue,
  siteId = undefined,
  siteStorage = undefined,
  isLoading = false,
  isStorageDisabled = false,
  hideHelperText = false,
  ...props
}: PropsWithChildren<StorageTypes>) => {
  const { t } = useTranslation('cloud');
  const { register, watch, setValue } = useFormContext();

  const storageRegisteredId = isNumber(siteId)
    ? `${siteId}-disk_limit_gib`
    : 'disk_limit_gib';
  const value = watch(storageRegisteredId, defaultValue);

  const { id: hostingId } = useAccount();
  const { data: hostingAccountData, isInitialLoading: isHostingLoading } =
    useHostingAccount(hostingId, {
      query: {
        enabled: !!hostingId,
        select: ({ data }) => data,
      },
    });

  const storage = siteStorage
    ? siteStorage
    : hostingAccountData?.resources?.storage;

  const storageAvailable = hostingAccountData?.resources?.storage?.available;
  const siteUsed = siteStorage?.used ? siteStorage?.used : 0;
  const siteTotal = siteStorage?.total;

  const minStorageAllowed = siteUsed > 1 ? siteUsed : 1;
  const maxStorageAllowed = calculateMaxStorageAllowed({
    siteTotal,
    storageAvailable,
  });

  const [currAvailable, setCurrAvailable] = useState(
    maxStorageAllowed - Number(siteTotal),
  );

  const { isValid, errorMessage } = validateStorage({
    value,
    min: minStorageAllowed,
    max: Number(maxStorageAllowed),
  });

  const calculateStorage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const currSiteTotal = event?.target?.value;
    setValue(storageRegisteredId, currSiteTotal);

    if (showStats) {
      const remainingAvailable =
        Number(maxStorageAllowed) - Number(currSiteTotal);
      const available =
        remainingAvailable >= 0 ? remainingAvailable : maxStorageAllowed;
      setCurrAvailable(Number(available));
    }
  };

  if (isLoading || isHostingLoading)
    return <Skeleton width={260} height={56} />;

  return (
    <Stack spacing={0.5}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2}
        // this should not always be centered (such as mobile design, add site flow)
        alignItems={showStats ? 'center' : undefined}
      >
        <TextField
          id={storageRegisteredId}
          type="number"
          {...(showLabel && {
            label: t('hosting.overview.adjustStorage.storageLabel'),
          })}
          data-testid="storage-input"
          variant="outlined"
          value={value}
          error={!isValid}
          onKeyDown={blockInvalidNumberChar}
          disabled={isStorageDisabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{storage?.unit}</InputAdornment>
            ),
          }}
          {...register(storageRegisteredId)}
          onChange={calculateStorage}
          {...props}
        />
        {showStats && (
          <div>
            <Typography color={'grey.700'}>
              {t('settings.adjustStorage.allocated')}
            </Typography>
          </div>
        )}
      </Stack>
      {!hideHelperText && !isValid && (
        <FormHelperText error>{errorMessage}</FormHelperText>
      )}
      {showStats && (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          {!isNaN(Number(siteUsed)) && (
            <Typography variant="h4">
              {t('settings.adjustStorage.storageUsed', {
                used: siteUsed,
                unit: storage?.unit,
              })}
            </Typography>
          )}
          {!isNaN(Number(currAvailable)) && (
            <Typography variant="h4">
              {t('settings.adjustStorage.storageAvailable', {
                amount: currAvailable,
                unit: storage?.unit,
              })}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default Storage;
