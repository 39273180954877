import { useTranslation } from 'react-i18next';

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SitesDomainsV2200DomainsItem } from '@newfold/huapi-js/src/index.schemas';

type SslStatusOptions = {
  domain?: SitesDomainsV2200DomainsItem;
};

const SslStatus = ({ domain = undefined }: SslStatusOptions) => {
  const { t } = useTranslation('domains');
  const hasSsl = !!domain?.ssl_status;
  const sslIsSecure = hasSsl && domain?.ssl_status === 'secure';
  const sslStatusText = hasSsl
    ? sslIsSecure
      ? 'secure'
      : 'insecure'
    : 'notFound';

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems={'center'}
      data-testid={'domain-row-ssl-status'}
    >
      {sslIsSecure ? (
        <CheckCircleOutline color="success" data-testid={'domain-ssl-secure'} />
      ) : (
        <WarningRoundedIcon color="error" data-testid={'domain-ssl-insecure'} />
      )}
      <Typography variant={'body2'} component={'span'}>
        {t(`ssl.${sslStatusText}`)}
      </Typography>
    </Stack>
  );
};

export default SslStatus;
