export const filterByRole =
  (dropdownFilter) =>
  ({ role }) => {
    if (dropdownFilter) {
      return role.toLowerCase() === dropdownFilter.toLowerCase();
    }
    return true;
  };

export const filterByNameEmailRole =
  (textFilter) =>
  ({ display_name, user_email, role }) => {
    if (textFilter) {
      return Object.values({ display_name, user_email, role })
        .join(' ')
        .toLowerCase()
        .includes(textFilter.toLowerCase());
    }
    return true;
  };
