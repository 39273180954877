// Refactor mobile and Desktop view for Cards and Table
import { number } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import SsoLink from '~/components/SsoLink';

const propTypes = {
  userId: number,
};

const SsoMenu = ({ userId = null }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation(['users', 'site']);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const displayMobile = {
    display: { xs: 'block', md: 'none' },
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen} data-testid="menu-btn">
        <MoreVertRounded />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <SsoLink
          variant="menuItem"
          type="site"
          queryParams={{ app: 'profile' }}
          sx={displayMobile}
          data-testid="menu-item-login"
          userId={userId}
          onAction={handleMenuClose}
        >
          {t('login')}
        </SsoLink>
        <SsoLink
          variant="menuItem"
          type="site"
          queryParams={{ app: 'profile' }}
          userId={userId}
          onAction={handleMenuClose}
        >
          {t('editProfile')}
        </SsoLink>
        <SsoLink
          variant="menuItem"
          type="site"
          queryParams={{ app: 'password' }}
          userId={userId}
          onAction={handleMenuClose}
        >
          {t('resetPass')}
        </SsoLink>
      </Menu>
    </>
  );
};

SsoMenu.propTypes = propTypes;
export default SsoMenu;
