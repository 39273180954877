import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type JetpackBackupProps = {
  planName?: String;
};

const JetpackBackup = ({ planName = '' }: JetpackBackupProps) => {
  const { t } = useTranslation('site');
  return (
    <Stack justifyContent={'space-between'} spacing={1}>
      <Typography variant="h2" noWrap>
        {t('cloud.backups.jetpack.plan')}
      </Typography>
      {<Typography noWrap>{planName}</Typography>}
    </Stack>
  );
};

export default JetpackBackup;
