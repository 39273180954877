import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const useResponsive = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const isTablet = !useMediaQuery(theme.breakpoints.up('md'));

  return {
    isMobile: () => isMobile,
    isTablet: () => isTablet,
    isDesktop: () => !isMobile, // Can we changes this?
    // These functions appear to break some transition/transform attrs for the swipable drawer
    MobileView: ({ children }) => (!isMobile ? null : <>{children}</>),
    TabletView: ({ children }) => (isTablet ? <>{children}</> : null),
    BrowserView: ({ children }) => (isMobile ? null : <>{children}</>),
  };
};

export default useResponsive;
