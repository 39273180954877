import { XyzTransition } from '@animxyz/react';
import { lazy } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import { useSitesInfo } from '@newfold/huapi-js';

import useQueryFeature from '~/hooks/useQueryFeature';

import Overview from '../scenes/Overview';

const Security = lazy(() => import('../scenes/Security'));
const Backups = lazy(() => import('../scenes/Backups'));
const Plugins = lazy(() => import('../scenes/Plugins'));
const Users = lazy(() => import('../scenes/Users'));
const Speed = lazy(() => import('../scenes/Speed'));
const Domains = lazy(() => import('../scenes/Domains'));
const Settings = lazy(() => import('../scenes/Settings'));
const Advanced = lazy(() => import('../scenes/Advanced'));

const propTypes = {};

export const getPages = (site, codeguardDetails = {}, options = {}) => {
  return [
    {
      Component: Overview,
      title: 'Overview',
      path: '',
      canShow: true,
      index: true,
    },
    {
      Component: Security,
      title: 'Security',
      path: 'security',
      canShow: true,
      index: false,
    },
    {
      Component: Backups,
      title: 'Backups',
      path: 'backups',
      canShow: true,
      index: false,
    },
    {
      Component: Plugins,
      title: 'Plugins',
      path: 'plugins',
      canShow: site?.type === 'wordpress',
      index: false,
      disabled:
        site && site.type === 'wordpress' && site.type !== site.detected_type,
    },
    {
      Component: Users,
      title: 'Users',
      path: 'users',
      canShow: site?.type === 'wordpress',
      index: false,
      disabled:
        site && site.type === 'wordpress' && site.type !== site.detected_type,
    },
    {
      Component: Speed,
      title: 'Speed',
      path: 'speed',
      canShow: site?.type === 'wordpress',
      index: false,
    },
    {
      Component: Domains,
      title: 'Domains',
      path: 'domains',
      canShow: true,
      index: false,
    },
    {
      Component: Advanced,
      title: 'Advanced',
      path: 'advanced',
      canShow: true,
      index: false,
    },
    {
      Component: Settings,
      title: 'Settings',
      path: 'settings',
      canShow: true,
      index: false,
    },
  ];
};

const TabsContent = () => {
  const { siteId } = useParams();
  const location = useLocation();
  const { data } = useSitesInfo(siteId);
  const [canShowDomains] = useQueryFeature('domains');
  const siteInfo = data?.data;
  const pages = getPages(siteInfo, { canShowDomains });
  return (
    <XyzTransition xyz="fade left duration-3" mode="out-in" key={siteId}>
      <div key={location.key}>
        {/* location is needed here for the animation between routes */}
        <Routes location={location}>
          {pages.map(({ Component, path, index }) => (
            <Route
              key={path}
              index={index}
              element={<Component />}
              path={`${path}/*`}
            />
          ))}
        </Routes>
      </div>
    </XyzTransition>
  );
};

TabsContent.propTypes = propTypes;
export default TabsContent;
