import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useMFEContext } from '~/components/MFEProvider';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useHostingInfo from '~/hooks/useHostingInfo';

export default function AlertBanner({
  children = undefined,
}: PropsWithChildren<{}>) {
  const {
    data: { hostingData, renewalCenterLink },

    hostingStatus: {
      renewalStatus: { isExpired, willExpireSoon },
    },
  } = useMFEContext() as { data: any; hostingStatus: any };

  const { supportLink } = useBrandInfo();

  const { isSuspended, isDecommissioned } = useHostingInfo();
  const { t } = useTranslation('hosting');

  const hasAutoRenew = hostingData?.hasAutoRenew;
  const hostingPlan = hostingData?.productName;

  const handleExternalLink = () => {
    window.location.assign(renewalCenterLink);
  };

  if (isSuspended) {
    return (
      <Alert data-testid="alert-banner" severity="error">
        <AlertTitle>{t(`suspended.alert.title`, { hostingPlan })}</AlertTitle>
        <Typography>
          <Trans
            i18nKey="hosting:suspended.alert.description"
            components={{
              Link: <Link href={supportLink} target="_blank" />,
            }}
          />
        </Typography>
      </Alert>
    );
  }
  if ((isExpired && !hasAutoRenew) || (isDecommissioned && !hasAutoRenew)) {
    return (
      <Alert
        data-testid="alert-banner"
        severity="error"
        action={
          <Button onClick={handleExternalLink}>
            {t('renewal.alerts.ctaText')}
          </Button>
        }
      >
        <AlertTitle>
          {t(`renewal.alerts.isExpired.title`, { hostingPlan })}
        </AlertTitle>
        {t(`renewal.alerts.isExpired.description`)}
      </Alert>
    );
  }
  if ((isExpired && hasAutoRenew) || (isDecommissioned && hasAutoRenew)) {
    return (
      <Alert
        data-testid="alert-banner"
        severity="error"
        action={
          <Button onClick={handleExternalLink}>
            {t('renewal.alerts.ctaText')}
          </Button>
        }
      >
        <AlertTitle>
          {t(`renewal.alerts.autoRenewFailed.title`, { hostingPlan })}
        </AlertTitle>
        {t(`renewal.alerts.autoRenewFailed.description`)}
      </Alert>
    );
  }

  if (willExpireSoon && !hasAutoRenew) {
    return (
      <Alert
        data-testid="alert-banner"
        severity="error"
        action={
          <Button onClick={handleExternalLink}>
            {t('renewal.alerts.ctaText')}
          </Button>
        }
      >
        <AlertTitle>
          {t(`renewal.alerts.willExpireSoon.title`, { hostingPlan })}
        </AlertTitle>
        {t(`renewal.alerts.willExpireSoon.title`, { hostingPlan })}
      </Alert>
    );
  }
  return null;
}
