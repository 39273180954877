// Remove SX prop
import { bool, object, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

const propTypes = {
  copyValue: string,
  disable: bool,
  icon: object,
};

const Copy = ({ children, copyValue = null, disable = false, icon = null }) => {
  const { t } = useTranslation('site', { keyPrefix: 'Copy' });
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const findCopy = copyValue || children?.props?.children || children;
    try {
      navigator.clipboard.writeText(findCopy);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="baseline"
      spacing={{ sm: 1 }}
    >
      {children}
      <Tooltip open={copied} title={t('copiedMsg')} placement="top-end">
        <Button
          sx={{ p: icon ? 0 : '4px 1px', minWidth: icon ? 0 : '40px' }}
          disabled={disable}
          onClick={handleCopy}
        >
          {icon ? icon : t('copyBtn')}
        </Button>
      </Tooltip>
    </Stack>
  );
};

Copy.propTypes = propTypes;
export default Copy;
