import { useTranslation } from 'react-i18next';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import DevMode from '~/components/DevMode';
import useResponsive from '~/hooks/useResponsive';

import { useSiteContext } from '.';
import SiteTitle from './components/SiteTitle';
import { HideOnScroll } from './Shared';

export const HeaderAppBar = ({ children, setShowRenameModal = undefined }) => {
  const { t } = useTranslation('site');
  const { isMobile } = useResponsive();

  const {
    data: siteInfo,
    isLoading,
    isSiteOrHostingDisabled: isDisabled,
  } = useSiteContext();

  const getAppBarHeight = () =>
    isMobile() &&
    document.querySelector('.container.banner[role="banner"]')?.offsetHeight;

  return (
    <HideOnScroll>
      <AppBar
        position={isMobile() ? 'sticky' : 'relative'}
        sx={{ pb: 1, boxShadow: 'none', top: getAppBarHeight() }}
        data-testid="site-toolbar"
      >
        <Toolbar>
          <Grid
            container
            alignItems="center"
            spacing={{ xs: 2, sm: 3 }}
            justifyContent="space-between"
          >
            <Grid xs={12} sm={6} item display="flex" order={{ xs: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%' }}
              >
                <SiteTitle site={siteInfo} isLoading={isLoading} />

                <DevMode>
                  <Grid item>
                    <Button variant="text" href="/">
                      Back to Hosting
                    </Button>
                  </Grid>
                </DevMode>

                {setShowRenameModal && (
                  <Grid item>
                    <Button
                      variant="text"
                      data-testid="rename-site"
                      onClick={() => setShowRenameModal(true)}
                      disabled={isDisabled}
                    >
                      {t('rename')}
                    </Button>
                  </Grid>
                )}
              </Stack>
            </Grid>

            {children}
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};
