import App from './App';
import { InitProps } from './bootstrap';
import { MFEProvider } from './components/MFEProvider';

export default function Init({ basename = '', callbacks, data }: InitProps) {
  return (
    <MFEProvider basename={basename} callbacks={callbacks} data={data}>
      <App />
    </MFEProvider>
  );
}
