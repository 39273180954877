import { useSitesList } from '@newfold/huapi-js';

import { removeDeletedSites } from '~/utils/remove-deleted-sites';

const useFilteredSites = (args) => {
  const { data, isLoading, isError, ...rest } = useSitesList(args);

  if (isLoading || isError) {
    return { ...rest, data, isLoading, isError };
  }

  const sites = data?.data?.items;
  const filteredSites = removeDeletedSites(sites);

  return {
    ...rest,
    data: {
      ...data,
      data: { ...data?.data, items: filteredSites },
    },
  };
};

export default useFilteredSites;
