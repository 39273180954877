import { PropsWithChildren } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography, { TypographyProps } from '@mui/material/Typography';

export default function AllocationText({
  used = undefined,
  total = undefined,
  unit = undefined,
  isLoading = false,
  ...props
}: PropsWithChildren<{
  used?: number | null;
  total?: number | null;
  unit?: string;
  isLoading?: boolean;
}> &
  TypographyProps) {
  return (
    <Typography component="span" {...props} sx={{ fontWeight: 'bold' }}>
      {isLoading ? (
        <>
          <Skeleton sx={{ display: 'inline-block' }} width={30} /> /{' '}
          <Skeleton sx={{ display: 'inline-block' }} width={30} />
        </>
      ) : (
        <>
          {used ||
            // let null or zero show as "0", and undefined show as "-"
            (used === null || used === 0 ? '0' : '-')}{' '}
          / {total || '-'} {unit}
        </>
      )}
    </Typography>
  );
}
