const JetpackIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clip-path="url(#clip0_554_39809)">
        <path
          id="Subtract"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM9.375 11.875H4.375L9.375 1.875V11.875ZM10.625 8.125V18.125L15.625 8.125H10.625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_554_39809">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JetpackIcon;
