// Refactor mobile and Desktop view for Cards and Table
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useSiteUsers } from '@newfold/huapi-js';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import useAlerts from '~/components/Alerts/alertsStore';
import UsersFilter from './UsersFilter';
import UsersTable from './UsersTable';
import UsersCard from './UsersCard';
import { filterByRole, filterByNameEmailRole } from './utilities';

const Users = () => {
  const { siteId } = useParams();
  const { t } = useTranslation('users');
  const [, { generateAlert }] = useAlerts();

  const [textFilter, setTextFilter] = useState('');
  const [dropdownFilter, setDropdownFilter] = useState('');

  const { data: results, isLoading: isLoadingUsers } = useSiteUsers(siteId, {
    query: {
      onError: (error) => {
        const key = error?.response?.status.toString();
        generateAlert({
          severity: 'error',
          description: t([key]),
          showCloseBtn: true,
        });
      },
    },
  });

  const users = results?.data?.users ? results?.data?.users : [];

  const rolesList = {};
  users.forEach(({ role }) => (rolesList[role] = role));
  const roles = Object.keys(rolesList);

  const filteredUsers = users
    .filter(filterByNameEmailRole(textFilter))
    .filter(filterByRole(dropdownFilter));

  const displayDesktop = {
    display: { xs: 'none', md: 'block' },
  };

  const displayMobile = {
    display: { xs: 'block', md: 'none' },
  };

  return (
    <>
      <Card>
        <CardHeader title={t('cardTitle')} />
        <CardContent data-testid="users-list">
          <Stack spacing={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">{t('usersDescription')}</Typography>
              </Grid>
            </Grid>
            {!isLoadingUsers && users.length > 5 && (
              <Grid container spacing={0} data-testid="users-filter">
                <UsersFilter
                  {...{
                    roles,
                    setTextFilter,
                    dropdownFilter,
                    setDropdownFilter,
                  }}
                />
              </Grid>
            )}
            {/* TODO: not an ideal setup, this can def be improved, it will do for now */}
            <Box sx={displayDesktop}>
              <UsersTable users={filteredUsers} isLoading={isLoadingUsers} />
            </Box>
            <Box sx={displayMobile}>
              <UsersCard users={filteredUsers} isLoading={isLoadingUsers} />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default Users;
