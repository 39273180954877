import {
  SitesDomains200DomainsItem,
  SitesDomainsV2200DomainsItem,
} from '@newfold/huapi-js/src/index.schemas';

export const validateSiteUrlDomain = (
  domainData:
    | SitesDomains200DomainsItem
    | SitesDomainsV2200DomainsItem
    | undefined
    | null,
  siteUrl: string | undefined | null,
) => {
  const isAutoGenerated =
    domainData?.autogen_id !== null && domainData?.autogen_id !== '';

  const isSiteUrlDomain =
    (domainData?.domain && siteUrl && siteUrl.includes(domainData?.domain)) ||
    (domainData?.base_domain &&
      isAutoGenerated &&
      siteUrl &&
      siteUrl.includes(domainData?.base_domain)) ||
    false;

  return isSiteUrlDomain;
};
