const { useSearchParams } = require('react-router-dom');

// TODO: refactor to .tsx, and to accept and return multiple values
const useQueryFeature = (requestedFeature) => {
  const [searchParams] = useSearchParams();

  const options = {};

  const featureParam = searchParams.get('feature');
  // This is so that checking multiple features can be done using a single
  // query parameter (e.g. ?feature=domains,ssls) instead of needing multiple
  const featureList = featureParam?.split(',');

  const isFeatureEnabled = featureList?.includes(requestedFeature);

  //The rest of params are mapped to an "options" object
  for (const [key, value] of searchParams.entries()) {
    if (key !== 'feature') {
      options[key] = value;
    }
  }

  return [isFeatureEnabled, options];
};

export default useQueryFeature;
