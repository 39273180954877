import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

import {
  useHostingAdvancedServer,
  useHostingInfoDiskUsage,
} from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';
import Usage from '~/scenes/Hosting/scenes/HostingDetail/components/UsageStats/Usage';
import { getUsageStats } from '~/utils/usage-stats';

const UsageStats = () => {
  const { id: accountId } = useAccount();
  const { t } = useTranslation('settings', { keyPrefix: 'usageStatsCard' });
  const [usage, setUsage] = useState({
    percentUsed: 0,
    variant: 'primary',
    filePercentUsed: 0,
    fileVariant: 'primary',
    hasIcon: false,
    hasFileIcon: false,
  });
  const [hasSites] = useFeature('sites');
  const [isPlesk, , clientReady] = useFeature('plesk');
  const [showServerControls, , isClientReady] = useFeature('server_controls');

  const { isDisabled } = useHostingInfo();
  const { data: serverData } = useHostingAdvancedServer(accountId, {
    query: {
      refetchOnWindowFocus: false,
      enabled: !!accountId && isClientReady && showServerControls,
    },
  });

  const serverStatus = serverData?.data?.status;

  //CTB upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );

  const doNotShowDiskUsage = isPlesk || !clientReady;
  const isRestrictDiskUsageCall = isDisabled ? false : !doNotShowDiskUsage;
  const {
    data,
    isFetching,
    isError,
    refetch: refetchDiskData,
  } = useHostingInfoDiskUsage(accountId, {
    query: {
      enabled: isRestrictDiskUsageCall,
      select: ({ data }) => data,
      onSuccess: (data) => {
        const usageData = getUsageStats(data);
        return setUsage(usageData);
      },
    },
  });
  if (!hasSites && (serverStatus !== 'active' || hasPurchasedRecently)) {
    return null;
  }

  if (doNotShowDiskUsage) return null;

  return (
    <Grid item xs={12} className="xyz-nested">
      <Card>
        <CardHeader title={t('usageCardTitle')} />

        <Usage
          isFetching={isFetching}
          isError={isError}
          cardContentBody={t('usageCardDesc')}
          cardSubTitle={t('subTitle')}
          fileSubTitle={t('fileSubTitle')}
          usage={usage}
          disklimit={data?.disklimit}
          diskused={data?.diskused}
          disklastupdated={data?.disklastupdated}
          filesused={data?.filesused?.toLocaleString()}
          fileslimit={data?.fileslimit?.toLocaleString()}
          data-testid="stats-info"
          refetchDiskData={refetchDiskData}
        />
      </Card>
    </Grid>
  );
};

export default UsageStats;
