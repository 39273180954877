import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

import { useHostingAdvancedServer } from '@newfold/huapi-js';

import InfoListItem from '~/components/InfoListItem';
import { useMFEContext } from '~/components/MFEProvider';
import useAccount from '~/hooks/useAccount';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';

import ResetPasswordModal from '../../../../../Site/scenes/Settings/components/ResetPasswordModal';

const ResetPasswordInfo = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'resetPasswordCard',
  });

  const { brand } = useMFEContext();
  const { id: accountId } = useAccount();

  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false);
  const showPasswordResetModal = () => setOpenPasswordResetModal(true);

  //Get Hosting Capabilities
  const [showResetPassword, account] = useFeature('reset_password');

  //Hide Reset password, if VPS server not active or when CTB is in progress.
  const [showServerControls, , isClientReady] = useFeature('server_controls');

  const { data: serverData } = useHostingAdvancedServer(accountId, {
    query: {
      refetchOnWindowFocus: false,
      enabled: !!accountId && isClientReady && showServerControls,
    },
  });

  const serverStatus = serverData?.data?.status;

  //ctb upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );

  const getArticle = (accountType) => {
    return t('resetPassword.article.' + accountType);
  };

  // Shared - Sites - true, server-controls - false, reset-password - true if reseller.
  // VPS/Dedi - Sites - false, server-controls - true, reset-password - true
  if (!showResetPassword) {
    return null;
  }
  const ACCOUNT_TYPES = [
    'dedicated_windows',
    'dedicated_plesk',
    'shared_plesk',
  ];
  if (
    ACCOUNT_TYPES.includes(account.accountType) &&
    brand?.toLowerCase() === 'hostgator'
  ) {
    return (
      <>
        <Grid container spacing={1}>
          <Stack>
            <Grid
              item
              sx={{
                alignContent: 'start',
              }}
            >
              <InfoListItem title={t('resetPassword.title')} />
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignContent: 'start',
              }}
            >
              <Link
                href={getArticle(account.accountType)}
                sx={{ fontSize: '14px' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('resetPassword.learnMore')}
              </Link>
            </Grid>
          </Stack>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignContent: 'start',
          }}
        >
          <InfoListItem title={t('resetPassword.title')} />
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignContent: 'start',
          }}
        >
          <Button
            component="button"
            variant="text"
            data-testid="btn-reset-password"
            aria-disabled
            disabled={
              hasPurchasedRecently ||
              (showServerControls && serverStatus !== 'active')
            }
            onClick={showPasswordResetModal}
          >
            {t('resetPassword.viewBtn')}
          </Button>
        </Grid>
      </Grid>
      <ResetPasswordModal
        openPasswordResetModal={openPasswordResetModal}
        setOpenPasswordResetModal={setOpenPasswordResetModal}
      />
    </>
  );
};

export default ResetPasswordInfo;
