import { useState } from 'react';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestoreIcon from '@mui/icons-material/Restore';
import Autocomplete from '@mui/lab/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Paper, { PaperProps } from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { InitProps } from '~/bootstrap';

import { apiBaseUrlOptions } from './hostAppOptions';

function DraggableComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#mfe-tool-draggable-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

type MFEBoostrapProps = {
  formData: InitProps;
  setFormData: (data: any) => void;
  defaultFormData: InitProps;
};

export default function MFEBootstrapTool({
  formData,
  setFormData,
  defaultFormData,
}: MFEBoostrapProps) {
  const [open, setOpen] = useState(false);

  const { handleSubmit, setValue, watch, register, reset } = useForm();

  const apiBaseUrl = watch('apiBaseUrl', formData?.data?.apiBaseUrl);
  const hostingId = watch('hostingId', formData?.data?.hostingId);
  const accessToken = watch('accessToken', formData?.data?.accessToken);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageRefresh = () => {
    window.location.reload();
  };

  const handleReset = () => {
    reset(defaultFormData);
    setFormData(defaultFormData);
  };

  const onSubmit = (data: any) => {
    setFormData({
      ...formData,
      ...(data?.hostingId && { hostingId: data?.hostingId }),
      ...(data?.accessToken && { accessToken: data?.accessToken }),
      data: {
        ...formData?.data,
        ...(data?.apiBaseUrl && { apiBaseUrl: data?.apiBaseUrl }),
        ...(data?.hostingId && { hostingId: data?.hostingId }),
        ...(data?.accessToken && { accessToken: data?.accessToken }),
        hostingData: {
          ...formData?.data?.hostingData,
          ...(data?.hostingId && { productInstanceId: data?.hostingId }),
        },
      },
    });
  };

  return (
    <>
      <Fab
        color="secondary"
        aria-label="edit app"
        onClick={handleOpen}
        size="small"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <EditIcon />
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'sm'}
        PaperComponent={DraggableComponent}
        aria-labelledby="mfe-tool-draggable-dialog"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <DialogTitle sx={{ cursor: 'move' }} id="mfe-tool-draggable-dialog">
            Host App Props
          </DialogTitle>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Autocomplete
                  id="api-base-url"
                  data-testid="api-base-url-dropdown"
                  value={apiBaseUrl}
                  onChange={(e, newValue) => setValue('apiBaseUrl', newValue)}
                  options={apiBaseUrlOptions}
                  renderInput={(params) => (
                    <TextField {...params} label={'apiBaseUrl'} />
                  )}
                />
                <TextField
                  label={'hostingId'}
                  id="hostingId"
                  data-testid="hosting-id-dropdown"
                  value={hostingId}
                  {...register('hostingId')}
                  onChange={(e) => setValue('hostingId', e.target.value)}
                />
                <TextField
                  label={'accessToken'}
                  id="accessToken"
                  data-testid="access-token-dropdown"
                  value={accessToken}
                  {...register('accessToken')}
                  onChange={(e) => setValue('accessToken', e.target.value)}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                alignItems="end"
                justifyContent="end"
              >
                <LoadingButton
                  variant="contained"
                  onClick={handlePageRefresh}
                  startIcon={<RefreshIcon />}
                >
                  Reload Page
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  onClick={handleReset}
                  startIcon={<RestoreIcon />}
                >
                  Reset
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="success"
                  type="submit"
                >
                  Update
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
