import { useState } from 'react';

import InfoRounded from '@mui/icons-material/InfoRounded';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const CommonTooltip = ({ desc, testId }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          open={open}
          title={desc || ''}
          placement="top-start"
          disableHoverListener
          onClose={handleTooltipClose}
          PopperProps={{
            disablePortal: true,
          }}
          data-testid={testId || 'tooltip-btn'}
        >
          <IconButton onClick={handleTooltipOpen} sx={{ p: 0 }}>
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CommonTooltip;
