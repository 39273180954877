import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Portal from '@mui/material/Portal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Wrapper = ({
  hasPurchasedRecently = false,
  children = null,
  container = {},
}: PropsWithChildren<{
  container?: object;
  hasPurchasedRecently?: boolean;
}>) => {
  const { t } = useTranslation('site');

  return (
    <div>
      {hasPurchasedRecently && container && (
        // @ts-ignore
        <Portal container={container.current}>
          <Alert data-testid="alert-banner" severity="warning" sx={{ mb: 3 }}>
            {t(`advancedServer.alerts.serverStatus.description`)}
          </Alert>
        </Portal>
      )}

      <Card>
        <CardHeader title={t('advancedServer.cardTitle')} />
        <CardContent>
          <Stack spacing={1}>
            <Typography variant="body2">
              {t('advancedServer.cardDesc')}
            </Typography>
            {children}
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default Wrapper;
