import { Route, Routes } from 'react-router-dom';

import DomainsContent from './DomainsContent';

const Domains = () => {
  const PATH_DOMAIN_LIST = '';

  return (
    <Routes>
      <Route path={PATH_DOMAIN_LIST} element={<DomainsContent />} />
      {/* Moved these to src/scenes/Sites/index.js to allow for fullscreen modal behavior */}
      {/* <Route path={'add/*'} element={<AddDomain />} /> */}
      {/* <Route
        path={':domainName/dns/*'}
        element={<DomainSetup />}
      /> */}
    </Routes>
  );
};

export default Domains;
