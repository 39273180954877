import { capitalize } from 'lodash';
import { ListChildComponentProps } from 'react-window';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CardMenu from './CardMenu';

export default function CardVirtualized(props: ListChildComponentProps) {
  const { index, style, data } = props;
  const hosting = data[index];

  const GUTTER_SIZE = 16;

  const virtualStyles = {
    ...style,
    top: Number(style.top) + GUTTER_SIZE - 1,
    padding: '0 1px',
    width: '100%',
    height: Number(style.height) - GUTTER_SIZE,
  };

  return (
    <div key={`hosting-${hosting?.hosting_id}`} style={virtualStyles}>
      <Card>
        <CardHeader
          variant="cardlistheader"
          title={`${hosting?.feature_set} - ${hosting?.hosting_id}`}
          action={<CardMenu hosting={hosting} />}
        />
        <CardContent>
          <Stack spacing={1}>
            <Typography>Status: {capitalize(hosting?.status)}</Typography>
            <Typography>Account: {hosting?.account_id}</Typography>
            <Typography>
              Renewal Date: Sites - {hosting?.current_sites}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}
