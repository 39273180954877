import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Scroll to Component on Click usage:

// First import useHashScroll into the page where the individual component lives.
// Then use it like this: useHashScroll();
// Add id to component: Ex: <Card id="domain-details">content</Card>
// Hook up anchor tag: Ex: <Link href="settings#domain-details">My Link</Link>

const useHashScroll = (waitForAnimation) => {
  let mounted = true;

  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  if (hash && !scrolledRef.current) {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        if (mounted) element.scrollIntoView({ behavior: 'smooth' });
      }, waitForAnimation || 0);
      scrolledRef.current = true;
    }
  }

  return () => {
    mounted = false;
  };
};

export default useHashScroll;
