import { object, oneOfType, string } from 'prop-types';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { isDevMode } from '~/components/DevMode';

const propTypes = {
  error: oneOfType([string, object]),
};

const ErrorScene = ({ error = null }) => {
  const formattedError = typeof error === 'string' ? error : error?.message;

  return (
    <Container id="hosting-mfe-fatal-error" sx={{ my: 3 }}>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">
          <AlertTitle>
            We're having trouble loading your hosting dashboard
          </AlertTitle>
          <Typography>
            Please try again in a few minutes. If this problem persists, please
            contact our support team.
          </Typography>
        </Alert>
        {isDevMode && <code>{formattedError}</code>}
      </Stack>
    </Container>
  );
};

ErrorScene.propTypes = propTypes;
export default ErrorScene;
