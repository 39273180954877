// Delete SX props
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import {
  siteThumbnailSkeletonSx,
  siteThumbnailSx,
} from '~/scenes/Hosting/scenes/HostingDetail/components/Sites/components/SiteThumbnail';

import noSitePictureWide from '../../../../Sites/assets/placeholders/site-no-picture-wide.svg';
import useThumbnail from '../useThumbnail';

const SiteThumbnail = ({ siteInfo, isLoadingSite = false }) => {
  const siteThumbnailLocalSx = {
    maxWidth: '337px',
  };

  const isWordPress = siteInfo?.type === 'wordpress';
  const detectedWordPress = siteInfo?.detected_type === 'wordpress';

  const isBrokenWordPress =
    (isWordPress && !detectedWordPress) || !siteInfo?.url;

  const { thumbnailLoc, isInitialLoading } = useThumbnail(
    siteInfo?.url,
    false,
    !isBrokenWordPress,
  );

  if (isLoadingSite || isInitialLoading) {
    return (
      <Grid item xs={12} sm={4}>
        <Box>
          <Skeleton
            variant="rectangular"
            sx={{
              ...siteThumbnailSkeletonSx,
              ...siteThumbnailLocalSx,
              height: '189px',
            }}
          />
        </Box>
      </Grid>
    );
  }

  if (isBrokenWordPress) {
    return (
      <Grid item xs={12} sm={4}>
        <Box
          component="img"
          src={noSitePictureWide}
          sx={{
            ...siteThumbnailSx,
            ...siteThumbnailLocalSx,
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={4}>
      <Box
        component="img"
        src={thumbnailLoc ? thumbnailLoc : noSitePictureWide}
        sx={{
          ...siteThumbnailSx,
          ...siteThumbnailLocalSx,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'divider',
        }}
      />
    </Grid>
  );
};

export default SiteThumbnail;
