/*
  MultipleChoiceSsoDialog

    Displays a modal dialog with multiple choice options that can be selected by clicking,
    and two buttons, Cancel and Continue, to use to either exit the dialog or to initiate
    the action of the selected choice.

    Each choice specifies a different SsoLink component to use.

  Properties:

    isOpen: A boolean to indicate whether or not to show the dialog box.  This might be a
      state variable in the calling component.
    onClose: A function to call when the dialog is closed.  It should update the isOpen
      state variable at a minimum.
    title: The title of the dialog.
    description: Optional. The description of the dialog.
    choices: An array of objects with the following properties:
      title: The title of the choice.
      description: Optional. The description of the choice.
      app: The name of the app to pass to SsoLink of type hosting.

  State:

    choiceData: Stores the data for the choices, including which one is currently selected.

*/
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import MultipleChoiceModalDialog from '~/components/MultipleChoiceModalDialog';
import Choice from '~/components/MultipleChoiceModalDialog/Choice';
import SsoLink from '~/components/SsoLink';

const propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  title: string.isRequired,
  description: string,
  choices: arrayOf(
    shape({
      title: string.isRequired,
      description: string,
      app: string,
    }),
  ).isRequired,
};

const MultipleChoiceSsoDialog = ({
  isOpen,
  onClose,
  title,
  description = '',
  choices,
  ...props
}) => {
  const { t } = useTranslation(['common', 'quickLinks']);

  const choicesData = choices.map((choice) => ({
    ...choice,
    title: choice.title,
    description: choice.description,
    app: choice.app,
    key: choice.key,
  }));

  const [currentChoiceKey, setCurrentChoiceKey] = useState(
    choicesData?.[0]?.key,
  );

  const spamExpertsChoice = choicesData.find(
    (choice) => choice.key === 'spamExperts',
  );
  const [currentChoiceAddonId, setCurrentChoiceAddonId] = useState(
    spamExpertsChoice?.addonList?.[0]?.id,
  );
  const handleResetCurrentChoiceAddonId = () =>
    setCurrentChoiceAddonId(spamExpertsChoice?.addonList?.[0]?.id);

  const handleClose = () => {
    // reset to first option
    setCurrentChoiceKey(choicesData?.[0]?.key);
    // reset to first domain option
    handleResetCurrentChoiceAddonId();
    // Hide spamExperts domain selection
    setShowSpamexpertsSection(false);
    // close dialog
    onClose();
  };

  const [showSpamexpertsSection, setShowSpamexpertsSection] = useState(false);
  // Reset spamExperts domain selection on list visibility change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleResetCurrentChoiceAddonId(), [showSpamexpertsSection]);

  const getActionButtonComponent = () => {
    const choice = choicesData?.find(
      (choice) => choice.key === currentChoiceKey,
    );
    const addonId = choice?.addonId;

    // If the server email modal is visible on the main list, allow
    // navigation to the spamExperts domain selection list
    if (
      choice?.key === 'spamExperts' &&
      !!choice?.addonList &&
      !showSpamexpertsSection
    ) {
      return (
        <Button
          onClick={() => setShowSpamexpertsSection(true)}
          variant="contained"
          data-testid="spamexperts-continue-button"
        >
          {t('actions.continue', { ns: 'common' })}
        </Button>
      );
    }

    return (
      <SsoLink
        type={choice?.app === 'spamexperts' ? 'spamexperts' : 'hosting'}
        variant="contained"
        addonId={addonId}
        queryParams={{ app: choice?.app }}
        onAction={handleClose}
        data-testid="mcsd-continue-button"
      >
        {t('actions.continue', { ns: 'common' })}
      </SsoLink>
    );
  };

  const handleClick = (key) => setCurrentChoiceKey(key);
  const handleSpamExpertsClick = (key) => setCurrentChoiceAddonId(key);

  // There can be multiple SpamExperts addons per hosting account, each requiring
  // a unique SSO. This will show the user a selection list, by domain.
  if (showSpamexpertsSection) {
    return (
      <MultipleChoiceModalDialog
        isOpen={isOpen}
        backAction={() => {
          setShowSpamexpertsSection(false);
          handleResetCurrentChoiceAddonId();
        }}
        onClose={handleClose}
        title={t('serverEmail.spamExpertsDomains.title', { ns: 'quickLinks' })}
        description={t('serverEmail.spamExpertsDomains.description', {
          ns: 'quickLinks',
        })}
        choices={spamExpertsChoice.addonList.map((choice) => {
          return (
            <Choice
              title={choice.meta.domain}
              description={''}
              variant={
                choice.id === currentChoiceAddonId ? 'selected' : 'outlined'
              }
              onClick={() => handleSpamExpertsClick(choice.id)}
              key={choice.id}
              data-testid={`domain-${choice.id}-choice-card`}
            />
          );
        })}
        actionButton={
          <SsoLink
            type={'spamexperts'}
            variant="contained"
            addonId={parseInt(currentChoiceAddonId)}
            queryParams={{ app: 'spamexperts' }}
            onAction={handleClose}
            data-testid="spamexperts-sso-button"
          >
            {t('actions.continue', { ns: 'common' })}
          </SsoLink>
        }
        data-testid="multiple-choice-sso-dialog"
        {...props}
      />
    );
  }

  return (
    <MultipleChoiceModalDialog
      isOpen={isOpen}
      onClose={handleClose}
      title={t('serverEmail.title', { ns: 'quickLinks' })}
      description={t('serverEmail.description', { ns: 'quickLinks' })}
      choices={choicesData.map((choice) => {
        if (!choice.canShow) return null;
        return (
          <Choice
            title={choice.title}
            description={choice.description}
            variant={choice.key === currentChoiceKey ? 'selected' : 'outlined'}
            onClick={() => handleClick(choice.key)}
            key={choice.key}
            data-testid={`${choice.key}-choice-card`}
          />
        );
      })}
      actionButton={getActionButtonComponent()}
      data-testid="multiple-choice-sso-dialog"
      {...props}
    />
  );
};

MultipleChoiceSsoDialog.propTypes = propTypes;
export default MultipleChoiceSsoDialog;
