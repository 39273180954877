import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useSitePlugins, useSitePluginsToggle } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import SsoLink from '~/components/SsoLink';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import { useSiteContext } from '~/scenes/Site';
import JetpackIcon from '~/scenes/Site/scenes/Cloud/scenes/assets/icons/JetpackIcon';

type JetpackBackupSsoProps = {
  siteId: number;
  getBackupDetails: VoidFunction;
  backupDetails?: {
    hasJetpackAddon: boolean;
    hasJetpackPlugin: boolean;
    jetpackAddonStatus: string;
    jetpackBackupNextUrl: string;
  };
};

const JetpackBackupSso = ({
  siteId,
  backupDetails = undefined,
  getBackupDetails = () => {},
}: JetpackBackupSsoProps) => {
  const [bounceName, setBounceName] = useState('');
  const [showActivateBtn, setShowActivateBtn] = useState(false);
  const { t } = useTranslation('site');
  const generateAlert = useAlert();
  const { phoneNumberFormatted } = useBrandInfo();

  // @ts-expect-error
  const { isSiteOrHostingDisabled } = useSiteContext();

  const { isLoading: pluginsLoading } = useSitePlugins(siteId, {
    query: {
      enabled:
        !!siteId &&
        !backupDetails?.hasJetpackPlugin &&
        backupDetails?.hasJetpackAddon,
      onSuccess: ({ data }) => {
        const pluginData = data?.plugins?.filter(
          (plugin) => plugin?.id === 'jetpack-backup',
        );
        if (pluginData?.length < 1) {
          setSSO('jetpackdashboard', false);
          return;
        }
        setSSO('', true);
      },
    },
  });

  const setSSO = (name: string, showBtn: boolean) => {
    setBounceName(name);
    setShowActivateBtn(showBtn);
    return;
  };

  //////////////////////////////////////////////////
  // plugin   addon     bounceName
  // 0        0           no card
  // 0        1           activate plugin
  // 1        0           SSO - 'jetpackbackup'
  // 1        1           SSO - 'jetpackdashboard'
  //////////////////////////////////////////////////
  useEffect(() => {
    backupDetails?.hasJetpackPlugin &&
      backupDetails?.hasJetpackAddon &&
      setSSO('jetpackdashboard', false);

    backupDetails?.hasJetpackPlugin &&
      !backupDetails?.hasJetpackAddon &&
      setSSO('jetpackbackup', false);
  }, [backupDetails?.hasJetpackAddon, backupDetails?.hasJetpackPlugin]);

  const { isLoading: activationInProgress, mutate: handleActivation } =
    useSitePluginsToggle({
      mutation: {
        onSuccess: ({ data }) => {
          const isSuccess = data.success;
          isSuccess && getBackupDetails();
          generateAlert({
            severity: isSuccess ? 'success' : 'error',
            description: isSuccess
              ? t('cloud.backups.jetpack.activationSuccess')
              : t('cloud.backups.jetpack.activationFailed'),
            showCloseBtn: true,
          });
        },
        onError: () =>
          generateAlert({
            severity: 'error',
            description: t('cloud.backups.jetpack.activationFailed', {
              phoneNumber: phoneNumberFormatted,
            }),
            showCloseBtn: true,
          }),
      },
    });

  return (
    <Stack>
      {showActivateBtn && (
        <LoadingButton
          variant="contained"
          loading={pluginsLoading || activationInProgress}
          disabled={!!activationInProgress || isSiteOrHostingDisabled}
          onClick={() =>
            handleActivation({
              siteId: siteId.toString(),
              data: { id: 'jetpack-backup', activate: true },
            })
          }
        >
          {t('cloud.backups.jetpack.activateBtn')}
        </LoadingButton>
      )}
      {bounceName && !backupDetails?.jetpackBackupNextUrl && (
        <SsoLink
          type={'site'}
          queryParams={{ app: bounceName }}
          variant="contained"
          startIcon={<JetpackIcon />}
          disabled={
            !(backupDetails?.jetpackAddonStatus === 'active') ||
            isSiteOrHostingDisabled
          }
        >
          {t('cloud.backups.jetpack.launchBtn')}
        </SsoLink>
      )}
      {backupDetails?.jetpackBackupNextUrl && (
        <Button
          variant="contained"
          href={backupDetails.jetpackBackupNextUrl}
          target="_blank"
        >
          {t('cloud.backups.jetpack.connectBtn')}
        </Button>
      )}
    </Stack>
  );
};

export default JetpackBackupSso;
