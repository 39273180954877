import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { isNumber } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getHostingAccountQueryKey,
  getSitesInfoQueryKey,
  useSiteUpdate,
} from '@newfold/huapi-js';
import {
  HostingAccount200,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import useAccount from '~/hooks/useAccount';
import useHostingInfo from '~/hooks/useHostingInfo';
import Workers from '~/scenes/Cloud/components/Workers';
import { validateWorkers } from '~/scenes/Cloud/utils/validations';

type PHPWorkersProps = {
  isDisabled?: boolean;
  allocatedWorkers?: number;
  isLoading?: boolean;
  getSiteData?: Function;
};

const PHPWorkers = ({
  isDisabled = false,
  allocatedWorkers = undefined,
  isLoading = false,
}: PHPWorkersProps) => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'performance.adjustPHPWorkers',
  });
  const generateAlert = useAlert();
  const { id: accountId } = useAccount();
  const { hostingAccount } = useHostingInfo();
  const workersInfo = hostingAccount?.data?.resources?.workers;
  const { siteId } = useParams<{ siteId: string }>();
  const methods = useForm({});
  const { watch } = methods;
  const queryClient = useQueryClient();

  const sitesInfoQueryKey = getSitesInfoQueryKey(Number(siteId));
  const hostingInfoQueryKey = getHostingAccountQueryKey(accountId);

  const currentWorkerValue = watch(`${siteId}-workers`);
  const differenceInWorkers =
    Number(currentWorkerValue) - (allocatedWorkers || 0);

  // subtract the newly allocated workers from the available workers
  const availableWorkers = workersInfo?.available || 0;
  const availableWorkersDisplayedAfterModification =
    (workersInfo?.available || 0) - differenceInWorkers;
  const minWorkersAllowed = 2;
  const maxWorkersAllowed =
    availableWorkers && availableWorkers <= 10 ? availableWorkers : 10;

  const { isValid: workersIsValid } = validateWorkers({
    value: Number(currentWorkerValue),
    min: minWorkersAllowed,
    max: maxWorkersAllowed,
    available: workersInfo?.available,
    allocated: allocatedWorkers,
  });

  const setAlertMessage = (variant: string, descKey: string) => {
    generateAlert({
      severity: variant,
      description: descKey,
      showCloseBtn: true,
    });
  };

  // update PHP workers
  const { isLoading: updatingPHPWorkers, mutate: updateSite } = useSiteUpdate({
    mutation: {
      onMutate: async ({ siteId: siteIdToUpdate, data }) => {
        // cancel current queries
        await queryClient.cancelQueries(sitesInfoQueryKey);
        await queryClient.cancelQueries(hostingInfoQueryKey);

        // backup previous data
        const previousDataSitesInfo = await queryClient.getQueryData(
          sitesInfoQueryKey,
        );
        const previousDataHostingInfo = await queryClient.getQueryData(
          hostingInfoQueryKey,
        );

        // set new data into sites info
        queryClient.setQueryData<AxiosResponse<SitesInfo200>>(
          sitesInfoQueryKey,
          (oldData) => {
            if (!oldData) return oldData;
            if (isNumber(data?.workers))
              return {
                ...oldData,
                data: {
                  ...oldData?.data,
                  resources: {
                    ...oldData?.data?.resources,
                    workers: Number(data?.workers),
                  },
                },
              };
            return oldData;
          },
        );

        // set query data for hostingInfo
        queryClient.setQueryData<AxiosResponse<HostingAccount200>>(
          hostingInfoQueryKey,
          (oldData) => {
            if (!oldData) return oldData;
            return {
              ...oldData,
              data: {
                ...oldData?.data,
                resources: {
                  ...oldData?.data?.resources,
                  workers: {
                    ...oldData?.data?.resources?.workers,
                    // subtract difference from available
                    available:
                      Number(oldData?.data?.resources?.workers?.available) -
                      differenceInWorkers,
                    // add difference to used
                    used:
                      Number(oldData?.data?.resources?.workers?.used) +
                      differenceInWorkers,
                  },
                },
              },
            };
          },
        );

        // return context with the previous data
        return () => {
          queryClient.setQueryData(sitesInfoQueryKey, previousDataSitesInfo);
          queryClient.setQueryData(
            hostingInfoQueryKey,
            previousDataHostingInfo,
          );
        };
      },

      onError: (error, variables, context) => {
        setAlertMessage('error', t('errorMsg'));
        // roll back
        if (typeof context === 'function') context();
      },

      onSuccess: ({ data }, variables, context) => {
        if (data?.success) {
          setAlertMessage('success', t('successMsg'));
        } else {
          // roll back
          if (typeof context === 'function') context();
        }
      },
      onSettled: () => {
        // NOTE: we should likely keep these here in case
        // the user updates this from another page or tab
        queryClient.invalidateQueries(sitesInfoQueryKey);
        queryClient.invalidateQueries(hostingInfoQueryKey);
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <Card>
        <CardHeader title={t('title')} />
        <CardContent>
          <Stack spacing={3} alignItems={'flex-start'}>
            <Typography variant="body1">{t('desc')}</Typography>
            <Grid
              container
              alignItems="baseline"
              justifyContent="space-between"
            >
              <Stack spacing={1}>
                {isLoading ? (
                  <Skeleton width={100} />
                ) : (
                  <Workers
                    defaultValue={allocatedWorkers ?? 2}
                    siteId={Number(siteId)}
                  />
                )}
                {isNumber(availableWorkersDisplayedAfterModification) && (
                  <FormHelperText>
                    <Typography variant="body2">
                      {availableWorkersDisplayedAfterModification}{' '}
                      {t('available')}
                    </Typography>
                  </FormHelperText>
                )}
              </Stack>
              <LoadingButton
                loading={updatingPHPWorkers || isLoading}
                variant="outlined"
                disabled={
                  Number(currentWorkerValue) === allocatedWorkers ||
                  isDisabled ||
                  !workersIsValid
                }
                onClick={() =>
                  updateSite({
                    siteId: Number(siteId),
                    data: { workers: Number(currentWorkerValue) },
                  })
                }
              >
                {t('updateBtn')}
              </LoadingButton>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </FormProvider>
  );
};

export default PHPWorkers;
