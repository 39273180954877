import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

export const PhpWorkersTooltip = ({
  children = undefined,
  iconProps = {},
  ...props
}) => (
  <Tooltip
    title="PHP workers are behind-the-scenes helpers for your website, ensuring fast and smooth page loading, especially for higher traffic sites."
    {...props}
  >
    {children ? (
      children
    ) : (
      <Info sx={{ color: 'grey.400' }} fontSize="small" {...iconProps} />
    )}
  </Tooltip>
);
