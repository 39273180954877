import isNumber from 'lodash/isNumber';
import { PropsWithChildren } from 'react';

//storage
export function calculateMaxStorageAllowed({
  siteTotal = null,
  storageAvailable = null,
}: PropsWithChildren<{
  siteTotal: number | null | undefined;
  storageAvailable: number | null | undefined;
}>) {
  if (isNumber(siteTotal) && isNumber(storageAvailable)) {
    return Number(siteTotal) + Number(storageAvailable);
  }

  return isNumber(storageAvailable) && storageAvailable <= 200
    ? storageAvailable
    : 200;
}
