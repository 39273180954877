import { useQueryClient } from '@tanstack/react-query';
import { PropsWithRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getSitesDomainsV2QueryKey,
  useHostingDomainsUnassign,
} from '@newfold/huapi-js';
import {
  SitesDomainsV2Params,
  SitesDomainsV2200,
} from '@newfold/huapi-js/src/index.schemas';

import useAlerts from '~/components/Alerts/alertsStore';
import useAccount from '~/hooks/useAccount';

const RemoveDomain = ({
  domain = undefined,
  domainId = undefined,
  isLastDomain = false,
  isMainDomain = false,
  isSiteUrlDomain = false,
  isOpen = false,
  setOpen = () => {},
}: PropsWithRef<{
  domain?: string;
  domainId?: number;
  isLastDomain?: boolean;
  isMainDomain?: boolean;
  isSiteUrlDomain?: boolean;
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
}>) => {
  const { t } = useTranslation('domains');
  const [, { generateAlert }] = useAlerts();
  const { siteId } = useParams();
  const { id: hostingId } = useAccount();

  const queryClient = useQueryClient();
  const queryParams: SitesDomainsV2Params = {};
  // @ts-expect-error
  const sitesDomainsQueryKey = getSitesDomainsV2QueryKey(siteId, queryParams);

  const handleClose = () => {
    setOpen(false);
  };

  const handleResult = (status: string) => {
    generateAlert({
      description: t(`remove.${status}Message`, { domain: domain }),
      severity: status,
      showCloseBtn: true,
    });
    handleClose();
  };

  const { mutate: unassignDomain, isLoading: isLoadingUnassignDomain } =
    useHostingDomainsUnassign({
      mutation: {
        onMutate: async (unassignDomainRequest) => {
          // optimistically remove domain from the site domain list query

          // snapshot current table data
          // @ts-expect-error
          const previousSiteDomains: { data: SitesDomainsV2200 } =
            queryClient.getQueryData(sitesDomainsQueryKey);

          queryClient.setQueryData(sitesDomainsQueryKey, () => {
            if (!previousSiteDomains) return previousSiteDomains;

            return {
              ...previousSiteDomains,
              data: {
                ...previousSiteDomains.data,
                domains: previousSiteDomains.data?.domains?.filter(
                  (d) => d.id !== domainId,
                ),
              },
            };
          });

          // return context object for rollback
          return { previousSiteDomains };
        },
        onSettled: (data, error, variables, context) => {
          if (error || data?.data?.queued === false) {
            handleResult('error');

            if (context?.previousSiteDomains) {
              return queryClient.setQueryData(
                sitesDomainsQueryKey,
                context?.previousSiteDomains,
              );
            }
          }

          if (data?.data?.queued === true) {
            handleResult('success');
          }
        },
      },
    });

  const handleRemove = () => {
    unassignDomain({
      hostingId: hostingId,
      // @ts-expect-error
      data: { domain_id: parseInt(domainId) },
    });
  };

  const canRemoveDomain = !isSiteUrlDomain && !isLastDomain && !isMainDomain;

  const getDialogTextKey = () => {
    if (isSiteUrlDomain) return 'domains:remove.domainIsSiteUrl';
    else if (isLastDomain) return 'domains:remove.lastDomain';
    else if (isMainDomain) return 'domains:remove.domainIsMain';
    else return 'domains:remove.confirmation';
  };

  return (
    <Dialog open={isOpen} data-testid="remove-domain-modal">
      <DialogContent>
        <Stack spacing={1}>
          <Stack direction={'row'} alignItems={'center'}>
            <WarningIcon color="error" />
            <DialogTitle>{t('actions.removeDomain')}</DialogTitle>
          </Stack>
          <Divider />
          <Stack pt={2}>
            <div>
              <Trans
                i18nKey={getDialogTextKey()}
                values={{ domain }}
                components={{
                  DomainName: (
                    <Typography component={'span'} fontWeight={'bold'} />
                  ),
                }}
              />
            </div>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {canRemoveDomain ? (
          <Stack direction={'row'} spacing={2}>
            <Button
              data-testid="remove-modal-cancel-btn"
              onClick={handleClose}
              disabled={isLoadingUnassignDomain}
            >
              {t('remove.cancelButton')}
            </Button>
            <LoadingButton
              data-testid="remove-modal-confirm-btn"
              variant="contained"
              color="error"
              onClick={handleRemove}
              disabled={isLoadingUnassignDomain}
              loading={isLoadingUnassignDomain}
            >
              {t('remove.confirmButton')}
            </LoadingButton>
          </Stack>
        ) : (
          <Button data-testid="remove-modal-close-btn" onClick={handleClose}>
            {t('remove.closeButton')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDomain;
