import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const useSitePath = (siteId) => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const [searchParams] = useSearchParams();
  const i = searchParams.get('i');
  const targetPath = i ? i : '';

  const isValid =
    siteId?.toLowerCase() !== 'site' && siteId?.toLowerCase() !== 'siteid';

  const getInvalidSitePath = () => {
    // util function to get the position needed to perform the substring function
    const getPosition = (string, subString, index) => {
      return string.split(subString, index).join(subString).length;
    };
    // get the target subpath by looking at everything after the position of the third slash
    // for example: /sites/12345678/my/page -> /my/page
    const targetSubPath = pathname.substring(
      getPosition(pathname, '/', 3),
      pathname.length,
    );
    return targetSubPath && targetSubPath !== '/'
      ? `..?i=${targetSubPath}`
      : '..';
  };
  const hashMarker = hash.length > 0 ? hash : '';

  const siteRedirect = (siteId) => {
    const path = pathname === '/' ? 'sites/' : '';
    navigate(`${path}${siteId}${targetPath}${hashMarker}`);
  };

  return {
    isValid,
    subPath: `${getInvalidSitePath()}${hashMarker}`,
    redirectPath: `${siteId}${targetPath}`,
    siteRedirect,
  };
};

export default useSitePath;
