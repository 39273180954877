import { useQueryClient } from '@tanstack/react-query';
import isNumber from 'lodash/isNumber';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getHostingAccountQueryKey,
  getSitesInfoQueryKey,
  SitesInfoQueryResult,
  useSitesInfo,
  useSiteUpdate,
} from '@newfold/huapi-js';
import { SitesInfo200 } from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import useAccount from '~/hooks/useAccount';
import useHostingInfo from '~/hooks/useHostingInfo';
import Storage from '~/scenes/Cloud/components/Storage';
import { calculateMaxStorageAllowed } from '~/scenes/Cloud/utils/manage-resources';
import { validateStorage } from '~/scenes/Cloud/utils/validations';

export default function AdjustStorage() {
  const { t } = useTranslation('cloud', { keyPrefix: 'settings' });

  const generateAlert = useAlert();

  const { id: hostingId } = useAccount();

  const { siteId } = useParams<{ siteId: string }>();

  const methods = useForm({});
  const { watch } = methods;
  const currentStorageValue = watch(`${siteId}-disk_limit_gib`);

  const { hostingAccount } = useHostingInfo();

  const queryClient = useQueryClient();

  const queryKey = getSitesInfoQueryKey(Number(siteId));
  const hostingQueryKey = getHostingAccountQueryKey(hostingId);

  const [refetchSite, setRefetchSite] = useState(false);

  const { data: site, isInitialLoading: isSiteLoading } = useSitesInfo(
    Number(siteId),
    {
      query: {
        enabled: !!siteId,
        select: ({ data }) => data,
        refetchInterval: (data) => {
          return refetchSite &&
            Number(data?.resources?.storage?.total) !==
              Number(currentStorageValue)
            ? 5000
            : false;
        },
        onSuccess: (data: SitesInfo200) => {
          if (
            refetchSite &&
            Number(data?.resources?.storage?.total) ===
              Number(currentStorageValue)
          ) {
            setRefetchSite(false);
            queryClient.invalidateQueries(hostingQueryKey);
            setAlertMessage('success', t('adjustStorage.successMsg'));
          }
          return data;
        },
      },
    },
  );

  const storageAvailable = hostingAccount?.data?.resources?.storage?.available;

  const siteUsed = Number(site?.resources?.storage?.used);
  const siteTotal = Number(site?.resources?.storage?.total);

  const minStorageAllowed =
    isNumber(siteUsed) && Number(siteUsed) > 1 ? Number(siteUsed) : 1;

  const maxStorageAllowed = calculateMaxStorageAllowed({
    siteTotal,
    storageAvailable,
  });

  const { isValid: isStorageValid } = validateStorage({
    value: currentStorageValue,
    min: Number(minStorageAllowed),
    max: Number(maxStorageAllowed),
  });

  // update Storage Allocation
  const { isLoading: updatingStorageAllocation, mutate: updateSite } =
    useSiteUpdate({
      mutation: {
        onMutate: async (newData) => {
          await queryClient.cancelQueries(queryKey);
          const oldData: SitesInfoQueryResult | undefined =
            queryClient.getQueryData(queryKey);
          const updatedData = {
            ...oldData,
            data: {
              ...oldData?.data,
              resources: {
                ...oldData?.data?.resources,
                storage: {
                  ...oldData?.data?.resources?.storage,
                  total: Number(newData?.data?.disk_limit_gib),
                },
              },
            },
          };

          queryClient.setQueryData(queryKey, updatedData);
          return { oldData, newData };
        },
        onError: (error, newData, context) => {
          queryClient.setQueryData(queryKey, context?.oldData);
          setAlertMessage('error', t('adjustStorage.errorMsg'));
        },
        onSuccess: ({ data }) => {
          setRefetchSite(true);
        },
        onSettled: () => {
          queryClient.invalidateQueries(queryKey);
        },
      },
    });

  const setAlertMessage = (variant: string, descKey: string) => {
    generateAlert({
      severity: variant,
      description: descKey,
      showCloseBtn: true,
    });
  };

  const isValueUpdated = Number(currentStorageValue) !== Number(siteTotal);

  return (
    <FormProvider {...methods}>
      <Card>
        <CardHeader title={t('adjustStorage.title')} />
        <CardContent>
          <Stack spacing={3} alignItems={'flex-start'}>
            <Typography variant="body1">{t('adjustStorage.desc')}</Typography>
            <Grid
              container
              alignItems="baseline"
              justifyContent="space-between"
            >
              <Storage
                showLabel
                showStats
                siteStorage={site?.resources?.storage}
                siteId={Number(siteId)}
                defaultValue={siteTotal}
                isLoading={isSiteLoading}
              />
              <LoadingButton
                loading={refetchSite || updatingStorageAllocation}
                variant="outlined"
                disabled={!isStorageValid || !isValueUpdated}
                onClick={() =>
                  updateSite({
                    siteId: Number(siteId),
                    data: { disk_limit_gib: Number(currentStorageValue) },
                  })
                }
              >
                {t('adjustStorage.updateBtn')}
              </LoadingButton>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </FormProvider>
  );
}
