import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { getHostingDisabled } from '~/utils/getHostingDisabled';
import { getSiteDisabled } from '~/utils/getSiteDisabled';
import { getIsPendingAction } from '~/utils/siteMeta';

import LogInButton from './LogInButton';
import Menu from './Menu';
import PrimaryAction from './SiteCardPrimaryAction';
import SiteTitle from './SiteTitle';

const propTypes = {
  list: arrayOf(
    shape({
      id: oneOfType([string, number]),
      name: string,
      type: string,
      url: string,
      pending_url: string,
      doc_root: string,
      ssl: bool,
    }),
  ),
};

const SitesTableBody = ({ list = undefined }) => {
  const { t } = useTranslation('sites');
  const theme = useTheme();

  return list?.map((site) => {
    const { id, type, status, detected_type, hosting } = site;
    const isPendingAction = getIsPendingAction(site);
    const siteDisabled = getSiteDisabled(status);
    const isDisabled = getHostingDisabled(site?.hosting?.status);

    return (
      <TableRow
        className="xyz-nested"
        key={`site-${id}`}
        sx={{
          // pending site rows should display with a grey background
          backgroundColor:
            isPendingAction || isDisabled ? theme.palette.grey[100] : undefined,
        }}
      >
        {/* Site Name & URL */}
        <TableCell data-testid={`site-${id}`}>
          <SiteTitle {...site} disabled={isDisabled} />
        </TableCell>

        {/* Platform */}
        <TableCell>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body2">
              {type ? t(`platform.${type}`) : t('platform.generic')}
            </Typography>
            <LogInButton
              id={id}
              type={type}
              status={status}
              disabled={isDisabled || siteDisabled}
              detected_type={detected_type}
              viewActivated="list"
              hostingId={hosting?.hosting_id}
            />
          </Stack>
        </TableCell>

        {/* Actions */}
        <TableCell align="right">
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <PrimaryAction
              status={status}
              type={type}
              id={id}
              migration_id={site?.migration_id}
              disabled={isDisabled}
            />
            <Menu
              disabled={isDisabled || siteDisabled}
              id={id}
              status={status}
              type={type}
              detected_type={detected_type}
              url={site?.url}
            />
          </Stack>
        </TableCell>
      </TableRow>
    );
  });
};

SitesTableBody.propTypes = propTypes;

export default SitesTableBody;
