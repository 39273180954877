import { isNumber } from 'lodash';
import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import RemoveIcon from '@mui/icons-material/Remove';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useHostingAccount, useSitesInfo } from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import { validateWorkers } from '~/scenes/Cloud/utils/validations';
import { blockInvalidNumberChar } from '~/utils/blockInvalidNumberChar';

interface WorkersTypes {
  showLabel?: boolean;
  defaultValue: number | undefined | null;
  hideControls?: boolean;
  siteId?: number | undefined;
  isWorkersDisabled?: boolean;
  hideHelperText?: boolean;
}

const Workers = ({
  showLabel = false,
  hideHelperText = false,
  defaultValue,
  hideControls = false,
  siteId = undefined,
  isWorkersDisabled = false,
  ...props
}: PropsWithChildren<WorkersTypes>) => {
  const { t } = useTranslation('addSites');
  const { register, watch, setValue } = useFormContext();

  const workersRegisteredId = isNumber(siteId)
    ? `${siteId}-workers`
    : 'workers';
  const value = watch(workersRegisteredId, defaultValue);

  const { id: hostingId } = useAccount();
  const { data: hostingAccountData, isLoading } = useHostingAccount(hostingId, {
    query: {
      enabled: !!hostingId,
      select: ({ data }) => data,
    },
  });

  const { data: siteData } = useSitesInfo(Number(siteId), {
    query: {
      enabled: !!siteId,
    },
  });
  const workersAvailable = hostingAccountData?.resources?.workers?.available;
  const minAllowed = 2;

  const siteWorkers = (!!siteId && siteData?.data?.resources?.workers) || 0;

  const calculateMaxAllowed = (
    workers: number,
    additionalWorkers: number = 0,
  ) => Math.min(workers + additionalWorkers, 10);

  const maxAllowed = isNumber(workersAvailable)
    ? calculateMaxAllowed(workersAvailable, siteId ? siteWorkers : 0)
    : 10;

  const { isValid, errorMessage } = validateWorkers({
    value,
    min: minAllowed,
    max: maxAllowed,
    ...(!!siteId && {
      available: workersAvailable,
    }),
    ...(!!siteId && { allocated: siteData?.data?.resources?.workers }),
  });

  const decrementWorkerValue = () =>
    setValue(workersRegisteredId, Number(value) - 1);

  const incrementWorkerValue = () =>
    setValue(workersRegisteredId, Number(value) + 1);

  const helperTextStyles = {
    maxWidth: !showLabel ? '150px' : '250px',
    ...(showLabel && { px: '40px' }),
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems={'center'}>
        {isLoading ? (
          <Skeleton width={260} height={56} />
        ) : (
          <>
            {!hideControls && (
              // @ts-ignore
              <IconButton
                styletype="counter"
                onClick={decrementWorkerValue}
                disabled={isWorkersDisabled || value <= minAllowed}
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
            )}
            <TextField
              id={`${siteId}`}
              type="number"
              {...(showLabel && {
                label: (
                  <Stack direction="row" spacing={1} alignItems={'center'}>
                    <Typography>{t('siteTitle.phpWorkersLabel')}</Typography>
                    <Tooltip
                      title={t('siteTitle.phpWorkersTooltip')}
                      arrow
                      placement="top"
                      data-testid="workers-tooltip"
                    >
                      {/* @ts-ignore */}
                      <InfoIcon color="secondary" />
                    </Tooltip>
                  </Stack>
                ),
              })}
              {...(!showLabel && { sx: { width: '70px' } })}
              data-testid={`${workersRegisteredId}-input`}
              variant="outlined"
              value={value}
              error={!isValid}
              disabled={hideControls || isWorkersDisabled}
              onKeyDown={blockInvalidNumberChar}
              {...register(workersRegisteredId)}
              {...props}
            />

            {!hideControls && (
              // @ts-ignore
              <IconButton
                styletype="counter"
                onClick={incrementWorkerValue}
                disabled={isWorkersDisabled || value >= maxAllowed}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            )}
          </>
        )}
      </Stack>
      {!hideHelperText && !isValid && (
        <FormHelperText
          error
          {...((!hideControls || !showLabel) && { sx: helperTextStyles })}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </Stack>
  );
};

export default Workers;
