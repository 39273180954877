import { useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ResetDBPassword = ({
  open = false,
  handleClose = () => {},
  handleContinue = () => {},
  isLoading = false,
  isDisabled = false,
}) => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'databases.resync',
  });

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Divider />
          <Stack direction={'row'} spacing={1}>
            <WarningIcon color="error" />
            <Typography>{t('desc')}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>{t('cancel')}</Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleContinue}
          disabled={isDisabled}
        >
          {t('continue')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResetDBPassword;
