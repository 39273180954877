import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';

import { useAccountHostingList } from '@newfold/huapi-js';

import useHostingInfo from '~/hooks/useHostingInfo';

import AddSiteValidation from './Validation';

const AddSiteButton = ({
  showingAllSites = false,
  isLoading = false,
  children = undefined,
}: PropsWithChildren<{
  showingAllSites?: boolean;
  isLoading?: boolean;
}>) => {
  const { t } = useTranslation('sites');

  const [selected, setSelected] = useState(false);

  const { isFetching: loadingHostingAccounts } = useAccountHostingList({
    query: {
      enabled: showingAllSites,
    },
  });

  const { isDisabled } = useHostingInfo();

  // handle start validation
  const handleValidation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    return setSelected(true);
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        sx={{ mr: 1 }}
        disabled={isDisabled || isLoading}
        loading={isLoading || loadingHostingAccounts}
        data-testid="add-site-btn"
        onClick={handleValidation}
      >
        {children || t('addSiteBtn')}
      </LoadingButton>

      <AddSiteValidation
        open={selected}
        setSelected={setSelected}
        showingAllSites={showingAllSites}
      />
    </>
  );
};

export default AddSiteButton;
