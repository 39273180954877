import orderSort from 'lodash/orderBy';
import { PropsWithChildren, ReactNode, useMemo, useState } from 'react';

import Pagination, { PaginationProps } from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import useResponsive from '~/hooks/useResponsive';
import paginate from '~/utils/paginate';

import VisionUICardList from './CardList';
import VisionUITable from './Table';

interface ResponsiveTableOptions {
  type?: string;
  list: {}[];
  headCells?: { id: string; label: string; sort?: boolean }[];
  isLoading?: boolean;
  showCardsUI?: boolean;
  mobile?: {
    ui?: ReactNode;
    loader?: ReactNode;
  };
  pageSize?: number;
  paginationProps?: PaginationProps;
  showAllCTA?: ReactNode;
  children: ReactNode;
}

export default function ResponsiveTable({
  type = 'list',
  isLoading = false,
  headCells = [],
  showCardsUI = false,
  mobile = {
    ui: undefined,
    loader: undefined,
  },
  paginationProps = {},
  pageSize = 10,
  showAllCTA = undefined,
  children = null,
  ...props
}: PropsWithChildren<ResponsiveTableOptions>) {
  const { isTablet } = useResponsive();
  const showCards = (showCardsUI || isTablet()) && mobile?.ui;

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

  const list = props?.list;
  const listLength = list?.length;

  const listSorted = useMemo(() => {
    // @ts-expect-error
    return orderSort(list, [(item) => item[orderBy]?.toLowerCase()], [order]);
  }, [order, orderBy, list]);

  const pageDefault = paginationProps?.defaultPage || 1;
  const [page, setPage] = useState(pageDefault);

  const showPagination = listLength > pageSize;
  // @ts-expect-error
  const paginatedList = paginate(listSorted, pageSize);
  const currentList = paginatedList?.[page - 1];

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      {showCards ? (
        <VisionUICardList
          {...props}
          type={type}
          isLoading={isLoading}
          // @ts-expect-error
          loader={mobile?.loader}
          list={currentList}
        >
          {/* @ts-expect-error */}
          {mobile?.ui}
        </VisionUICardList>
      ) : (
        <VisionUITable
          {...props}
          type={type}
          isLoading={isLoading}
          headCells={headCells}
          list={currentList}
          order={order}
          orderBy={orderBy}
          // @ts-expect-error
          handleRequestSort={handleRequestSort}
        >
          {/* @ts-expect-error */}
          {children}
        </VisionUITable>
      )}
      {showPagination && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          alignItems={{ xs: 'start', sm: 'center' }}
        >
          <Pagination
            shape="rounded"
            count={paginatedList?.length}
            page={page}
            onChange={handleChange}
            {...paginationProps}
          />
          {showAllCTA}
        </Stack>
      )}
    </>
  );
}
