import { capitalize } from 'lodash';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { AccountHostingList200AccountsItem } from '@newfold/huapi-js/src/index.schemas';

type HostingOptions = {
  hosting: AccountHostingList200AccountsItem;
};

export default function HostingTableRow({ hosting }: HostingOptions) {
  return (
    <TableRow className="xyz-nested">
      {/* Hosting Plan Name */}
      <TableCell data-testid={`hosting-${hosting?.hosting_id}`}>
        <Typography>
          {hosting?.feature_set} - {hosting?.hosting_id}
        </Typography>
      </TableCell>

      {/* Status */}
      <TableCell>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body2">{capitalize(hosting?.status)}</Typography>
        </Stack>
      </TableCell>

      {/* Account */}
      <TableCell>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body2">{hosting?.account_id}</Typography>
        </Stack>
      </TableCell>

      {/* Renewal Date */}
      <TableCell>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body2">
            Sites - {hosting?.current_sites}
          </Typography>
        </Stack>
      </TableCell>

      {/* Actions */}
      <TableCell align="right">
        <Button variant="contained" href={hosting?.hosting_id}>
          Manage
        </Button>
      </TableCell>
    </TableRow>
  );
}
