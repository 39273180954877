export const disableList = [
  'disable',
  'disabled',
  'pending_destroy',
  'destroy',
  'deleted',
  'decom',
  'pending_decom',
];

export const getHostingDisabled = (status) => {
  // returns `true` by default to prevent a false positive from returning before the parent fuction completes, letting whatever element that's rendered by this function flash as `enabled` then `disabled` if/when a truthy value returns
  if (!status) return true;
  const lowerCaseStatus = status?.toLowerCase();
  return disableList.includes(lowerCaseStatus);
};
