import { useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CTB from '~/components/CTB';
import useFeature from '~/hooks/useFeature';

const DBWarningModal = ({ openSiteLimit, setOpenSiteLimit }) => {
  const { t } = useTranslation('sites');
  const [canUpgrade] = useFeature('hosting_upgrade');

  const handleClose = () => setOpenSiteLimit(false);

  return (
    <Dialog
      fullWidth
      open={openSiteLimit}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="add-db-warning-modal"
    >
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <WarningIcon color="error" />
          <DialogTitle>{t('dbLimit')}</DialogTitle>
        </Stack>
        <Typography variant="body1" pb={2}>
          {t('availableDb')}
        </Typography>
        <Stack spacing={1} direction="row" justifyContent="flex-end" mt={3}>
          <Button onClick={handleClose} variant="text">
            {t('close')}
          </Button>
          {canUpgrade && (
            <CTB product="HOSTING_UPGRADE">
              <Button variant="outlined">{t('upgrade')}</Button>
            </CTB>
          )}
          <Button href="./databases" variant="contained">
            {t('manageDatabases')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DBWarningModal;
