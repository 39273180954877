import { isNumber } from 'lodash';
import { string } from 'yup';

interface ValidateStorageOptions {
  value: number;
  min: number;
  max: number;
}

const validateStorage = ({ value, min, max }: ValidateStorageOptions) => {
  if (!value)
    return {
      isValid: false,
      errorMessage: 'field required',
    };

  if (value < min)
    return {
      isValid: false,
      errorMessage: `sites need a minimum storage of ${min} GB`,
    };

  if (max < 200 && value > max)
    return {
      isValid: false,
      isExceedMaxStorage: true,
      errorMessage: 'cannot exceed the available storage',
    };

  if (max === 200 && value > max)
    return {
      isValid: false,
      errorMessage: 'sites are allowed a maximum storage of 200 GB',
    };

  return { isValid: true, isExceedMaxStorage: false };
};

interface ValidateWorkersOptions {
  value: number;
  min: number;
  max: number;
  available?: number | undefined | null;
  allocated?: number | undefined | null;
}

const validateWorkers = ({
  value,
  min,
  max,
  available = undefined,
  allocated = undefined,
}: ValidateWorkersOptions) => {
  const diff = value - (allocated ?? 0);

  if (!value)
    return {
      isValid: false,
      errorMessage: 'field required',
    };

  if (value < min)
    return {
      isValid: false,
      errorMessage: 'sites need a minimum of 2 PHP workers',
    };

  if ((max < 10 && diff > max) || (isNumber(available) && diff > available))
    return {
      isValid: false,
      errorMessage: 'cannot exceed the available PHP workers',
    };

  if (max === 10 && value > max)
    return {
      isValid: false,
      errorMessage: 'sites are allowed a maximum of 10 PHP workers',
    };

  if (isNumber(allocated) && allocated === 10 && value > 10) {
    return {
      isValid: false,
    };
  }

  return { isValid: true };
};

const validateEmail = (email: string) =>
  string().email('Must be a valid email address').isValidSync(email);

export { validateEmail, validateStorage, validateWorkers };
