// Remove SX props
import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { isDevMode } from '~/components/DevMode';
import useFilteredSites from '~/hooks/useFilteredSites';

import Menu from './Menu';

const SiteName = ({ siteName, siteId, isLoading, children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const isTruncated = (str) => str?.length > 15;

  const tooltipToggle = () => {
    if (!isTruncated(siteName)) return;
    setTooltipOpen(!tooltipOpen);
  };

  if (isLoading)
    return (
      <Typography noWrap variant="h1" sx={{ mr: 1 }} data-testid="site-name">
        <Skeleton width={220} />
      </Typography>
    );

  return (
    <>
      <Tooltip
        open={tooltipOpen}
        onClose={tooltipToggle}
        onOpen={tooltipToggle}
        title={siteName}
        enterDelay={500}
        leaveDelay={250}
      >
        <Typography noWrap variant="h1" sx={{ mr: 1 }} data-testid="site-name">
          {!siteName ? `Site ${siteId}` : <>{siteName}</>}
        </Typography>
      </Tooltip>
      {children}
    </>
  );
};

const propTypes = {};

const SiteTitle = ({ site, isLoading }) => {
  const { data: sitesData } = useFilteredSites();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    if (anchorEl !== event.currentTarget)
      return setAnchorEl(event.currentTarget);
    return setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const site = data?.data;
  const sites = sitesData?.data;

  return (
    <>
      {/* only display dropdown if the user has more than one site  - Devmode until UX finilaizes the design.*/}
      {sites?.items?.length > 1 && isDevMode ? (
        <>
          <ButtonBase
            href="#change-site"
            color="inherit"
            underline="none"
            onClick={handleClick}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <SiteName
              siteName={site?.name}
              siteId={site?.id}
              isLoading={isLoading}
            >
              <ExpandMoreIcon />
            </SiteName>
          </ButtonBase>
          <Menu sites={sites} anchorEl={anchorEl} handleClose={handleClose} />
        </>
      ) : (
        <SiteName
          siteName={site?.name}
          siteId={site?.id}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

SiteTitle.propTypes = propTypes;
export default SiteTitle;
