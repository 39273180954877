import { isDevMode } from '~/components/DevMode';

declare global {
  interface Window {
    FS: any;
  }
}

export default function useFullStory() {
  const FS = window.FS;
  const event =
    typeof FS?.event === 'function'
      ? FS.event
      : (eventName = '', eventProperties = {}) => {
          if (isDevMode)
            console.log('Logged FullStory Event', {
              eventName,
              eventProperties,
            });
        };

  return {
    event,
  };
}
