import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import {
  getHostingAdvancedServerQueryKey,
  useHostingAdvancedServer,
  useHostingAdvancedServerStatus,
} from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';

const ServerManagement = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: `serverManagement`,
  });

  const { id: hostingId } = useAccount();

  const queryClient = useQueryClient();
  const queryKey = getHostingAdvancedServerQueryKey(hostingId);

  //server states
  const intermediateStates = [
    'start',
    'stop',
    'reboot_soft',
    'pending_resize',
    'resize',
  ];
  const toggleOnStates = ['active', 'start', 'reboot_soft'];
  const finalStates = ['active', 'stopped'];

  const refetchInterval = 5000;

  //Get Hosting Capabilities
  const [showServerControls, , clientReady] = useFeature('server_controls');

  //CTB upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );

  //Get Server Status
  const {
    data,
    isLoading: isLoadingServerStatus,
    isError: isErrorServerStatus,
    refetch: refetchServerStatus,
  } = useHostingAdvancedServer(hostingId, {
    query: {
      enabled: !!hostingId && clientReady && showServerControls,
      refetchInterval: (data) => {
        return !!hostingId &&
          clientReady &&
          showServerControls &&
          intermediateStates.includes(data?.data?.status)
          ? refetchInterval
          : false;
      },
      onSuccess: async ({ data }) => {
        if (
          !isLoadingServerStatus &&
          showServerControls &&
          finalStates.includes(data?.data?.status)
        ) {
          //TODO - Alert Message to be provided by the product.
        }
      },
    },
  });

  //Put Server Status.
  const { mutate: serverStatusToggle } = useHostingAdvancedServerStatus({
    mutation: {
      onMutate: (newStatus) => {
        const oldStatus = queryClient.getQueryData(queryKey)?.data.status;
        queryClient.setQueryData(queryKey, newStatus);
        return { oldStatus, newStatus };
      },
      onSuccess: async ({ data }) => {
        //TODO -  Alert Message to be provided by the product.
      },
      // onError: (error, data, rollbackStatus) => {
      //   rollbackStatus();
      // },
    },
  });

  useEffect(() => {
    if (showServerControls && hasPurchasedRecently === false) {
      refetchServerStatus();
    }
  }, [showServerControls, hasPurchasedRecently, refetchServerStatus]);

  const serverStatus = data?.data.status;

  const isToggleOn = toggleOnStates.includes(serverStatus);
  const isIntermediateState = intermediateStates.includes(serverStatus);

  //ToggleButton
  const handleServerStatus = async () => {
    const request = serverStatus === 'active' ? 'stop' : 'start';
    serverStatusToggle({
      hostingId,
      data: {
        status: request,
        action: request,
      },
    });
  };

  //RebootButton
  const handleRebootServer = async () => {
    const request = serverStatus === 'active' ? 'reboot' : 'start';
    serverStatusToggle({
      hostingId,
      data: {
        status: 'reboot_soft',
        action: request,
      },
    });
  };

  //Server Status Label
  const getServerStateLabel = () => {
    switch (serverStatus) {
      default:
      case 'pending_resize':
      case 'resize':
        return 'loading';
      case 'active':
        return 'running';
      case 'stopped':
        return 'stopped';
      case 'start':
      case 'pending_start':
        return 'starting';
      case 'stop':
      case 'pending_stop':
        return 'stopping';
      case 'reboot_soft':
      case 'pending_reboot_soft':
        return 'rebooting';
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h3">{t('title')}</Typography>
          <Typography variant="body2" data-testid="label-server-status">
            {t(getServerStateLabel())}
          </Typography>
          <Switch
            disabled={
              isErrorServerStatus ||
              isIntermediateState ||
              isLoadingServerStatus ||
              hasPurchasedRecently
            }
            data-testid="btn-server-status"
            checked={!isLoadingServerStatus && isToggleOn}
            onClick={() => handleServerStatus()}
          />
        </Stack>
        <Button
          variant="outlined"
          data-testid="btn-reboot-server"
          disabled={
            isErrorServerStatus ||
            serverStatus !== 'active' ||
            hasPurchasedRecently
          }
          onClick={() => handleRebootServer()}
        >
          <SettingsBackupRestoreIcon />
          {t('rebootServerBtn')}
        </Button>
      </Stack>
    </>
  );
};

export default ServerManagement;
