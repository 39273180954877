import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function SiteError() {
  return (
    <Container>
      <Stack spacing={2} alignItems={'flex-start'}>
        <Typography variant="h1">Unable to load site</Typography>
        <Typography variant="body1">
          We were unable to load the site you requested. Please try again later,
          or contact our support team.
        </Typography>
        <Button variant="outlined" href="/">
          Return to Hosting
        </Button>
      </Stack>
    </Container>
  );
}
