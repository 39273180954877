import { useEffect, useState } from 'react';

export default function useLocalStorage(
  key: string,
  defaultValue: any,
  enabled: boolean = true,
) {
  const [value, setValue] = useState(() => {
    let currentValue;

    try {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue),
      );
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    if (enabled) {
      localStorage.setItem(key, JSON.stringify(value));
    }
    const hasLSItem = !!localStorage.getItem(key);
    if (!enabled && hasLSItem) {
      localStorage.removeItem(key);
    }
  }, [value, key, enabled]);

  return [value, setValue];
}
