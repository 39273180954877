import { capitalize } from 'lodash';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { AccountHostingList200AccountsItem } from '@newfold/huapi-js/src/index.schemas';

import CardMenu from './CardMenu';

type HostingListOptions = {
  hosting: AccountHostingList200AccountsItem;
};

export default function HostingCard({ hosting }: HostingListOptions) {
  return (
    <Card>
      <CardHeader
        variant="cardlistheader"
        title={`${hosting?.feature_set} - ${hosting?.hosting_id}`}
        action={<CardMenu hosting={hosting} />}
      />
      <CardContent>
        <Stack spacing={1}>
          <Typography>Status: {capitalize(hosting?.status)}</Typography>
          <Typography>Account: {hosting?.account_id}</Typography>
          <Typography>
            Renewal Date: Sites - {hosting?.current_sites}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
