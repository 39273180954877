// Remove SX props
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import SsoLink from '~/components/SsoLink';

import SsoMenu from './SsoMenu';
import UserTableHead from './UserTableHead';

const propTypes = {
  isLoading: bool.isRequired,
  users: arrayOf(
    shape({
      id: number.isRequired,
      display_name: string.isRequired,
      user_email: string.isRequired,
      role: string.isRequired,
    }),
  ),
};

const tableContainerSx = {
  boxShadow: 0,
  borderTopRightRadius: '4px',
  borderTopLeftRadius: '4px',
};

const tableSx = { border: '1px solid', borderColor: 'divider' };

const UsersTable = ({ users = [], isLoading }) => {
  const { t } = useTranslation('users');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const tableHeader = (
    <UserTableHead
      order={order}
      orderBy={orderBy}
      onRequestSort={handleRequestSort}
      rowCount={users.length}
    />
  );

  if (isLoading) {
    return (
      <TableContainer sx={tableContainerSx} data-testid="user-table-loader">
        <Table sx={tableSx}>
          {tableHeader}
          <TableBody>
            {[1, 2, 3, 4, 5, 6].map((key) => (
              <TableRow key={key}>
                <TableCell>
                  <Skeleton variant="text" width={100} sx={{ my: 1 }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={150} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={100} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={60} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width={20} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer sx={tableContainerSx}>
      <Table sx={tableSx} aria-labelledby="tableTitle">
        {tableHeader}
        <TableBody>
          {users.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} data-testid="no-users-found">
                {t('noDataFound')}
              </TableCell>
            </TableRow>
          ) : (
            stableSort(users, getComparator(order, orderBy)).map((user) => {
              return (
                <TableRow role="checkbox" tabIndex={-1} key={user.id}>
                  <TableCell>{user.display_name}</TableCell>
                  <TableCell>{user.user_email}</TableCell>
                  <TableCell data-testid="user-role">{user.role}</TableCell>
                  <TableCell>
                    <SsoLink
                      type="site"
                      variant="text"
                      queryParams={{ app: 'profile' }}
                      userId={user.id}
                    >
                      {t('login')}
                    </SsoLink>
                  </TableCell>
                  <TableCell>
                    <SsoMenu userId={user.id} />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

UsersTable.propTypes = propTypes;
export default UsersTable;
