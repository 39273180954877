import { useQueryClient } from '@tanstack/react-query';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { number } from 'yup';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import {
  getSitePluginsQueryKey,
  useSitePlugins,
  useSitePluginsUpdate,
  useSitesAutoupdatesInfo,
  useSitesSso,
  useSitesVersion,
} from '@newfold/huapi-js';

import useAlerts from '~/components/Alerts/alertsStore';
import StatusListItem from '~/components/StatusListItem';
import { useSiteContext } from '~/scenes/Site';
import UpdatePHPVersion from '~/scenes/Site/components/UpdatePHPVersion';

import { ReactComponent as IconPhpVersion } from '../../../../../Sites/assets/icons/IconPhpVersion.svg';
import WooCommerceIcon from '../../../../../Sites/assets/icons/WooCommerceIcon';
import WordPressIcon from '../../../../../Sites/assets/icons/WordPressIcon';
import AutoUpdateType from './components/AutoUpdateType';

const propTypes = {
  siteInfo: shape({
    id: number,
    type: string,
    php_version: string,
  }),
  refetchSiteInfo: () => {},
  wpSsoBounceName: string,
};

const UpdateStatus = ({
  siteInfo = {
    id: '',
    type: '',
    php_version: 'N/A',
  },
  refetchSiteInfo = () => {},
  wpSsoBounceName = 'updatecore',
}) => {
  const { t } = useTranslation('site');
  const { siteId } = useParams();
  const [, { generateAlert }] = useAlerts();

  // Condition for calls if site type is wordpress
  const canShow = siteInfo.type === 'wordpress';

  const { isSiteOrHostingDisabled: isDisabled } = useSiteContext();

  const params = {
    bounce_name: wpSsoBounceName,
  };

  const {
    data: wpVersion,
    isInitialLoading: isLoadingWPVersion,
    isSuccess: isSuccessWPVersion,
  } = useSitesVersion(siteId, {
    query: {
      enabled: canShow,
      select: ({ data }) => data,
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('updateStatus.wpVersionError', {
            displayName: wpDisplayName,
          }),
          showCloseBtn: true,
        });
      },
    },
  });

  const { isFetching, refetch: fetchSso } = useSitesSso(siteId, params, {
    query: {
      enabled: false,
    },
  });

  const {
    data: WooCommercePlugin,
    isInitialLoading: isLoadingPlugins,
    isSuccess: isSuccessWooVersion,
    refetch: fetchPluginData,
  } = useSitePlugins(siteId, {
    query: {
      select: ({ data }) =>
        data.plugins.find((plugin) => plugin.id === 'woocommerce'),
    },
  });

  const queryClient = useQueryClient();
  const queryKeyPlugin = getSitePluginsQueryKey(siteId);

  const { isLoading: isLoadingPluginUpdate, mutate: pluginUpdate } =
    useSitePluginsUpdate({
      mutation: {
        onMutate: async () => {
          await queryClient.cancelQueries(queryKeyPlugin);
        },
        onError: () => {
          generateAlert({
            severity: 'error',
            description: t('updateStatus.errorOnPluginUpdate'),
            showCloseBtn: true,
          });
        },
        onSettled: (data, error) => {
          if (error) {
            queryClient.invalidateQueries(queryKeyPlugin);
          }
        },
        onSuccess: () => {
          fetchPluginData();
          generateAlert({
            severity: 'success',
            description: t('updateStatus.successOnUpdatePlugin'),
            showCloseBtn: true,
          });
        },
      },
    });

  const handlePluginUpdate = () => {
    pluginUpdate({
      siteId: siteId,
      data: {
        id: 'woocommerce',
      },
    });
  };

  const redirectToSsoUrl = async () => {
    const { data, error } = await fetchSso();

    const url = data?.data?.sso;

    if (error || !url) {
      return generateAlert({
        severity: 'error',
        description: t('error.alertError'),
        showCloseBtn: true,
      });
    }
    window.open(url, '_blank');
  };

  const { data: wpDataAU, isInitialLoading: isLoadingAutoUpdates } =
    useSitesAutoupdatesInfo(siteId, {
      query: {
        enabled: !!siteId && canShow,
        select: ({ data }) => data,
        onError: () => {
          generateAlert({
            severity: 'error',
            description: t('error.alertError', {
              item: t('updateStatus.title') + '. ',
            }),
            showCloseBtn: true,
          });
        },
      },
    });

  const wpDisplayName = siteInfo?.type === 'wordpress' ? 'WordPress' : '';
  const phpVersion = siteInfo.php_version;

  return (
    <Grid item xs={4} sm={4} md={4}>
      <Typography variant="h3">{t('updateStatus.title')}</Typography>
      <List>
        {canShow && (
          <StatusListItem
            isLoading={isLoadingWPVersion}
            ctaIsLoading={isFetching}
            avatarIcon={WordPressIcon}
            avatarIconColor="black"
            ctaText={t('updateNowBtn')}
            disableCTA={isDisabled}
            {...(wpVersion?.update && { handleCTA: redirectToSsoUrl })}
            dataTestId="wpVersion"
          >
            {`${t('updateStatus.versionText', {
              displayName: wpDisplayName,
            })} ${isSuccessWPVersion ? wpVersion?.current : 'N/A'}`}
          </StatusListItem>
        )}

        {WooCommercePlugin && canShow && (
          <StatusListItem
            isLoading={isLoadingPlugins}
            ctaIsLoading={isLoadingPluginUpdate}
            avatarIcon={WooCommerceIcon}
            avatarIconColor="black"
            ctaText={t('updateNowBtn')}
            disableCTA={isDisabled}
            {...(WooCommercePlugin?.update && {
              handleCTA: handlePluginUpdate,
            })}
            dataTestId="wooCommerce"
          >
            {`${t('updateStatus.versionText', {
              displayName: WooCommercePlugin.title,
            })} ${isSuccessWooVersion ? WooCommercePlugin?.version : 'N/A'}`}
          </StatusListItem>
        )}

        {siteInfo &&
          canShow &&
          siteInfo.type === siteInfo.detected_type &&
          t('updateStatus.autoUpdate.items', { returnObjects: true }).map(
            ({ type, label }) => (
              <AutoUpdateType
                key={type}
                siteId={siteId}
                type={type}
                typeLabel={label}
                wpDataAU={wpDataAU}
                isLoading={isLoadingAutoUpdates}
              />
            ),
          )}
        <StatusListItem
          avatarIcon={IconPhpVersion}
          dataTestId="PhpVersion"
          inlineCTA={
            <UpdatePHPVersion
              siteId={siteInfo.id}
              siteUrl={siteInfo.url}
              currentPHP={phpVersion}
              refetchSiteInfo={refetchSiteInfo}
            />
          }
          inRow={true}
        >
          {t('phpVerTxt', {
            version: !phpVersion ? 'N/A' : phpVersion.replace('PHP ', ''),
          })}
        </StatusListItem>
      </List>
    </Grid>
  );
};

UpdateStatus.propTypes = propTypes;
export default UpdateStatus;
