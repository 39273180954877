import { Trans, useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import blue from '@mui/material/colors/blue';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CopyButton from './CopyButton';

const ViewIPModal = ({ openIPModal, setOpenIPModal, ip }) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard.ipAddress.modal',
  });

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenIPModal(false);
  };

  //TODO: Removed the learn more link to advanced/ssh. as the nameserver info available in hosting overview page. If necessary, with the product.
  return (
    <Dialog
      open={openIPModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
      PaperProps={{
        sx: {
          '@media (max-width: 768px)': {
            position: 'fixed',
            top: 16,
            mt: 0,
            mr: 1,
            mb: 0,
            ml: 1,
          },
        },
      }}
    >
      <DialogTitle fontSize="20px">{t('title')}</DialogTitle>
      <Divider sx={{ mt: 0, mr: 3, mb: 0, ml: 3, borderColor: 'grey.500' }} />
      <DialogContent sx={{ p: 3, color: 'grey.800' }}>
        <Stack direction="row" spacing={2} pb={2}>
          <WarningIcon color="error" />
          <Typography>
            <Trans i18nKey="settings:nameServerAndIPCard.ipAddress.modal.desc" />
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography>{t('subTitle')}</Typography>
          <Typography>
            <b>{ip}</b>
          </Typography>
          <CopyButton disable={ip === undefined} text={ip} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pt: 0, pr: 3, pb: 3, pl: 3 }}>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            backgroundColor: blue[900],
            '@media (max-width: 768px)': {
              width: '100%',
            },
          }}
        >
          {t('closeBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewIPModal;
