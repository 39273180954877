import Container from '@mui/material/Container';

import useFeature from '~/hooks/useFeature';
import AlertBanner from '~/scenes/Hosting/components/AlertBanner';

import SiteDetailShared from '../../Shared';
import SiteDetailCloud from '../Cloud';

export default function SiteDetailFork() {
  const [isAtomic, , isClientReady] = useFeature('atomic');
  // TODO: Add Loading... state
  if (!isClientReady) return null;

  return (
    <>
      <Container
        sx={{
          marginBottom: 3,
        }}
      >
        <AlertBanner />
      </Container>
      {isAtomic ? <SiteDetailCloud /> : <SiteDetailShared />}
    </>
  );
}
