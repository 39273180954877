import { AccountHostingList200AccountsItem } from '@newfold/huapi-js/src/index.schemas';

import HostingTableRow from './HostingTableRow';

type HostingTableBodyOptions = {
  list?: AccountHostingList200AccountsItem[];
};

export default function HostingTableBody({
  list = [],
}: HostingTableBodyOptions) {
  return (
    <>
      {list?.map((hosting) => (
        <HostingTableRow
          key={`hosting-${hosting?.hosting_id}`}
          hosting={hosting}
        />
      ))}
    </>
  );
}
