import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

const ActionHandlers = ({ userName, isDisabled = false }) => {
  const navigate = useNavigate();
  //const editUser = `users/edit`;
  const removeUser = `users/remove`;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertRounded />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClick={handleMenuClose}
        onClose={handleMenuClose}
      >
        <MenuItem
          disabled={isDisabled}
          onClick={() =>
            navigate(removeUser, {
              state: {
                changeSiteUrl: true,
                userName: userName,
              },
            })
          }
        >
          <Typography color="error"> Remove User</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ActionHandlers;
