import { bool, shape, oneOfType, arrayOf, node } from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const propTypes = {
  enabled: bool,
  config: shape({}),
  children: oneOfType([arrayOf(node), node]),
};

export const pushToDataLayer = (data) => {
  // adobe analytics dl
  window.digitalData = data;
  return window.digitalData;
};

const AnalyticsProvider = ({
  enabled = true,
  config = undefined,
  children = undefined,
}) => {
  const { pathname } = useLocation();

  // 1. log virtual page views
  useEffect(() => {
    // adobe event functions are stored in `window._satellite`
    const _satellite = window._satellite;
    const hasAdobeAnalytics = !!_satellite;

    // TODO: we need the ability to allow any route to customize its own digital data
    // TODO: on options would be to add future support for `route.state.pageInfo`

    if (enabled && hasAdobeAnalytics) {
      const newDataLayer = {
        event: 'vpv', // vpv means "virtual page view"
        userInfo: {
          ...window.digitalData?.userInfo,
        },
        pageInfo: {
          ...window.digitalData?.pageInfo,
          pageApplication: 'back-of-site',
          pageType: '', // Pick from support|company_info|blog|partners|homepage|product_page|landing_page|checkout
          pageClass: '', // leave blank unless on a product related page then use product name
          pageName: `vpv${pathname}`, // (path, minus unique idenfiers like acct # in url)
        },
        ...window.digitalData,
      };

      pushToDataLayer(newDataLayer);
      _satellite.track('vpv');
    }
  }, [enabled, pathname]);

  return <>{children}</>;
};

AnalyticsProvider.propTypes = propTypes;
export default AnalyticsProvider;
