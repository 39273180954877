import { FixedSizeList } from 'react-window';

import Stack from '@mui/material/Stack';

import { AccountHostingList200AccountsItem } from '@newfold/huapi-js/src/index.schemas';

import CardVirtualized from './CardVirtualized';
import HostingCard from './HostingCard';

type HostingListOptions = {
  list?: AccountHostingList200AccountsItem[];
};

export default function HostingCards({ list = [] }: HostingListOptions) {
  if (list?.length > 10)
    return (
      <FixedSizeList
        height={500}
        width={'auto'}
        itemData={list}
        itemCount={list?.length}
        itemSize={210}
        overscanCount={5}
      >
        {CardVirtualized}
      </FixedSizeList>
    );

  return (
    <Stack spacing={2}>
      {list?.map((hosting) => (
        <HostingCard key={`hosting-${hosting?.hosting_id}`} hosting={hosting} />
      ))}
    </Stack>
  );
}
