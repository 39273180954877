export default function JetpackIcon(props: any) {
  return (
    <svg
      width="140"
      height="38"
      viewBox="0 0 140 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_19478_328046)">
        <path
          d="M19.0352 0C15.2773 0 11.6039 1.11433 8.47933 3.20208C5.3548 5.28982 2.91952 8.25722 1.48145 11.729C0.0433886 15.2008 -0.332875 19.0211 0.400244 22.7067C1.13336 26.3924 2.94294 29.7778 5.60014 32.435C8.25733 35.0922 11.6428 36.9018 15.3284 37.6349C19.0141 38.368 22.8344 37.9918 26.3061 36.5537C29.7779 35.1156 32.7453 32.6804 34.8331 29.5558C36.9208 26.4313 38.0352 22.7578 38.0352 19C38.0352 13.9609 36.0334 9.12816 32.4702 5.56497C28.907 2.00178 24.0743 0 19.0352 0ZM18.0352 22.15H8.59517L18.0352 3.73V22.15ZM19.9552 34.23V15.81H29.4252L19.9552 34.23Z"
          fill="#069E08"
        />
        <path
          d="M49.0952 31.55C48.5552 30.71 48.0452 29.88 47.5352 29.08C50.2152 27.45 51.1252 26.14 51.1252 23.68V9.42999H47.9752V6.70999H54.6752V23C54.6752 27.09 53.4852 29.41 49.0952 31.55Z"
          fill="black"
        />
        <path
          d="M77.1952 21.83C77.1952 23.21 78.1952 23.35 78.8252 23.35C79.6132 23.3026 80.3924 23.1582 81.1452 22.92V25.45C80.0143 25.8289 78.8277 26.0149 77.6352 26C75.8852 26 73.8552 25.35 73.8552 22.31V14.87H72.0352V12.29H73.8752V8.47998H77.2152V12.29H81.4252V14.87H77.1952V21.83Z"
          fill="black"
        />
        <path
          d="M84.1543 32.81V12.26H87.3443V13.49C88.5918 12.461 90.1571 11.8956 91.7743 11.89C94.7743 11.89 97.1743 14 97.1743 18.53C97.1743 23.06 94.5643 26 90.2443 26C89.3184 25.9899 88.3963 25.8792 87.4943 25.67V32.78H84.1543V32.81ZM90.9043 14.65C89.6371 14.7322 88.4398 15.26 87.5243 16.14V23.14C88.3493 23.3161 89.1907 23.4032 90.0343 23.4C92.3843 23.4 93.7243 21.91 93.7243 18.79C93.7243 15.92 92.7543 14.65 90.9043 14.65Z"
          fill="black"
        />
        <path
          d="M110.305 25.71H107.185V24.22H107.105C105.89 25.3201 104.323 25.9512 102.685 26C102.184 26.0055 101.688 25.907 101.227 25.7109C100.767 25.5148 100.352 25.2252 100.009 24.8606C99.6657 24.496 99.402 24.0642 99.2344 23.5924C99.0667 23.1207 98.9988 22.6193 99.0348 22.12C99.0348 18.67 101.975 18.02 104.035 17.73L106.975 17.33V16.93C106.975 15.12 106.245 14.54 104.545 14.54C103.041 14.5941 101.556 14.9017 100.155 15.45L99.8648 12.76C101.514 12.2105 103.237 11.9171 104.975 11.89C108.235 11.89 110.345 13.2 110.345 17.08V25.71H110.305ZM106.965 19.44L104.205 19.87C103.735 19.8934 103.294 20.1005 102.976 20.4466C102.658 20.7928 102.488 21.2502 102.505 21.72C102.476 21.9348 102.494 22.1533 102.557 22.3605C102.621 22.5677 102.729 22.7586 102.873 22.9202C103.018 23.0818 103.195 23.2101 103.394 23.2964C103.593 23.3827 103.808 23.4248 104.025 23.42C105.105 23.3086 106.127 22.8807 106.965 22.19V19.44Z"
          fill="black"
        />
        <path
          d="M124.035 25.27C122.701 25.7657 121.289 26.0229 119.865 26.03C114.865 26.03 112.865 23.17 112.865 19.03C112.865 14.64 115.615 11.92 120.075 11.92C121.374 11.9114 122.665 12.1349 123.885 12.58V15.4C122.68 14.9359 121.406 14.6789 120.115 14.64C118.115 14.64 116.345 15.73 116.345 18.85C116.345 22.29 118.085 23.34 120.345 23.34C121.644 23.3108 122.924 23.0289 124.115 22.51V25.3L124.035 25.27Z"
          fill="black"
        />
        <path
          d="M130.384 18.06C130.674 17.73 130.894 17.4 135.104 12.29H139.454L134.034 18.67L140.034 25.74H135.674L130.494 19.36V25.74H127.154V6.73999H130.494V18.06H130.384Z"
          fill="black"
        />
        <path
          d="M69.5042 25.27C67.8902 25.7947 66.2012 26.0514 64.5042 26.03C60.2242 26.03 57.5742 23.9 57.5742 18.89C57.5742 15.23 59.8242 11.89 64.1342 11.89C68.4442 11.89 69.9042 14.89 69.9042 17.69C69.9042 18.64 69.8342 19.15 69.7942 19.69H61.2042C61.2742 22.63 62.9442 23.31 65.4442 23.31C66.8162 23.2728 68.1707 22.9917 69.4442 22.48V25.27H69.5042ZM66.5042 17.48C66.5042 15.85 65.9542 14.43 64.1842 14.43C62.4142 14.43 61.4942 15.63 61.2842 17.48H66.5042Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_19478_328046">
          <rect width="140" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
