import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSitesInfo } from '@newfold/huapi-js';
import { SitesInfo200 } from '@newfold/huapi-js/src/index.schemas';

import { useSiteContext } from '~/scenes/Site';

import DeleteSiteModal from './DeleteSiteModal';

const DeleteSite = () => {
  const { t } = useTranslation('cloud', { keyPrefix: 'settings' });
  const { siteId } = useParams();
  const { data } = useSitesInfo(Number(siteId));
  const siteInfo: SitesInfo200 | undefined = data?.data;

  // @ts-ignore
  const { isSiteOrHostingDisabled: isDisabled } = useSiteContext();

  const hasUrlOrPendingUrl: boolean =
    !!siteInfo?.url || !!siteInfo?.pending_url;

  const [openDeleteSiteModal, setOpenDeleteSiteModal] = useState(false);
  const showDeleteSiteModal = () => setOpenDeleteSiteModal(true);

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant="h2" color="error">
              {t('deleteSite.title')}
            </Typography>
          }
        />
        <CardContent>
          <Stack spacing={2} alignItems={'flex-start'}>
            <Typography variant="body1">{t('deleteSite.desc')}</Typography>

            <Button
              variant="outlined"
              color="error"
              disabled={isDisabled}
              onClick={showDeleteSiteModal}
              id="delete-site"
              data-testid="delete-site-btn"
            >
              {t('deleteSite.deleteSiteBtn')}
            </Button>
          </Stack>
        </CardContent>
      </Card>

      <DeleteSiteModal
        openDeleteSiteModal={openDeleteSiteModal}
        setOpenDeleteSiteModal={setOpenDeleteSiteModal}
        siteUrl={
          (hasUrlOrPendingUrl && (siteInfo?.pending_url || siteInfo?.url)) ||
          t('deleteSite.deleteSiteBtn').toUpperCase()
        }
        siteId={siteInfo?.id!}
        siteName={siteInfo?.name}
      />
    </>
  );
};
export default DeleteSite;
