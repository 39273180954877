const WooCommerceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      x="0"
      y="0"
      viewBox="0 0 256 256"
    >
      <g
        strokeMiterlimit="10"
        fontFamily="none"
        fontSize="none"
        fontWeight="none"
        textAnchor="none"
      >
        <g
          fill="#7f54b3"
          fillOpacity="0"
          style={{ mixBlendMode: 'normal' }}
          transform="translate(21.333 21.333) scale(.83333)"
        >
          <path d="M-25.6 281.6V-25.6h307.2v307.2z"></path>
        </g>
        <g
          fill="#7f54b3"
          style={{ mixBlendMode: 'normal' }}
          transform="translate(21.333 21.333) scale(.83333)"
        >
          <path d="M128 281.6c-84.83 0-153.6-68.77-153.6-153.6S43.17-25.6 128-25.6 281.6 43.17 281.6 128 212.83 281.6 128 281.6z"></path>
        </g>
        <path
          fill="#fff"
          d="M3 4a3 3 0 00-3 3v7a3 3 0 003 3h7.285l6.01 2.955a.5.5 0 00.684-.596L16 17h5c1.657 0 3-1.345 3-3.002V7a3 3 0 00-3-3zm7.248 2c.097 0 .195.019.29.059a.747.747 0 01.403.978c-.645 1.55-.97 3.976-.97 7.213a.753.753 0 01-.75.75.752.752 0 01-.59-.287c-.937-1.192-1.67-3.124-2.14-4.625L4.694 14.53a.751.751 0 01-1.42-.084c-.046-.168-1.127-4.159-1.273-7.666a.746.746 0 01.717-.779c.41-.041.762.303.78.717.071 1.728.396 3.624.692 5.056L5.93 7.47a.733.733 0 01.756-.465.75.75 0 01.671.582c.007.029.46 2.008 1.206 3.861.147-2.095.478-3.738.996-4.984a.75.75 0 01.69-.463zm3.547 1c.128 0 .257.011.385.037 1.342.28 2.098 2.017 1.724 3.953-.222 1.15-.807 2.138-1.566 2.643a2.06 2.06 0 01-1.145.367 1.88 1.88 0 01-.373-.037c-1.34-.28-2.098-2.017-1.724-3.953.222-1.15.807-2.138 1.566-2.643.362-.24.748-.364 1.133-.367zm6 0c.128 0 .257.011.385.037 1.342.28 2.098 2.017 1.724 3.953-.222 1.15-.807 2.138-1.566 2.643a2.06 2.06 0 01-1.145.367 1.88 1.88 0 01-.373-.037c-1.34-.28-2.098-2.017-1.724-3.953.222-1.15.807-2.138 1.566-2.643.362-.24.748-.364 1.133-.367zm-5.986 1.5a.584.584 0 00-.315.115c-.419.278-.782.939-.926 1.68-.236 1.222.175 2.117.56 2.197.107.024.235-.012.38-.107.419-.278.782-.939.926-1.68.235-1.222-.175-2.117-.561-2.197a.27.27 0 00-.064-.008zm6 0a.584.584 0 00-.315.115c-.419.278-.782.939-.926 1.68-.236 1.222.175 2.117.56 2.197.107.024.236-.012.38-.107.419-.278.782-.939.926-1.68.235-1.222-.175-2.117-.561-2.197a.27.27 0 00-.064-.008z"
          transform="translate(21.333 21.333) scale(.83333) translate(0 10.667) scale(10.66667)"
        ></path>
      </g>
    </svg>
  );
};

export default WooCommerceIcon;
