export enum HostingStatus {
  ACTIVE = 'Active',
  SUSPENDED = 'Suspended',
  EXPIRED = 'Expired',
  PROVISIONING = 'Provisioning',
}

declare global {
  interface Window {
    Hosting: {
      init: any;
    };
  }
}
