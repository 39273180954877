import { useSitesBackupsDetails } from '@newfold/huapi-js';

const useSiteBackups = (siteId, type) => {
  const backupDetails = useSitesBackupsDetails(siteId, {
    query: {
      enabled: !!siteId,
      select: ({ data }) => {
        if (!type) return data;
        const backup = !!data
          ? {
              ...data[type],
              has_backup_addon: !!data[type]?.addon_id,
            }
          : {};
        return backup;
      },
    },
  });

  return { ...backupDetails };
};

export default useSiteBackups;
