import { arrayOf, func, number, shape, string } from 'prop-types';

export const servicesRespShape = shape({
  data: shape({
    data: shape({
      title: string,
      rootAction: shape({
        text: string,
        link: string,
      }),
      services: arrayOf(
        shape({
          instanceId: string,
          name: string,
          accountId: number,
          form: shape({
            actionText: string,
            scriptSrc: string,
          }),
          chat: shape({
            actionText: string,
            callback: func,
          }),
          manage: shape({
            text: string,
            href: string,
          }),
          description: string,
        }),
      ),
    }),
  }),
});

// mock data for pro services bootstrapped from host app
const proServices = {
  // dashboard link to account manager's new area for pro serv
  link: '#account-manager-pro-services-page-link-here', // previously `/pro-services` for local app
  title: 'Professional Services',
  rootAction: {
    text: 'Buy Professional Services Packages',
    link: 'https://www.bluehost.com/solutions/full-service#request-form',
  },
  services: [
    {
      instanceId: '00443sm',
      productCode: 'Pro_Site_Migration',
      accountId: 12124,
      name: 'Pro Site Migration',
      description:
        'These files store crucial WordPress information, like functionality. If WordPress admin isn’t working or if you see other errors, you can run this scan to see if your WordPress core files are intact.',
      form: {
        actionText: 'Migrate',
        scriptSrc:
          'https://customerservice-stg.web.com/prweb/IAC/?pyActivity=pzIncludeMashupScripts',
      },
      chat: undefined, // if product does not have chat, this can be null
      manage: {
        // link text
        text: 'Manage',
        // link to renewal center
        href: '#TODO',
      },
    },
    {
      instanceId: '5323153ad',
      productCode: 'Pro_Design_Live',
      accountId: 12124,
      name: 'Pro Design Live',
      description:
        'These files store crucial WordPress information, like functionality. If WordPress admin isn’t working or if you see other errors, you can run this scan to see if your WordPress core files are intact.',
      form: {
        actionText: 'Request Change',
        scriptSrc:
          'https://customerservice-stg.web.com/prweb/IAC/?pyActivity=pzIncludeMashupScripts',
      },
      chat: {
        actionText: 'Support Chat',
        callback: () => {
          window.alert('starting chat...');
        },
      }, // if product does have chat, we need the relevant information to call or function to invoke it
      manage: {
        // link text
        text: 'Manage',
        // link to renewal center
        href: '#TODO',
      },
    },
    {
      instanceId: '0123453ef',
      productCode: 'Pro_Website_Design',
      accountId: 12124,
      name: 'Pro Websites',
      description:
        'These files store crucial WordPress information, like functionality. If WordPress admin isn’t working or if you see other errors, you can run this scan to see if your WordPress core files are intact.',
      form: {
        actionText: 'Request Change',
        scriptSrc:
          'https://customerservice-stg.web.com/prweb/IAC/?pyActivity=pzIncludeMashupScripts',
      },
      chat: undefined,
      manage: {
        // link text
        text: 'Manage',
        // link to renewal center
        href: '#TODO',
      },
    },
  ],
};

export default proServices;
