import { node, number, oneOfType, string } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useBrandInfo } from '~/hooks/useBrandInfo';

const propTypes = {
  siteId: oneOfType([string, number]).isRequired,
  children: oneOfType([node, string]).isRequired,
  description: string,
};

const FixSiteAlertBox = ({
  siteId,
  description = undefined,
  children = undefined,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('site');
  const { phoneNumberFormatted } = useBrandInfo();

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Alert data-testid="site-action-delete-banner" severity="warning">
        <AlertTitle>{t('fixSite.title')}</AlertTitle>
        {description || (
          <Trans
            i18nKey="site:fixSite.description"
            values={{ phoneNumber: phoneNumberFormatted }}
            components={{ b: <b></b> }}
          />
        )}
        {children || (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
            <Button onClick={() => navigate(`/sites/${siteId}/backups`)}>
              {t('fixSite.restoreBtn')}
            </Button>
            <Button
              onClick={() =>
                navigate(`/sites/${siteId}/settings#delete-content`)
              }
            >
              {t('fixSite.resetBtn')}
            </Button>
            <Button
              onClick={() => navigate(`/sites/${siteId}/settings#delete`)}
            >
              {t('fixSite.deleteBtn')}
            </Button>
          </Stack>
        )}
      </Alert>
    </Box>
  );
};
FixSiteAlertBox.propTypes = propTypes;

export default FixSiteAlertBox;
