import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSitesSso } from '@newfold/huapi-js';

import { useSiteContext } from '~/scenes/Site';

import JetpackIcon from '../../assets/JetpackIcon';

const PurchaseJetpack = () => {
  const { t } = useTranslation('backups', { keyPrefix: 'installJetpack' });
  const { siteId } = useParams();

  // @ts-ignore
  const { isSiteOrHostingDisabled: isDisabled } = useSiteContext();

  const hookOptions = {
    query: {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  };

  const { refetch } = useSitesSso(Number(siteId), undefined, hookOptions);
  const ssoLoadingPage = `${process.env.PUBLIC_URL}/loading/wordpress.html`;

  const redirectToWpAdmin = async (addonPage: string) => {
    var ssoWindow = window.open(ssoLoadingPage, '_blank');

    const { data, error } = await refetch();

    if (error) {
      ssoWindow?.close();
    }
    const url = data?.data?.sso;
    if (url) {
      // set url for window
      let urlArray = url.split('/');
      let newUrl = `${urlArray[0]}//${urlArray[2]}/${urlArray[3]}/${addonPage}`;
      if (ssoWindow) ssoWindow.location = newUrl;
      return;
    }
    ssoWindow?.close();
  };

  return (
    <Grid item xs={12}>
      <Card
        variant={'outlined'}
        sx={{ backgroundColor: 'info.light' }}
        data-testid="install-jetpack"
      >
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          alignItems={{ sm: 'start', md: 'center' }}
          justifyContent="space-between"
          mx={2}
          my={1}
        >
          <JetpackIcon alt="Jetpack logo" height={51} width={190} />
          <CardContent>
            <Stack spacing={1}>
              <Typography variant="h3">{t('title')}</Typography>
              <Typography variant="body2">{t('description')}</Typography>
            </Stack>
          </CardContent>
          <CardActions>
            <Button
              variant="outlined"
              onClick={() => redirectToWpAdmin('plugin-install.php')}
              disabled={isDisabled}
            >
              {t('install')}
            </Button>
          </CardActions>
        </Stack>
      </Card>
    </Grid>
  );
};

export default PurchaseJetpack;
