import { useHostingSitesV2 } from '@newfold/huapi-js';

import { removeDeletedSites } from '~/utils/remove-deleted-sites';

const useFilteredHostingSites = (hostingId, options) => {
  const { data, isLoading, isError, ...rest } = useHostingSitesV2(
    hostingId,
    options,
  );

  if (isLoading || isError) {
    return { ...rest, data, isLoading, isError };
  }

  const sites = data?.data?.items;
  const filteredSites = removeDeletedSites(sites);

  const sitesWithTypeWPForced = filteredSites.map((site) => ({
    ...site,
    name:
      site.name === null && site.migration_id ? 'Migrating Site' : site.name, // Use site.name if not null, otherwise use default value
    type: site.type || 'wordpress', // TODO: Once the backend returns the type this default wordpress type can be removed
  }));

  return {
    ...rest,
    isLoading,
    isError,
    data: {
      ...data,
      data: { ...data?.data, items: sitesWithTypeWPForced },
    },
  };
};

export default useFilteredHostingSites;
