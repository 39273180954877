export const blockInvalidNumberChar = (e) => {
  /**
   * Allowing: Integers | Backspace | Tab | Delete | Left, Right, Up, & Down arrow keys
   **/
  const regex = new RegExp(
    /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown)/,
  );

  return !e.key.match(regex) && e.preventDefault();
};
