import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface ServerStatusModalTypes {
  openServerStatusSuccessModal?: any;
  handleSuccessfullModalClose?: any;
  showRebootSuccessfullTxt?: boolean;
  showPowerOffSuccessfullTxt?: boolean;
  showServerOnSuccessfullTxt?: boolean;
  showResetPasswordSuccessTxt?: boolean;
}

const AdvancedHostingServerStatusModal = ({
  openServerStatusSuccessModal = false,
  handleSuccessfullModalClose = false,
  showRebootSuccessfullTxt = false,
  showPowerOffSuccessfullTxt = false,
  showServerOnSuccessfullTxt = false,
  showResetPasswordSuccessTxt = false,
}: PropsWithChildren<ServerStatusModalTypes>) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'advHostingServerStatusModal',
  });

  return (
    <Dialog open={openServerStatusSuccessModal} fullWidth maxWidth="sm">
      <DialogTitle>{t('successTxt')}</DialogTitle>
      <Divider variant="middle" />
      <DialogContent sx={{ p: 3 }}>
        <Box
          component="section"
          border="1px solid"
          borderColor="divider"
          borderRadius={1}
          p={5}
        >
          <Stack direction="column" spacing={0.5} alignItems="center">
            <CheckCircleOutlineRoundedIcon
              color="success"
              sx={{
                fontSize: 40,
              }}
            />
            {showServerOnSuccessfullTxt && (
              <Typography variant="h3">{t('serverisOnline')}</Typography>
            )}
            {showRebootSuccessfullTxt && (
              <Typography variant="h3">
                {t('serverRebootSuccessful')}
              </Typography>
            )}
            {showPowerOffSuccessfullTxt && (
              <Typography variant="h3">{t('serverIsOffline')}</Typography>
            )}
            {showResetPasswordSuccessTxt && (
              <Typography variant="h3">
                {t('passwordResetSuccessfull')}
              </Typography>
            )}
            <Stack direction="row" spacing={1}>
              {showServerOnSuccessfullTxt && (
                <Typography variant="body2">
                  {t('serverStartedsuccessfully')}
                </Typography>
              )}
              {showRebootSuccessfullTxt && (
                <Typography variant="body2">
                  {t('serverRebootedsuccessfully')}
                </Typography>
              )}

              {showPowerOffSuccessfullTxt && (
                <Typography variant="body2">
                  {t('serverPowerOffsuccessfully')}
                </Typography>
              )}
              {showResetPasswordSuccessTxt && (
                <Typography variant="body2">
                  {t('passwordresetsuccessfully')}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions sx={{ pt: 0, pr: 3, pb: 3, pl: 3 }}>
        <Button variant="contained" onClick={handleSuccessfullModalClose}>
          {t('closeBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AdvancedHostingServerStatusModal;
