import { PropsWithChildren } from 'react';

import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';

const linearProgressStyles = {
  borderRadius: 10,
  height: 8,
  width: '100%',
  maxWidth: 100,
  alignSelf: 'center',
};

export default function AllocationLinearProgress({
  used = undefined,
  total = undefined,
  unit = undefined,
  warningThreshold = 80,
  ...props
}: PropsWithChildren<{
  used?: number | null;
  total?: number | null;
  unit?: string;
  warningThreshold?: number;
}> &
  LinearProgressProps) {
  const usagePercentage =
    used && total && total > 0
      ? Math.min((Number(used) / Number(total)) * 100, 100)
      : 0;
  return (
    <LinearProgress
      sx={linearProgressStyles}
      variant={'determinate'}
      color={usagePercentage > warningThreshold ? 'error' : 'info'}
      value={usagePercentage}
      {...props}
    />
  );
}
