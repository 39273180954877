// import TabsContent from "./components/Tabs";
//@ts-nocheck
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Backup from '@mui/icons-material/Backup';
import Brush from '@mui/icons-material/Brush';
import Cached from '@mui/icons-material/Cached';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Power from '@mui/icons-material/Power';
import Sensors from '@mui/icons-material/Sensors';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

import {
  useSitesDomain,
  useSitesSecuritySslStatus,
  useSitesSso,
} from '@newfold/huapi-js';

import useAlerts from '~/components/Alerts/alertsStore';
import Copy from '~/components/Copy';
import DevMode from '~/components/DevMode';
import { useNewfoldBrandTheme } from '~/components/MFEProvider/lib/MUI/themeDefault';
import SsoLink from '~/components/SsoLink';
import useResponsive from '~/hooks/useResponsive';
import AllocationText from '~/scenes/Hosting/scenes/HostingDetail/scenes/Overview/Cloud/components/AllocationText';
import { PhpWorkersTooltip } from '~/scenes/Hosting/scenes/HostingDetail/scenes/Overview/Cloud/components/PhpWorkersTooltip';
import { useSiteContext } from '~/scenes/Site';
import StatusChip from '~/scenes/Site/scenes/Cloud/components/StatusChip';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';
import getSiteUrlWithoutProtocol from '~/utils/getSiteUrlWithoutProtocol';

import SiteThumbnail from '../../Overview/components/SiteThumbnail';

export default function Overview() {
  const { t } = useTranslation('site');
  const [, { generateAlert }] = useAlerts();
  const { isMobile } = useResponsive();
  const themeDark = useNewfoldBrandTheme('dark');

  const { siteId } = useParams();

  const hookOptions = {
    query: {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  };

  // GET: SSL status
  const {
    data,
    isError,
    isLoading: isSslStatusLoading,
  } = useSitesSecuritySslStatus(siteId, {
    query: { enabled: !!siteId },
  });

  const siteSSLStatus = data?.data?.ssl_status;

  const {
    data: site,
    isLoading,
    isHostingDisabled,
    isSiteOrHostingDisabled: isDisabled,
  } = useSiteContext();

  const siteStatus = site?.status;
  const storageUsed = site?.resources?.storage?.used;
  const storageTotal = site?.resources?.storage?.total;
  const storageUnit = site?.resources?.storage?.unit;
  const phpWorkers = site?.resources?.workers;
  const datacenter = site?.datacenter;
  const wordpressVersion = site?.version;
  const phpVersion = site?.php_version;
  const siteUrl = site?.url;
  const urlIsProvisional = site?.url_is_provisional;
  const pendingUrl = site?.pending_url_data?.domain;

  const { data: dnsStatus } = useSitesDomain(
    siteId,
    {},
    {
      query: {
        retry: 5,
        retryDelay: (attempt) => attempt * 15000, // add 15 seconds per retry
        enabled: !!siteId && !!pendingUrl,
        select: ({ data }) => ({
          aMatch: data?.a_match,
        }),
      },
    },
  );

  const aMatch = dnsStatus?.aMatch;

  const { refetch, params } = useSitesSso(siteId, '', hookOptions);
  const ssoLoadingPage = `${process.env.PUBLIC_URL}/loading/wordpress.html`;

  // Temp solution until malware scan huapi service available.
  const redirectToWpAdmin = async (addonPage) => {
    var ssoWindow = window.open(ssoLoadingPage, '_blank');
    const { data, error } = await refetch(params);
    if (error) {
      ssoWindow.close();
      return generateAlert({
        severity: 'error',
        description: t('error.alertError'),
        showCloseBtn: true,
      });
    }
    const url = data?.data?.sso;
    if (url) {
      // Parse the url and set the page
      let urlArray = url.split('/');
      let newUrl = `${urlArray[0]}//${urlArray[2]}/${urlArray[3]}/${addonPage}`;
      ssoWindow.location = newUrl;
      return;
    }
    ssoWindow.close();
  };

  const { palette } = useTheme();

  const white = palette?.common?.white;

  const siteUrlWithoutProtocol = getSiteUrlWithoutProtocol(siteUrl);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* Overview */}
        <ThemeProvider theme={themeDark}>
          <Card raised={false}>
            <CardContent sx={{ p: 3 }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <SiteThumbnail siteInfo={site} isLoadingSite={isLoading} />

                <Stack spacing={1} flex={1} justifyContent={'space-around'}>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                  >
                    <div>
                      <Stack spacing={1}>
                        <Typography variant="h2">
                          <b>{site?.name || <Skeleton width={120} />}</b>
                        </Typography>

                        {siteUrl && (
                          <Copy
                            copyValue={siteUrl}
                            icon={
                              <ContentCopy
                                sx={{
                                  color: white,
                                  fontSize: '1rem',
                                }}
                              />
                            }
                          >
                            <Link
                              variant="body1"
                              href={siteUrl}
                              color={white}
                              target="_blank"
                            >
                              {siteUrlWithoutProtocol}
                            </Link>
                          </Copy>
                        )}

                        {/* There is no custom URL, yet  */}
                        {!pendingUrl ? (
                          <>
                            {urlIsProvisional &&
                              (isLoading ? (
                                <Skeleton width={120} />
                              ) : (
                                <Typography variant="body2">
                                  <Link
                                    href={`./domains`}
                                    disabled={isDisabled}
                                    color={white}
                                  >
                                    {t('cloud.connectDomain')}
                                  </Link>
                                </Typography>
                              ))}
                          </>
                        ) : (
                          // Custom URL set
                          <>
                            {/* Custom URL matches */}
                            {aMatch ? (
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems={'center'}
                              >
                                <CircularProgress
                                  size={14}
                                  sx={{ color: white }}
                                />
                                <Typography variant="body2" color={white}>
                                  {t('urlCta.updateInProgress.text')}
                                </Typography>
                              </Stack>
                            ) : (
                              // Custom URL does not match
                              <Link
                                href={`domains/${pendingUrl}/dns`}
                                disabled={isDisabled}
                                variant="body2"
                                color={white}
                              >
                                {t('urlCta.pendingUrl.text')}
                              </Link>
                            )}
                          </>
                        )}
                      </Stack>
                      {/* end url section */}
                    </div>

                    <Stack spacing={1} direction={'row'}>
                      {!isError && !isSslStatusLoading && (
                        <StatusChip type="ssl" status={siteSSLStatus} />
                      )}
                      {!isLoading && <StatusChip status={siteStatus} />}
                    </Stack>
                  </Stack>

                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={1}
                    justifyContent={{ xs: undefined, md: 'space-between' }}
                  >
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <Typography variant="h4" component="span">
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <WordPressIcon color="white" />
                          <span>{t('cloud.wordPress')}</span>{' '}
                          <span>
                            <b>{wordpressVersion ? wordpressVersion : 'N/A'}</b>
                          </span>
                        </Stack>
                      </Typography>

                      <Typography variant="h4" component="span">
                        <Stack
                          direction={'row'}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <span>{t('cloud.php')}</span>{' '}
                          <span>
                            <b>{phpVersion ? phpVersion : 'N/A'} </b>
                          </span>
                        </Stack>
                      </Typography>
                    </Stack>

                    <Typography
                      variant="body2"
                      sx={{ alignSelf: { xs: undefined, md: 'flex-end' } }}
                      component="span"
                    >
                      <Stack
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                      >
                        <span>{t('cloud.dataCenter')}</span> <Sensors />{' '}
                        <span className="location">
                          <span>
                            <b>{datacenter ? datacenter : 'N/A'}</b>
                          </span>
                        </span>
                      </Stack>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </ThemeProvider>
      </Grid>

      {/* - Updates */}
      <Grid item xs={12} md={9}>
        <Card>
          <CardHeader
            title={t('cloud.updates')}
            subheader={
              false && (
                <Typography variant="body2">
                  <i>
                    {t('cloud.lastUpdate')}
                    <Skeleton
                      width={180}
                      sx={{ mx: 1, display: 'inline-block' }}
                    />
                    {/* Jan 04, 2024 11:49 AM CST */}
                  </i>{' '}
                  <Cached />
                </Typography>
              )
            }
          />
          <CardContent>
            <Card variant="outlined">
              <CardContent>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="center"
                  justifyContent={'space-evenly'}
                  divider={
                    <Divider
                      flexItem
                      orientation={isMobile() ? 'horizontal' : 'vertical'}
                    />
                  }
                  gap={{ xs: 3, sm: 1 }}
                >
                  {/* Not MVP, hide for now and show later in future when requirements are defined */}
                  {false && (
                    // added false because the DevMode was still causing an extra vertical divider to show
                    <DevMode envs={['development']}>
                      <Stack spacing={1} alignItems={'center'}>
                        <VerifiedUserIcon />

                        <Typography variant="h3">
                          {t('cloud.malwareScan')}
                        </Typography>
                        <Typography variant="body2">
                          <Link
                            disabled={isDisabled}
                            onClick={() =>
                              redirectToWpAdmin(
                                'admin.php?page=my-jetpack#/add-scan',
                              )
                            }
                            component="button"
                          >
                            {t('cloud.startScan')}
                          </Link>
                        </Typography>
                      </Stack>
                    </DevMode>
                  )}

                  <Stack spacing={1} alignItems={'center'}>
                    <Backup />
                    <Typography variant="h3">
                      {t('cloud.latestBackups')}
                    </Typography>
                    <Typography variant="body2">
                      <Link href={`./backups`} disabled={isDisabled}>
                        {t('cloud.viewBackups')}
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack spacing={1} alignItems={'center'}>
                    <Power />
                    <Typography variant="h3">{t('cloud.plugins')}</Typography>
                    <Typography variant="body2">
                      <SsoLink
                        type="site"
                        variant="textLink"
                        disabled={!siteId || isDisabled}
                        queryParams={{ app: 'plugins' }}
                      >
                        {t('cloud.pluginsAction')}
                      </SsoLink>
                    </Typography>
                  </Stack>

                  <Stack spacing={1} alignItems={'center'}>
                    <Brush />
                    <Typography variant="h3">{t('cloud.themes')}</Typography>
                    <Typography variant="body2">
                      <SsoLink
                        type="site"
                        variant="textLink"
                        disabled={!siteId || isDisabled}
                        queryParams={{ app: 'themes' }}
                      >
                        {t('cloud.viewThemes')}
                      </SsoLink>
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </Grid>

      {/* - Resources */}
      <Grid item xs={12} md={3}>
        <Stack spacing={2}>
          <Card sx={{ backgroundColor: '#E4ECF0' }} variant="outline">
            <CardActionArea href={`./performance`} disabled={isHostingDisabled}>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography variant="h4" noWrap>
                        {t('cloud.phpWorkers.title')}
                      </Typography>
                      <PhpWorkersTooltip
                        arrow
                        placement="top-start"
                        size="small"
                      />
                    </Stack>

                    {isLoading ? (
                      <Skeleton sx={{ display: 'inline-block' }} width={70} />
                    ) : (
                      <>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Typography variant="h1">
                            {phpWorkers ? phpWorkers : '-'}
                          </Typography>
                          <Typography variant="h5">
                            {t('cloud.phpWorkers.allocated')}
                          </Typography>
                        </Stack>
                      </>
                    )}
                  </Stack>
                  <ArrowForwardIosIcon
                    sx={{ cursor: 'pointer', alignContent: 'right' }}
                    data-testid="card-header"
                    color="action"
                  />
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card sx={{ backgroundColor: '#E4ECF0' }} variant="outline">
            <CardActionArea href={`./settings`} disabled={isHostingDisabled}>
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column" spacing={0.5}>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography variant="h4" noWrap>
                        {t('cloud.storageUsed')}
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={2} alignItems="center">
                      {storageUsed && (
                        <Typography variant="h1">
                          {Math.round((storageUsed / storageTotal) * 100)}%
                        </Typography>
                      )}
                      <AllocationText
                        sx={{
                          fontSize: '12px',
                        }}
                        isLoading={isLoading}
                        used={storageUsed}
                        total={storageTotal}
                        unit={storageUnit}
                      />
                    </Stack>
                  </Stack>
                  <ArrowForwardIosIcon
                    sx={{ cursor: 'pointer', alignContent: 'right' }}
                    color="action"
                  />
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}
