import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { createContext, PropsWithChildren, useContext } from 'react';

import { useAccountHostingList } from '@newfold/huapi-js';
import { AccountHostingList200 } from '@newfold/huapi-js/src/index.schemas';

import { useMFEContext } from '../MFEProvider';

const TenantContext = createContext<
  | (UseQueryResult<
      AxiosResponse<AccountHostingList200, any>,
      AxiosError<unknown, any>
    > & {
      isValidated: boolean;
      isProvisioning: boolean;
      isEmpty: boolean;
      isUnexpectedError: boolean;
    })
  | undefined
>(undefined);

export function useTenant() {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}

export default function TenantProvider({
  children = undefined,
}: PropsWithChildren<{}>) {
  const {
    // @ts-expect-error
    hostingStatus: { isProvisioning },
  } = useMFEContext();

  const maxFailureAttempts = 400;
  const retryDelay = 3000;

  const tenant = useAccountHostingList({
    query: {
      // wait for axios baseURL to be set before calling
      // enabled: !!axios.defaults.baseURL,
      /*
      if AMM provides a "provisioning" hosting status and we get 403 responses
        keep retrying util we get a 200 response or after 20 minutes show an error message
      if AMM provides an "active" hosting status and we get 403
        stop retrying and show an error message
      any type of other error status (400, 512, etc...) will stop retrying and show an error message
      */
      retryDelay,
      retry: (failureCount, error) =>
        // retry if
        failureCount < maxFailureAttempts &&
        // retry if 404
        (error?.response?.status === 404 ||
          // retry if provisioning & 403
          (error?.response?.status === 403 && isProvisioning)),
    },
  });

  const isValidated = Boolean(
    tenant.data?.data?.accounts && tenant.data?.data?.accounts?.length > 0,
  );

  const isEmpty = Boolean(
    tenant.data?.data?.accounts && tenant.data?.data?.accounts?.length < 1,
  );

  const isUnexpectedError =
    !tenant?.isInitialLoading &&
    (tenant.isError ||
      !tenant.data ||
      tenant?.failureCount > maxFailureAttempts);

  const isProvisioningExtended =
    isProvisioning &&
    (tenant?.failureReason?.response?.status === 403 ||
      tenant?.failureReason?.response?.status === 404) &&
    tenant?.failureCount <= maxFailureAttempts;

  return (
    <TenantContext.Provider
      value={{
        ...tenant,
        isEmpty,
        isValidated,
        isProvisioning: isProvisioningExtended,
        isUnexpectedError,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
}
