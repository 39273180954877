import { useState } from 'react';

import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { AccountHostingList200AccountsItem } from '@newfold/huapi-js/src/index.schemas';

type HostingOptions = {
  hosting: AccountHostingList200AccountsItem;
};

export default function CardMenu({ hosting }: HostingOptions) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id={`hosting-menu-button-${hosting?.hosting_id}`}
        aria-controls={open ? `hosting-menu-${hosting?.hosting_id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertRounded />
      </IconButton>

      <Menu
        id={`hosting-menu-${hosting?.hosting_id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `hosting-menu-button-${hosting?.hosting_id}`,
        }}
      >
        <MenuItem
          component={Link}
          href={hosting?.hosting_id}
          onClick={handleClose}
        >
          Manage
        </MenuItem>
      </Menu>
    </>
  );
}
