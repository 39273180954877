import { useSitesListV2 } from '@newfold/huapi-js';

import { removeDeletedSites } from '~/utils/remove-deleted-sites';

const useFilteredSitesV2 = (args) => {
  const { data, isLoading, isError, ...rest } = useSitesListV2(args);

  if (isLoading || isError) {
    return { ...rest, data, isLoading, isError };
  }

  const sites = data?.data?.items;
  const filteredSites = removeDeletedSites(sites);

  return {
    ...rest,
    data: {
      ...data,
      data: { ...data?.data, items: filteredSites },
    },
  };
};

export default useFilteredSitesV2;
