import getCTBEnv from './getCTBEnv';

// Include a ClickToBuy script, based on the JWT env, providing functionality for all product CTBs
const includeCTB = (accessToken) => {
  // escape if the script is already in the dom
  if (document.getElementById('ctb-script-include')) return null;

  const node = document.createElement('script');
  node.id = 'ctb-script-include';
  node.type = 'text/javascript';
  node.defer = true;

  const { src } = getCTBEnv(accessToken);
  node.src = src;

  document.getElementsByTagName('body')[0].appendChild(node);
};

export const removeCTB = () => {
  document.getElementById('ctb-script-include')?.remove();
};

export default includeCTB;
