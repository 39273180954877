import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

// https://mui.com/material-ui/guides/routing/#global-theme-link
const LinkBehavior = forwardRef<any, LinkProps & { href: string }>(
  (props, ref) => {
    const { children, target, href, to, ...other } = props;
    // Enable links with an href prop to use the React Router `to` prop
    // Map href (MUI) -> to (react-router)
    // Need to allow for 'external' links - we'll look for 'http' and if truthy, return a TRUE href; otherwise will return `to`
    return target === '_blank' ? (
      <a
        href={(to as string) || (href as string)}
        target="_blank"
        rel="noopener noreferrer"
        {...other}
      >
        {children}
      </a>
    ) : (
      <Link ref={ref} to={to || href} {...other}>
        {children}
      </Link>
    );
  },
);

export default LinkBehavior;
