import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useSitesSshUserDelete, useSitesSshUserList } from '@newfold/huapi-js';

import useAlerts from '~/components/Alerts/alertsStore';

const RemoveUser = ({ id }) => {
  const { t } = useTranslation('sshUserManager');
  const siteId = id;
  const location = useLocation();
  const { userName } = location.state;
  const navigate = useNavigate();
  const [, { generateAlert }] = useAlerts();

  const queryClient = useQueryClient();
  const queryKey = useSitesSshUserList(siteId);
  const { mutate: handleSshDelete, isLoading: isDeleteLoading } =
    useSitesSshUserDelete({
      mutation: {
        onSuccess: async ({ data }) => {
          await queryClient.cancelQueries(queryKey);
          const previousKeysData = queryClient.getQueryData(queryKey);

          const removeKey = previousKeysData.data.users.filter(
            (item) => item !== userName,
          );

          queryClient.setQueryData(queryKey, (oldKeysData) => {
            return {
              ...oldKeysData,
              data: { ...oldKeysData.data, rows: [...removeKey] },
            };
          });
          return { previousKeysData };
        },
        onError: (error, _key, context) => {
          queryClient.setQueryData(queryKey, context.previousKeysData);
          generateAlert({
            severity: 'error',
            description:
              error?.response?.data?.error === 'sshKeyExists'
                ? t('errorExistingKeyNameToast')
                : t('removeSSHUSER.removeUserError'),
            showCloseBtn: true,
            persist: false,
          });
          navigate('..');
        },
        onSettled: (data) => {
          if (data?.status === 200) {
            generateAlert({
              severity: 'success',
              description: t('removeSSHUSER.success'),
              showCloseBtn: true,
              persist: false,
            });
            navigate('..');
          }
          queryClient.invalidateQueries(queryKey);
        },
      },
    });

  const handleDelete = () => {
    handleSshDelete({
      siteId,
      data: {
        ssh_user: userName,
      },
    });
  };

  return (
    <Dialog open onClose={() => navigate('..')}>
      <DialogTitle>{t('removeSSHUSER.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('removeSSHUSER.desc')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button href=".." sx={{ mr: 1 }}>
          {t('removeSSHUSER.cancelTxt')}
        </Button>
        <LoadingButton
          loading={isDeleteLoading}
          variant="contained"
          onClick={handleDelete}
        >
          {t('removeSSHUSER.removeUser')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUser;
