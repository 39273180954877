import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';

import { getHostingDisabled } from '~/utils/getHostingDisabled';
import { getSiteDisabled } from '~/utils/getSiteDisabled';
import { getIsPendingAction } from '~/utils/siteMeta';

import LogInButton from './LogInButton';
import Menu from './Menu';
import PrimaryAction from './SiteCardPrimaryAction';
import SiteThumbnail from './SiteThumbnail';
import SiteTitle from './SiteTitle';

const propTypes = {
  list: arrayOf(
    shape({
      id: oneOfType([number, string]),
      name: string,
      type: string,
      url: string,
      pending_url: string,
      doc_root: string,
      ssl: bool,
      detected_type: string,
    }),
  ),
};

const SitesCards = ({ list = undefined }) => {
  const theme = useTheme();
  return (
    <Stack display="block">
      <Grid container spacing={2}>
        {list?.map((site) => {
          const { id, status, type, detected_type, hosting } = site;
          const isPendingAction = getIsPendingAction(site);
          const siteDisabled = getSiteDisabled(status);
          const isDisabled = getHostingDisabled(site?.hosting?.status);

          return (
            <Grid
              key={`SitesCard__${id}`}
              item
              xs={12}
              md={6}
              lg={4}
              minWidth={{ md: 376 }}
            >
              <Card
                data-testid={`site-${id}`}
                variant={isPendingAction || isDisabled ? 'outlined' : undefined}
                sx={{
                  backgroundColor:
                    isPendingAction || isDisabled
                      ? theme.palette.grey[100]
                      : undefined,
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  <Stack spacing={2}>
                    <SiteThumbnail siteInfo={site} />
                    <Grid container direction="row">
                      <Grid item xs={10}>
                        {/* title */}
                        <SiteTitle disabled={isDisabled} {...site} />
                      </Grid>
                      <Grid item xs={2}>
                        {/* extra actions menu */}
                        <Menu
                          disabled={isDisabled || siteDisabled}
                          alignItems="right"
                          {...site}
                          sx={{ flex: 1 }}
                          hostingId={hosting?.hosting_id}
                          id={id}
                          type={type}
                          status={status}
                          detected_type={detected_type}
                          viewActivated="grid"
                        />
                      </Grid>
                    </Grid>
                    <Stack
                      direction={'row'}
                      spacing={2}
                      justifyContent={'space-between'}
                      sx={{ maxHeight: 40 }}
                    >
                      <PrimaryAction
                        sx={{ flex: 1 }}
                        status={status}
                        type={type}
                        id={id}
                        migration_id={site?.migration_id}
                        disabled={isDisabled}
                      />
                      <LogInButton
                        disabled={isDisabled || siteDisabled}
                        sx={{ flex: 1 }}
                        id={id}
                        type={type}
                        status={status}
                        detected_type={detected_type}
                        viewActivated="grid"
                        hostingId={hosting?.hosting_id}
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

SitesCards.propTypes = propTypes;

export default SitesCards;
