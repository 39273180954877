import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSitesInfo } from '@newfold/huapi-js';

import Copy from '~/components/Copy';
import HighlightedBox from '~/components/HighlightedBox';

const DocRoot = () => {
  const { siteId } = useParams();
  const { t } = useTranslation('site', { keyPrefix: `docRoot` });

  const { data: siteInfo, isInitialLoading: isLoadingSiteInfo } =
    useSitesInfo(siteId);

  const docRootLink = siteInfo?.data?.docroot;
  const docRootPath = siteInfo?.data?.path;

  const fullDocRoot = docRootPath
    ? `~/${docRootLink}/${docRootPath}`
    : `~/${docRootLink}`;

  if (isLoadingSiteInfo) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={185} height={24} />
        <Skeleton width={64} height={24} />
      </Stack>
    );
  }

  if (docRootLink === null)
    return (
      <Stack mt={0.5}>
        <HighlightedBox testId="docroot-unavailable" bgColor="warning">
          <Typography sx={{ fontFamily: 'courier' }}>{t('error')}</Typography>
        </HighlightedBox>
      </Stack>
    );

  return (
    <Stack mt={0.5}>
      <HighlightedBox testId="docroot-component">
        <Copy>
          <Typography
            sx={{ wordBreak: 'break-all', fontFamily: 'courier' }}
            variant="body1"
          >
            {fullDocRoot}
          </Typography>
        </Copy>
      </HighlightedBox>
    </Stack>
  );
};

export default DocRoot;
