import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { OverridableStringUnion } from '@mui/types';

type DiskValue = {
  percentUsed: number;
  variant: OverridableStringUnion<'success' | 'error' | 'warning' | 'primary'>;
};

const AdvancedHostingUsageView = ({
  isFetching = false,
  value = undefined,
  disklimit = undefined,
  diskused = undefined,
}: PropsWithChildren<{
  isFetching?: boolean;
  value?: DiskValue;
  disklimit?: string | undefined;
  diskused?: string | undefined;
}> &
  TypographyProps) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'usageStatsCard',
    useSuspense: false,
  });

  return (
    <>
      {isFetching ? (
        <Stack direction="row" spacing={1}>
          <Skeleton width="80%" />
        </Stack>
      ) : (
        <Stack spacing={1}>
          <Box>
            {diskused && disklimit ? (
              <>
                <Typography variant="h2" component={'span'}>
                  {diskused}{' '}
                </Typography>
                <Typography variant="body1" component={'span'}>
                  {t('advHostingDiskUsageTxt')} {disklimit}
                </Typography>
              </>
            ) : (
              <Typography variant="body2">{t('unableToLoadMsg')}</Typography>
            )}
          </Box>
          <LinearProgress
            variant="determinate"
            value={value?.percentUsed || 0}
            color={value?.variant}
          />
        </Stack>
      )}
    </>
  );
};

export default AdvancedHostingUsageView;
