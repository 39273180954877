import { InitFunctionOptions } from '~/bootstrap';
import { HostingStatus } from '~/types';

import proServices from './pro-services';

const apiBaseUrl = process.env.REACT_APP_HUAPI_BASE_URL;
const accessToken =
  (process.env.REACT_APP_QA_JWT && !process.env.REACT_APP_MOCK
    ? process.env.REACT_APP_QA_JWT
    : process.env.REACT_APP_HUAPI_JWT) || 'missing.jwt.xx';

const hostingId = process.env.REACT_APP_HOSTING_ID;
const selector = process.env.REACT_APP_ROOT_ID || 'hosting-app';

const hostAppProps: InitFunctionOptions = {
  selector,
  basename: process.env.PUBLIC_URL,
  callbacks: {
    refreshAccessToken: async () => accessToken,
  },

  data: {
    apiBaseUrl,
    accessToken,
    hostingId,
    proServices,
    renewalCenterLink: 'http://localhost:4200/renewal-center',
    marketPlaceLink: 'http://localhost:4200/market-place',
    addHostingAccountUrl: 'https://www.bluehost.com/wordpress/hosting',

    // mock from AMM demo
    hostingData: {
      expiryDate: '08/08/2030',
      accountId: 41589749,
      productCode: 'BH _PKG WP ENT',
      productInstanceId: process.env.REACT_APP_HOSTING_ID || 'WN.HP.320926252',
      // productInstanceId: 'WN.HP.321459597', // CTB tester
      productName: 'WordPress Basic (Hosting)',
      hasAutoRenew: false,
      status: HostingStatus.ACTIVE,
    },
  },
};

export default hostAppProps;
