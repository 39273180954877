import { string } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';

import FixSiteModal from '~/components/FixSiteModal';
import SsoLink from '~/components/SsoLink';
import useResponsive from '~/hooks/useResponsive';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';
import { getCanShowFixSiteModal } from '~/utils/siteMeta';

import BuilderSSO from './BuilderSSO';

const propTypes = {
  type: string,
  detected_type: string,
  status: string,
};

const SsoButtonGroup = ({
  type = undefined,
  detected_type = undefined,
  status = undefined,
}) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation('site');
  const { siteId } = useParams();

  const [openFixSiteModal, setOpenFixSiteModal] = useState(false);

  const isWordPress = type === 'wordpress';
  const isBuilder = type === 'builder' || detected_type === 'builder';

  const ssoButtonText =
    isMobile() && isWordPress
      ? t('ssoButton.mobileLogin')
      : t(`ssoButton.wordpress`);

  if (isBuilder) {
    return <BuilderSSO />;
  }

  if (getCanShowFixSiteModal({ status, type, detected_type })) {
    return (
      <>
        <Button
          data-testid={`fix-site-cta-${siteId}`}
          variant="contained"
          color="primary"
          onClick={() => setOpenFixSiteModal(true)}
        >
          <WordPressIcon color="inherit" sx={{ mr: 1 }} />
          {t('ssoButton.wordpress')}
        </Button>
        <FixSiteModal
          siteId={siteId}
          open={openFixSiteModal}
          setOpen={setOpenFixSiteModal}
        />
      </>
    );
  }

  return (
    <SsoLink data-testid={`ssolink-wordpress`} variant="contained">
      <>
        {isWordPress && <WordPressIcon color="inherit" sx={{ mr: 1 }} />}
        {ssoButtonText}
      </>
    </SsoLink>
  );
};

SsoButtonGroup.propTypes = propTypes;

export default SsoButtonGroup;
