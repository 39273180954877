import { func, node, object, oneOfType, string } from 'prop-types';

import InfoIcon from '@mui/icons-material/Info';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const propTypes = {
  title: string,
  children: node,
  avatarIcon: oneOfType([object, node]),
  avatarIconColor: string,
  textColor: string,
  tooltipText: string,
  ctaText: string,
  handleCTA: func,
  inlineCTA: oneOfType([func, node]),
};

const InfoListItem = ({
  title = null,
  children = null,
  avatarIcon = null,
  avatarIcon: AvatarIcon = null,
  avatarIconColor = null,
  textColor = undefined,
  tooltipText = null,
  ctaText = '',
  handleCTA = null,
  inlineCTA = null,
}) => {
  return (
    <ListItem
      disableGutters
      dense
      alignItems={handleCTA ? 'flex-start' : 'center'}
    >
      {avatarIcon && (
        <ListItemAvatar>
          <Avatar>
            <AvatarIcon {...(avatarIconColor && { color: avatarIconColor })} />
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" color={textColor}>
              {title}
            </Typography>
            {tooltipText && (
              <Tooltip title={tooltipText} arrow placement="top-start">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            {inlineCTA}
          </Stack>
        }
        {...(children && {
          secondary: (
            <Typography variant="body1" component="span">
              {children}
            </Typography>
          ),
        })}
      />
    </ListItem>
  );
};

InfoListItem.propTypes = propTypes;
export default InfoListItem;
