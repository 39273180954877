import { array, arrayOf, bool, node, number, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const propTypes = {
  type: string,
  list: array,
  isLoading: bool,
  loader: node,
  isError: bool,
  loaderCount: arrayOf(number),
  emptyListCopy: string,
  empty: node,
  errorCopy: string,
  error: node,
  children: node,
};

const VisionUICardList = ({
  type = 'list',
  list = [],
  isLoading = false,
  loader = undefined,
  loaderCount = [1, 2, 3],
  isError = false,
  errorCopy = undefined,
  error = undefined,
  emptyListCopy = undefined,
  empty = undefined,
  children = undefined,
}) => {
  const { t } = useTranslation('lists');

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { list });
    }
    return child;
  });

  const aspectX = 16;
  const aspectY = 9;

  const thumbnailSkeletonSx = {
    aspectRatio: `${aspectX}/${aspectY}`,
    width: '100%',
  };

  const isEmpty = list?.length === 0;

  const getCardList = () => {
    if (isLoading && loader) return loader;
    if (isLoading) {
      return (
        <Stack display="block">
          <Grid spacing={2} container>
            {loaderCount.map((key) => (
              <Grid item xs={12} md={6} lg={4} key={key}>
                <Card data-testid={`card-loader-${key}`}>
                  <CardContent sx={{ p: 2 }}>
                    <Stack spacing={2}>
                      <Skeleton
                        variant="rectangular"
                        height={'100%'}
                        sx={thumbnailSkeletonSx}
                      />
                      <Grid container direction="row">
                        <Grid item xs={10}>
                          <Skeleton variant="text" width={120} height={16} />
                          <Skeleton variant="text" width={180} height={16} />
                        </Grid>
                        <Grid item xs={2}>
                          <Button id="basic-button" disabled>
                            <MoreVertRounded />
                          </Button>
                        </Grid>
                      </Grid>
                      <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'space-between'}
                        sx={{ maxHeight: 40 }}
                      >
                        <Skeleton
                          variant="rounded"
                          width={'100%'}
                          height={36}
                        />
                        <Skeleton
                          variant="rounded"
                          width={'100%'}
                          height={36}
                        />
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>
      );
    }

    if (isError && error) return error;
    if (isError) {
      return (
        <Card>
          <CardContent>{errorCopy || t('listError', { type })}</CardContent>
        </Card>
      );
    }

    if (isEmpty && empty) return empty;
    if (isEmpty) {
      return (
        <Card>
          <CardContent>
            {emptyListCopy || t('listNotAvailable', { type })}
          </CardContent>
        </Card>
      );
    }

    return <>{childrenWithProps}</>;
  };

  return getCardList();
};

VisionUICardList.propTypes = propTypes;

export default VisionUICardList;
