import moment from 'moment';
import { useState } from 'react';

const defaultTimes = {
  0: 10000,
  1: 10000,
  2: 15000,
  5: 30000,
  10: 60000,
  15: 120000,
  30: 300000,
};

const useRefetchInterval = (times = defaultTimes) => {
  const [timerStart] = useState(moment());
  const timesKeys = Object.keys(times);
  const key = moment().diff(timerStart, 'minutes');
  const activeTimer = Math.max(
    ...timesKeys.map((r) => Number(r)).filter((i) => i <= key),
  );
  return {
    refetchTime: times[activeTimer] || false,
  };
};

export default useRefetchInterval;
