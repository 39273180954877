import { useTranslation } from 'react-i18next';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface Props {
  openPowerOffServerModal: boolean;
  handleServerStatus: () => void;
  handleClose: () => void;
}

const AdvancedHostingPowerOffServerModal: React.FC<Props> = ({
  openPowerOffServerModal,
  handleServerStatus,
  handleClose,
}) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'advHostingPowerOffModal',
  });

  return (
    <Dialog
      open={openPowerOffServerModal}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Stack spacing={3}>
          <Typography>{t('desc')}</Typography>
          <Stack direction="row" spacing={1}>
            <WarningIcon color="error" />
            <Typography>{t('alertMessage')}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          {t('cancelBtn')}
        </Button>
        <Button variant="contained" onClick={handleServerStatus}>
          {t('Btntxt')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdvancedHostingPowerOffServerModal;
