import { number, oneOfType, string } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const propTypes = {
  siteId: oneOfType([string, number]).isRequired,
};

const DocrootMismatchAlert = ({ siteId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('site');

  return (
    <Box
      sx={{
        marginBottom: 3,
      }}
    >
      <Alert
        data-testid="alert-banner-docroot-mismatch"
        severity="error"
        {...{
          action: (
            <Button
              onClick={() => {
                navigate(`/sites/${siteId}/change-domain`);
              }}
            >
              {t('docrootMismatchAlert.connectDomainBtnTxt')}
            </Button>
          ),
        }}
      >
        <AlertTitle>{t('docrootMismatchAlert.title')}</AlertTitle>
        <Typography>
          <Trans
            i18nKey="site:docrootMismatchAlert.description"
            values={{}}
            components={{
              br: <br />,
              Link: <Link href={`/sites/${siteId}/settings#delete`} />,
            }}
          />
        </Typography>
      </Alert>
    </Box>
  );
};
DocrootMismatchAlert.propTypes = propTypes;

export default DocrootMismatchAlert;
