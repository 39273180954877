const passwordPatt =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const Validation = (value) => {
  return passwordPatt.test(value);
};

export const onValidUsername = (val) => {
  const pat = /^[a-zA-Z0-9]+$/;
  return pat.test(val);
};

export const onValidUpperCase = (value) => {
  const hasUppercase = /[A-Z]/;
  if (hasUppercase.test(value)) {
    return {
      isUpperCaseValid: hasUppercase.test(value),
    };
  }
  return {
    isUpperCaseValid: false,
  };
};

export const onValidSpecialCase = (value) => {
  const hasSpecialCase = /[!@#$%^&*()]/;

  if (hasSpecialCase.test(value)) {
    return {
      isSpecialCaseValid: hasSpecialCase.test(value),
    };
  }
  return {
    isSpecialCaseValid: false,
  };
};

export const onValidNumberCase = (value) => {
  const hasNumberCase = /[0-9]/;
  if (hasNumberCase.test(value)) {
    return {
      isNumberCaseValid: hasNumberCase.test(value),
    };
  }
  return {
    isNumberCaseValid: false,
  };
};

export const onValidLengthCase = (value) => {
  const hasTextLenghtCase = /^.{8,}$/;

  if (hasTextLenghtCase.test(value)) {
    return {
      isTextLengthCaseValid: hasTextLenghtCase.test(value),
    };
  }

  return {
    isTextLengthCaseValid: false,
  };
};
