import { useQueryClient } from '@tanstack/react-query';
import { any, bool, number, oneOfType, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import AutorenewIcon from '@mui/icons-material/Autorenew';

import {
  getSitesAutoupdatesInfoQueryKey,
  useSitesAutoupdatesUpdate,
} from '@newfold/huapi-js';

import useAlerts from '~/components/Alerts/alertsStore';
import { useMFEContext } from '~/components/MFEProvider';
import StatusListItem from '~/components/StatusListItem';

const propTypes = {
  siteId: oneOfType([number, string]).isRequired,
  type: string,
  typeLabel: string,
  wpDataAU: any,
  isLoading: bool,
};

const AutoUpdateType = ({
  siteId = null,
  type = '',
  typeLabel = '',
  wpDataAU = null,
  isLoading = false,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('site');
  const [, { generateAlert }] = useAlerts();
  const {
    data: { hostingData },
  } = useMFEContext();
  const queryKey = getSitesAutoupdatesInfoQueryKey(siteId);
  const updateList = {
    core: 'core',
    plugins: 'plugins',
    themes: 'themes',
  };

  const { mutate, isLoading: isUpdating } = useSitesAutoupdatesUpdate({
    mutation: {
      onMutate: async () => {
        await queryClient.cancelQueries(queryKey);
        const previousSitesAutoupdatesUpdateData =
          queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, () => {
          return {
            ...previousSitesAutoupdatesUpdateData,
            data: {
              ...previousSitesAutoupdatesUpdateData?.data,
              ...{
                [updateList[type]]:
                  !previousSitesAutoupdatesUpdateData?.data[type],
              },
            },
          };
        });
      },
      onSuccess: (data) => {
        generateAlert({
          severity: 'success',
          description: t('updateStatus.autoUpdate.success', {
            item: typeLabel,
          }),
          showCloseBtn: true,
        });
      },
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('updateStatus.autoUpdate.error', {
            item: typeLabel,
          }),
          showCloseBtn: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    },
  });

  const turnOnAutoUpdate = () => {
    mutate({
      siteId,
      data: {
        type: type,
        enabled: !!siteId,
      },
    });
  };

  const hostingNotActive = hostingData?.status.toLowerCase() !== 'active';
  const autoUpdateEnabled = wpDataAU && wpDataAU[type];

  const formatStatusMsg = (status) => {
    const text = status ? 'on' : 'off';
    return t(`updateStatus.autoUpdate.statusText`, { status: text });
  };

  return (
    <StatusListItem
      avatarIcon={AutorenewIcon}
      avatarIconColor={autoUpdateEnabled ? 'success' : 'error'}
      ctaText={t('turnOnBtn')}
      {...(!autoUpdateEnabled && { handleCTA: turnOnAutoUpdate })}
      disableCTA={hostingNotActive}
      isLoading={isLoading}
      ctaIsLoading={isUpdating}
      dataTestId={`wp-${type}-AU`}
    >
      {`${typeLabel} ${formatStatusMsg(autoUpdateEnabled)}`}
    </StatusListItem>
  );
};

AutoUpdateType.propTypes = propTypes;
export default AutoUpdateType;
