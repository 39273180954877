import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const CTAComponent = ({
  handleCTA,
  ctaIsLoading,
  updateInProgress,
  disableCTA,
  ctaText,
  dataTestId,
}) => {
  if (ctaIsLoading) return <CircularProgress size={16} />;

  if (updateInProgress)
    return (
      <Stack marginTop={1} direction="row" spacing={1}>
        <CircularProgress size={18} />
        <Typography variant="body2">{ctaText}</Typography>
      </Stack>
    );

  if (!handleCTA) return null;

  return (
    <Link
      component="button"
      onClick={handleCTA}
      disabled={disableCTA}
      data-testid={`${dataTestId}-secondary-link`}
    >
      {ctaText}
    </Link>
  );
};

export default CTAComponent;
