const USAGE_ERR_PERCENTAGE = 90;
const USAGE_WARN_PERCENTAGE = 70;
const MAX_FILE_LIMIT = 1000000;

const getUsageStats = (data) => {
  let percentUsed = 0,
    filePercentUsed = 0;
  let variant = 'primary',
    fileVariant = 'primary';
  let hasIcon = false,
    hasFileIcon = false;
  if (data?.disklimit !== 'Unlimited') {
    percentUsed =
      Number(data?.diskused.slice(0, -2) / data?.disklimit.slice(0, -2)) * 100;
    variant = 'primary';
    switch (true) {
      case percentUsed >= USAGE_ERR_PERCENTAGE:
        variant = 'error';
        hasIcon = true;
        break;
      case percentUsed < USAGE_ERR_PERCENTAGE &&
        percentUsed >= USAGE_WARN_PERCENTAGE:
        variant = 'warning';
        break;
      default:
        variant = 'success';
    }
  }

  if (data?.fileslimit !== MAX_FILE_LIMIT) {
    filePercentUsed = Number(data?.filesused / data?.fileslimit) * 100;
    fileVariant = 'primary';
    switch (true) {
      case filePercentUsed >= USAGE_ERR_PERCENTAGE:
        fileVariant = 'error';
        hasFileIcon = true;
        break;
      case filePercentUsed < 90 && filePercentUsed >= USAGE_WARN_PERCENTAGE:
        fileVariant = 'warning';
        break;
      default:
        fileVariant = 'success';
    }
  }

  const stats = {
    percentUsed,
    variant,
    filePercentUsed,
    fileVariant,
    hasIcon,
    hasFileIcon,
  };

  return stats;
};

export { getUsageStats };
