import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type PHPModalProps = {
  siteId: number;
  siteUrl: string;
  openPHPModal: boolean;
  setOpenPHPModal: Function;
  currentPHP: string;
  phpList: string[] | undefined;
  updatePHP: Function;
  isLoading: boolean;
  phpListLoading: boolean;
  latestVersion: string;
  isDeprecatedPhpVersion: boolean;
};

const PHPModal = ({
  siteId,
  siteUrl,
  openPHPModal,
  setOpenPHPModal,
  currentPHP,
  phpList,
  updatePHP,
  isLoading,
  phpListLoading,
  latestVersion,
  isDeprecatedPhpVersion,
}: PHPModalProps) => {
  const { t } = useTranslation('site');

  const defaultVersion = isDeprecatedPhpVersion ? latestVersion : currentPHP;

  const [selectedVersion, setSelectedVersion] = useState(defaultVersion);
  const [currentVersion, setCurrentVersion] = useState(currentPHP);
  const [showWarning, setShowWarning] = useState(false);
  const [nestedSites, setNestedSites] = useState([]);

  const handleChange = (event: any) => {
    setSelectedVersion(event.target.value);
  };

  const handleClose = (reason: any) => {
    if (reason && reason === 'backdropClick') return;
    setNestedSites([]);
    setOpenPHPModal(false);
    setShowWarning(false);
    setSelectedVersion(currentVersion);
  };

  const updatePhpVersion = () =>
    updatePHP(
      {
        siteId: siteId,
        data: {
          php_version: selectedVersion,
          force: !showWarning ? false : true,
        },
      },
      {
        onSuccess: ({ data }: any) => {
          const nestedSites = data?.nested_sites;
          if (nestedSites && nestedSites.length > 0) {
            const parentSite = data?.site;
            if (parentSite !== siteUrl) {
              nestedSites.push(parentSite);
            }
            const filteredSites = nestedSites.filter(
              (site: string) => site !== siteUrl,
            );
            setNestedSites(filteredSites);
            setShowWarning(true);
            return;
          }
          if (data?.success) {
            setCurrentVersion(selectedVersion);
            setNestedSites([]);
            setOpenPHPModal(false);
            setShowWarning(false);
            return;
          }
        },
      },
    );

  const SubSitesList = () => {
    return (
      <List dense={true} sx={{ ml: 5, wordBreak: 'break-word' }}>
        {nestedSites.map((site, index) => (
          <ListItem key={index} component="div" disablePadding>
            <ListItemText
              secondary={<Typography variant="body1">{site}</Typography>}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Dialog open={openPHPModal} data-testid="php-modal">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {showWarning && (
          <ArrowBackIosIcon
            sx={{ cursor: 'pointer', color: 'info.main', ml: 3 }}
            onClick={() => setShowWarning(false)}
          />
        )}
        <DialogTitle fontSize="20px" sx={{ pl: showWarning ? 0 : 3 }}>
          {t('phpVersion.modal.title')}
        </DialogTitle>
      </Box>
      <Divider />
      <DialogContent>
        {!showWarning ? (
          <>
            <Stack direction="row" spacing={2} pb={2}>
              <WarningIcon color="error" />
              <Typography>
                <Trans
                  i18nKey={t('site:phpVersion.modal.desc')}
                  components={{
                    Backup: <Link href="../backups" />,
                  }}
                />
              </Typography>
            </Stack>

            <Typography variant="body1">
              {t('phpVersion.modal.subTitle')}
            </Typography>

            <FormControl fullWidth>
              <Select
                value={selectedVersion}
                labelId="phpversions-dropdown-label"
                onChange={handleChange}
                data-testid="php-list"
                id="phpversions-dropdown"
              >
                {phpListLoading ? (
                  <CircularProgress
                    sx={{ ml: 1, mt: 2, alignSelf: 'center' }}
                    data-testid="php-loader"
                  />
                ) : (
                  phpList?.map((php, index) => (
                    <MenuItem key={index} value={php}>
                      <Stack direction="row" spacing={1}>
                        <ListItemIcon>
                          {php === selectedVersion && <CheckIcon />}
                        </ListItemIcon>
                        <Stack direction="row" spacing={2}>
                          <Typography variant="body1">{php}</Typography>
                          {php === latestVersion && (
                            <Chip
                              label={t('phpVersion.modal.tagVersionTxt')}
                              size="small"
                              color="secondary"
                              variant="outlined"
                            />
                          )}
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="flex-start"
              data-testid="warning-msg"
            >
              <WarningIcon color="error" sx={{ mr: 2 }} />
              <Typography>
                <Trans
                  i18nKey="site:phpVersion.modal.warningMsg"
                  components={{
                    b: (
                      <Box
                        component="span"
                        m="{1}"
                        sx={{ fontWeight: 700, overflowWrap: 'anywhere' }}
                      />
                    ),
                  }}
                  values={{
                    siteUrl: siteUrl,
                  }}
                />
              </Typography>
            </Box>

            <SubSitesList />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          {t('phpVersion.modal.cancelBtn')}
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={isLoading || currentVersion === selectedVersion}
          onClick={updatePhpVersion}
          data-testid="change-php"
        >
          {showWarning
            ? t('phpVersion.modal.proceedBtn')
            : t('phpVersion.modal.applyBtn')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PHPModal;
