import { extractJWTAud, getIsUserEnvProd } from '~/utils/extractJWTInfo';

export default function getCTBEnv(accessToken) {
  const isUserEnvProd = getIsUserEnvProd(accessToken);
  const aud = extractJWTAud(accessToken);

  const environments = {
    LOCAL: {
      env: 'LOCAL',
      // src: 'http://localhost:63002/ctb/click-to-buy.bundle.js', // LOCAL use this to get debug output
      src: 'https://qa.mfe.svcs.newfold.com/ctb/click-to-buy.bundle.js', // QA
    },
    // jwt.aud === 'staging'
    staging: {
      env: 'STAGE',
      src: 'https://stage.mfe.svcs.newfold.com/ctb/click-to-buy.bundle.js',
    },
    QA: {
      env: 'QA',
      src: 'https://qa.mfe.svcs.newfold.com/ctb/click-to-buy.bundle.js',
    },
    PROD: {
      env: 'PROD',
      src: 'https://mfe.svcs.newfold.com/ctb/click-to-buy.bundle.js',
    },
  };

  // LOCAL
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK)
    return environments.LOCAL;

  // staging
  if (aud === 'staging') return environments.staging;

  // QA
  if (!isUserEnvProd) return environments.QA;

  // PROD
  return environments.PROD;
}
