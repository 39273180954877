import Grid from '@mui/material/Grid';

import DevMode from '~/components/DevMode';
import useHashScroll from '~/components/useHashScroll';
import UpdatePHPVersion from '~/scenes/Site/components/UpdatePHPVersion';
import AdjustStorage from '~/scenes/Site/scenes/Cloud/scenes/Settings/AdjustStorage';
import DeleteSite from '~/scenes/Site/scenes/Cloud/scenes/Settings/DeleteSite';

export default function Settings() {
  useHashScroll(500);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AdjustStorage />
      </Grid>
      {/* @ts-expect-error, HOST-457 */}
      <DevMode envs={['development', 'beta']}>
        <Grid item xs={12}>
          <UpdatePHPVersion displayCard={true} />
        </Grid>
      </DevMode>
      <Grid item xs={12}>
        <DeleteSite />
      </Grid>
    </Grid>
  );
}
