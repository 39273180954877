import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import ServerEmail from '~/apps/ServerEmail';
import LoadingScene from '~/components/MFEProvider/lib/ReactSuspense/LoadingScene';
import Hosting from '~/scenes/Hosting';
import Overview from '~/scenes/Hosting/scenes/HostingDetail/scenes/Overview';

import { getIsDevMode } from './components/DevMode';
import HostingList from './scenes/HostingList';

const Databases = lazy(() => import('~/apps/Databases'));
const Sites = lazy(() => import('~/scenes/Sites'));
const SSHKeyManager = lazy(() => import('~/apps/SSHKeyManager'));
const ProServices = lazy(() => import('~/apps/ProServices'));
const CronJobs = lazy(() => import('~/apps/CronJobs'));
const FtpAccessManger = lazy(() => import('~/apps/FTPAccess'));
const Resources = lazy(() =>
  import(
    './scenes/Hosting/scenes/HostingDetail/scenes/Overview/Cloud/scenes/Resources'
  ),
);

const MainRoutes = () => (
  <Suspense>
    <Routes>
      <Route path="/*">
        {/* each of these need to implement a TenantValidation */}
        {/* each of these need to be wrapped in HostingProvider */}
        <Route element={<Hosting />}>
          <Route index element={<Overview />} />
          <Route path="cron-jobs/*" element={<CronJobs />} />
          <Route path="ssh-keys/*" element={<SSHKeyManager />} />
          <Route path="pro-services/*" element={<ProServices />} />
          <Route path="ftp-access/*" element={<FtpAccessManger />} />
          <Route path="databases/*" element={<Databases />} />
          <Route path="email/*" element={<ServerEmail />} />
          <Route path="resources/*" element={<Resources />} />
        </Route>

        {/* each of these need to implement a TenantValidation */}
        {/* sub-routes of Sites need to be wrapped in HostingProvider at the SiteDetail level */}
        <Route path="sites/*" element={<Sites />} />
        {getIsDevMode(['development', 'beta']) && (
          <Route path="hosting/*">
            <Route index element={<HostingList />} />
            <Route path=":hostingId" element={<Overview />} />
          </Route>
        )}

        {/* No validation needed */}
        <Route path="*" element={<LoadingScene />} />
      </Route>
    </Routes>
  </Suspense>
);

export default MainRoutes;
