import { useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getSitesInfoQueryKey,
  useSitesDomainsV2,
  useSiteUpdate,
} from '@newfold/huapi-js';
import { SitesDomainsV2Params } from '@newfold/huapi-js/src/index.schemas';

import useAlerts from '~/components/Alerts/alertsStore';
import { useBrandInfo } from '~/hooks/useBrandInfo';

const ChangeSiteURLModal = ({
  domain = undefined,
  open = false,
  setOpen,
}: PropsWithChildren<{
  domain?: string;
  open?: boolean;
  setOpen: Function;
}>) => {
  const { t } = useTranslation('domains');
  const [, { generateAlert }] = useAlerts();
  const { siteId } = useParams();
  const queryClient = useQueryClient();
  const { phoneNumberFormatted } = useBrandInfo();

  const [fetchDomains, setFetchDomain] = useState(false);
  const handleClose = () => setOpen(false);

  const sitesInfoQueryKey = getSitesInfoQueryKey(Number(siteId));

  const queryParams: SitesDomainsV2Params = {};
  useSitesDomainsV2(Number(siteId), queryParams, {
    query: {
      enabled: !!siteId && fetchDomains,
      // refetch Domain list on update success until domain is found in the list and is active
      refetchInterval: (data) => {
        const domains = data?.data?.domains;
        const domainStatus = domains?.find((d) => d.domain === domain)?.status;
        const keepPolling = fetchDomains && domainStatus !== 'active';
        return keepPolling ? 5000 : false;
      },
      onSuccess: (data) => {
        const domains = data?.data?.domains;
        const domainStatus = domains?.find((d) => d.domain === domain)?.status;
        const siteUrlUpdated = domainStatus === 'active';

        if (siteUrlUpdated) {
          setFetchDomain(false);
          generateAlert({
            description: (
              <Trans
                i18nKey="domains:changeSiteURLModal.successMessage"
                values={{ url: domain }}
                components={{ b: <b /> }}
              />
            ),
            severity: 'success',
            showCloseBtn: true,
          });
          handleClose();
          queryClient.invalidateQueries(sitesInfoQueryKey);
        }
        return data;
      },
    },
  });

  const { mutate: updateSiteUrl, isLoading } = useSiteUpdate({
    mutation: {
      onError: () => {
        generateAlert({
          description: (
            <Trans
              i18nKey="domains:changeSiteURLModal.errorMessage"
              values={{ phone: phoneNumberFormatted }}
              components={{ b: <b /> }}
            />
          ),
          severity: 'error',
          showCloseBtn: true,
        });
        handleClose();
      },
      onSuccess: () => {
        setFetchDomain(true);
      },
    },
  });

  const handleConfirm = () => {
    updateSiteUrl({
      siteId: Number(siteId),
      data: { domain, skip_fg_checks: true },
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="change-site-url-modal-title"
      aria-describedby="change-site-url-modal-description"
      data-testid="change-site-url-modal"
    >
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <WarningIcon color="error" />
          <DialogTitle>{t('changeSiteURLModal.title')}</DialogTitle>
        </Stack>
        <Divider />
        <Typography variant="body1" py={3}>
          <Trans
            i18nKey="domains:changeSiteURLModal.description"
            values={{ url: domain }}
            components={{ b: <b /> }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            onClick={handleClose}
            data-testid="change-site-url-cancel-btn"
          >
            {t('changeSiteURLModal.cancelBtn')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            loading={isLoading || fetchDomains}
            data-testid="change-site-url-confirm-btn"
          >
            {t('changeSiteURLModal.confirmBtn')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeSiteURLModal;
