import Chip, { ChipProps } from '@mui/material/Chip';

export default function ChipPro({ ...props }: ChipProps) {
  return (
    <Chip
      size="small"
      color="warning"
      sx={{
        backgroundColor: '#FF5C00',
        borderRadius: '4px',
        color: '#fff',
        fontSize: '11px',
      }}
      label="PRO"
      {...props}
    />
  );
}
