import { createRoot } from 'react-dom/client';

import Init from './Init';
import reportWebVitals from './reportWebVitals';
import startDevMode from './startDevMode';
import { HostingStatus } from './types';

export interface InitProps {
  /**
   * Host app can provide contextual data to the application
   */
  /**
   * Base path to application, such as /hosting/app. May include full URL.
   */
  basename?: string;
  data: {
    /**
     * User JWT to use for HUAPI authentication
     */
    accessToken: string;
    /**
     * Hosting ID currently selected plan
     */
    hostingId?: string;
    /**
     * Hosting related information, status
     */
    hostingData?: {
      /**
       * Status indicator for the selected Hosting plan
       */
      status?: HostingStatus;
      /**
       * Expiration date, expected format is `MM/DD/YYYY`
       */
      expiryDate?: number | string | Date;
      /**
       * accountId
       */
      accountId?: number;
      /**
       * ex: `BH_PKG_WP_ENT`
       */
      productCode?: string;
      /**
       * ex: 'WN.HP.320926252'
       */
      productInstanceId?: string;
      /**
       * ex: `WordPress Basic (Hosting)`
       */
      productName?: string;
      /**
       * Whether auto renewal is currently enabled.
       */
      hasAutoRenew?: boolean;
    };
    /**
     * Optional: API Base URL for the Hosting User API. If not provided, the proper URL for the current
     * environment will be derived based on the MFE environment.
     */
    apiBaseUrl?: string;
    /**
     * Link to renewal center
     */
    renewalCenterLink?: string;
    /**
     * Link to MarketPlace
     */
    marketPlaceLink?: string;
    /**
     * Optional link to purchase another hosting account
     */
    addHostingAccountUrl?: string;
    /**
     * optional pro services info
     */
    proServices?: {
      /**
       * link to pro services dashboard
       */
      link?: string;
    };
  };
  /**
   * Provide callbacks to the application
   */
  callbacks: {
    /**
     * Provide a function to refresh the access token
     */
    refreshAccessToken?: () => Promise<string>;
    /**
     * Function to invoke when an error occurs during initialization
     * - Ex, for AMM: This will open an AMM snackbar
     */
    handleInitErrors?: (error: Error) => void;
  };
}

export type InitFunctionOptions = InitProps & {
  /**
   * id of dom element to mount application do, for example, if you have `<div id="app"><!-- app goes here --></div>`, provide value `app`
   */
  selector: string;
};

export const init = ({
  basename = '',
  callbacks,
  data,
  selector,
}: InitFunctionOptions) => {
  const el = document.getElementById(selector);
  const root = el !== null ? createRoot(el) : null;

  if (root) {
    root.render(<Init basename={basename} callbacks={callbacks} data={data} />);

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  }

  return {
    selector,
    root,
    el,
  };
};

startDevMode();

const exports = { init, Init };
export default exports;
