import { number, oneOfType, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BackupIcon from '@mui/icons-material/Backup';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import StatusListItem from '~/components/StatusListItem';
import useSiteBackups from '~/hooks/useSiteBackups';

const propTypes = {
  siteId: oneOfType([number, string]),
  siteType: string,
};

const BackupStatus = ({ siteId = undefined, siteType = undefined }) => {
  const { t } = useTranslation(['site', 'backups']);

  const {
    data: codeguardDetails,
    isLoading,
    isError,
  } = useSiteBackups(siteId, 'codeguard');

  const genericBackupStatusOn =
    siteType === 'generic' && !!codeguardDetails?.website_id;

  const wordpressBackupStatusOn =
    siteType === 'wordpress' && codeguardDetails?.activated;

  const backupStatus =
    genericBackupStatusOn || wordpressBackupStatusOn ? 'on' : 'off';

  const navigate = useNavigate();

  const handleLink = (url) => {
    navigate(url);
  };

  // Status will be displayed regardless the site type
  // if codeguard addon is not associated, manage link will provide an option navigate to backup page and purchase codeguard
  return (
    <StatusListItem
      isLoading={isLoading}
      isError={isError}
      dataTestId={'on' === backupStatus ? 'backupStatusON' : 'backupStatusOFF'}
      avatarIcon={'on' === backupStatus ? BackupIcon : CloudOffIcon}
      avatarIconColor={'on' === backupStatus ? 'success' : 'error'}
      tooltipText={
        'on' !== backupStatus
          ? t(`backups:status.${backupStatus}.tooltipDesc`)
          : ''
      }
      ctaText={t('backups:manageBtnLabel')}
      handleCTA={() => handleLink('backups')}
    >
      {t('backupMsg', { siteBackupStatus: backupStatus })}
    </StatusListItem>
  );
};

BackupStatus.propTypes = propTypes;

export default BackupStatus;
