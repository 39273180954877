import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import useSitePath from '~/hooks/useSitePath';
import {
  checkCreatingStatus,
  checkDeletingStatus,
  checkFailedDelete,
  checkFailedReset,
  checkFailedStatus,
  checkResettingStatus,
} from '~/utils/siteMeta';

// TODO: this could be improved by extracting the primary action into its own util method
// - would allow quicker access in places like SiteTitle.js
const PrimaryAction = ({
  status,
  type,
  id,
  migration_id,
  disabled,
  ...props
}) => {
  const { t } = useTranslation('sites');

  const { siteRedirect } = useSitePath();

  // Migration in progress (includes failed status)
  if (
    (checkCreatingStatus(status) || checkFailedStatus(status)) &&
    migration_id
  )
    return (
      <Stack {...props} direction="row" spacing={1} alignItems="center">
        {checkFailedStatus(status) && (
          <Tooltip
            title={t('disableSite.migrationFailedToolTip')}
            arrow
            placement="top-start"
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
        <Typography variant="body2">{t('disableSite.migratingTxt')}</Typography>
        <CircularProgress size={18} />
      </Stack>
    );

  // Installing WordPress (includes failed status)
  if (checkCreatingStatus(status) || checkFailedStatus(status))
    return (
      <Stack {...props} direction="row" spacing={1} alignItems="center">
        {checkFailedStatus(status) && (
          <Tooltip
            title={t('disableSite.failedCreateToolTip')}
            arrow
            placement="top-start"
          >
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
        <Typography variant="body2">
          {type === 'wordpress'
            ? t('disableSite.installingWordPress')
            : t('disableSite.installingTxt')}
        </Typography>
        <CircularProgress size={18} />
      </Stack>
    );

  // Reset/Content Deleting
  if (checkResettingStatus(status))
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2">
          {type === 'wordpress'
            ? t('disableSite.resettingWordPress')
            : t('disableSite.resettingSite')}
        </Typography>
        <CircularProgress size={18} />
      </Stack>
    );

  // Reset/Content Failed
  if (checkFailedReset(status))
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2">{t('disableSite.resetFailed')}</Typography>
        {/* TODO: need copy */}
        <Tooltip
          title={t('disableSite.failedResetToolTip')}
          arrow
          placement="top-start"
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );

  // Deleting
  if (checkDeletingStatus(status))
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2">{t('disableSite.deleting')}</Typography>
        <CircularProgress size={18} />
      </Stack>
    );

  // Delete Failed
  if (checkFailedDelete(status))
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2">{t('disableSite.deleteFailed')}</Typography>
        <Tooltip
          title={t('disableSite.failedDeleteToolTip')}
          arrow
          placement="top-start"
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );

  // Settings Button
  return (
    <Button
      {...props}
      variant="outlined"
      disabled={disabled}
      onClick={() => {
        // go to site detail page
        siteRedirect(id);
      }}
    >
      {t('settingsBtn')}
    </Button>
  );
};

export default PrimaryAction;
