import { closeSnackbar } from 'notistack';
import { forwardRef } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

const AlertTemplate = forwardRef((props, ref) => {
  const alertProps = props;

  const alertFunc = () => {
    alertProps?.cta();
    closeSnackbar(alertProps?.id);
  };

  return (
    <Alert
      ref={ref}
      severity={'error'}
      {...alertProps}
      {...(alertProps?.showCloseBtn && {
        onClose: () => closeSnackbar(alertProps?.id),
      })}
      {...(alertProps?.ctaText && {
        action: (
          <Button onClick={alertFunc} color={alertProps?.severity}>
            {alertProps?.ctaText}
          </Button>
        ),
      })}
      variant="outlined"
    >
      {alertProps?.title && <AlertTitle>{alertProps?.title}</AlertTitle>}
      {alertProps?.description}
    </Alert>
  );
});
export default AlertTemplate;
