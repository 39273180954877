import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingSshInfo } from '@newfold/huapi-js';

import Copy from '~/components/Copy';
import InfoListItem from '~/components/InfoListItem';
import SsoLink from '~/components/SsoLink';
import useAccount from '~/hooks/useAccount';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';

const SshAccessInfo = () => {
  const { t } = useTranslation('site');
  const { ssh } = t('quickConnect.items', { returnObjects: true });

  const { id: hostingId } = useAccount();

  const { isDisabled } = useHostingInfo();
  const [hasSites] = useFeature('sites');

  const enableSSshInfo = !isDisabled && hasSites;
  const {
    isLoading: sshLoading,
    data: sshData,
    isError: isSshError,
  } = useHostingSshInfo(hostingId, {
    query: {
      enabled: enableSSshInfo,
    },
  });

  if (!hasSites) {
    return null;
  }

  const sshKey = sshData?.data?.credential;
  return (
    <InfoListItem
      key={ssh?.id}
      title={ssh?.label}
      tooltipText={ssh?.tooltipText}
      inlineCTA={
        <SsoLink
          variant="text"
          type={'hosting'}
          queryParams={{ app: 'sshkeys' }}
          data-testid="btn-advanced"
          aria-disabled
        >
          {ssh?.inlineCta}
        </SsoLink>
      }
    >
      {sshLoading && (
        <Stack direction="row" spacing={1}>
          <Skeleton width={185} height={24} />
          <Skeleton width={64} height={24} />
        </Stack>
      )}
      {!isSshError && !sshLoading && sshKey && (
        <Copy disable={isDisabled}>
          <Typography
            sx={{ wordBreak: 'break-all' }}
            variant="body1"
            data-testid="ssh-credential"
          >
            {sshKey}
          </Typography>
        </Copy>
      )}
    </InfoListItem>
  );
};

export default SshAccessInfo;
