import { Fragment } from 'react';

const appEnv = process.env.REACT_APP_ENV;

export const isTestMode = process.env.NODE_ENV === 'test';

export const getIsDevMode = (envs = []) => {
  if (isTestMode) return true;
  if (process.env.REACT_APP_HIDE_DEVMODE) return false;
  if (process.env.REACT_APP_MOCK) return true;
  if (appEnv && envs.length >= 1) return envs.includes(appEnv);
  return process.env.NODE_ENV === 'development';
};

export const isDevMode = getIsDevMode();

/**
 * DevMode component props.
 *
 * @param {object} props - top level props
 *
 * @param {['development', 'beta', 'staging', 'production']} props.envs - Additional environments that show nested component
 */

const DevMode = ({ children, team, eta, jira, envs = [], ...props }) => {
  if (getIsDevMode(envs) && !isTestMode)
    console.warn(
      'Rendered a React component that is still Under Construction...',
      {
        team,
        eta,
        jira,
        envs,
        ...props,
      },
    );
  return getIsDevMode(envs) ? <Fragment>{children}</Fragment> : null;
};

export default DevMode;
