import { useParams } from 'react-router-dom';

import { useMFEContext } from '~/components/MFEProvider';
import { useSiteContext } from '~/scenes/Site';

import useSitePath from './useSitePath';

const useAccount = () => {
  const { siteId } = useParams();
  const { accountId } = useSiteContext();
  const { hostingId } = useMFEContext();
  const { isValid } = useSitePath(siteId);

  if (
    // if there's a site id, get the account it relates to
    siteId &&
    // if siteId is not valid, then user is trying to
    // get a deep link to a specific site, so they aren't
    // in a specific site context just yet
    isValid
  ) {
    return { id: accountId };
  }
  return { id: hostingId };
};

export default useAccount;
