export const statuses = {
  creating: ['create', 'pending_create'],
  failed: ['failed_create'],
  resetting: ['pending_reset', 'reset'],
  failedreset: ['failed_reset'],
  deleting: ['pending_delete', 'delete'],
  faileddelete: ['failed_delete'],
  suspended: ['decom', 'disabled', 'tos', 'aup'],
};

export const checkDeletingStatus = (status) =>
  statuses.deleting.includes(status);

export const checkFailedDelete = (status) =>
  statuses.faileddelete.includes(status);

export const checkResettingStatus = (status) =>
  statuses.resetting.includes(status);

export const checkFailedReset = (status) =>
  statuses.failedreset.includes(status);

export const checkFailedStatus = (status) => statuses.failed.includes(status);

export const checkCreatingStatus = (status) =>
  statuses.creating.includes(status);

export const checkSuspendedStatus = (status) =>
  statuses.suspended.includes(status);

export const getSiteStatus = (status) => {
  if (statuses.creating.includes(status)) {
    return 'creating';
  }
  if (statuses.failed.includes(status)) {
    return 'failed';
  }
};

export const getCanShowLogin = ({ status, type, detected_type }) =>
  type === 'wordpress' &&
  type === detected_type &&
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status) &&
  !checkFailedReset(status) &&
  !checkDeletingStatus(status) &&
  !checkFailedDelete(status);

export const getCanShowDocrootMismatchAlert = ({ status }) =>
  status === 'docroot_mismatch';

export const getCanShowFixSiteModal = ({ status, type, detected_type }) =>
  type === 'wordpress' &&
  type !== detected_type &&
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status);

export const getCanShowManageInTitle = ({ status }) =>
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status) &&
  !checkDeletingStatus(status) &&
  !checkFailedDelete(status);

export const getCanShowManageInMenu = ({ status }) =>
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status) &&
  !checkFailedReset(status) &&
  !checkDeletingStatus(status) &&
  !checkFailedDelete(status);

export const getCanShowViewSite = ({ status }) =>
  !checkCreatingStatus(status) &&
  !checkFailedStatus(status) &&
  !checkFailedReset(status) &&
  !checkDeletingStatus(status) &&
  !checkFailedDelete(status) &&
  !checkSuspendedStatus(status);

export const getCanShowRenameSite = ({ status }) =>
  !checkCreatingStatus(status) && !checkFailedStatus(status);

export const getIsPendingAction = (site) =>
  checkFailedStatus(site?.status) ||
  checkCreatingStatus(site?.status) ||
  checkFailedReset(site?.status) ||
  checkResettingStatus(site?.status) ||
  checkDeletingStatus(site?.status) ||
  checkFailedDelete(site?.status);
