import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const MetricsCard = () => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'performance.metrics',
  });

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" spacing={2}>
            <Typography variant="h2">{t('title')}</Typography>
            {/*TODO: Remove this Chip after 90 days from the date of release.*/}
            <Chip
              label={
                <Typography variant="h4" color="text.primary">
                  {t('new')}
                </Typography>
              }
              sx={{
                backgroundColor: 'success.400',
                borderRadius: 2,
              }}
            />
          </Stack>
        }
        subheader={<Typography pt={1}>{t('desc')}</Typography>}
        action={
          <Button variant="outlined" onClick={() => navigate('metrics')}>
            {t('viewAllBtn')}
          </Button>
        }
      />
      <CardContent>
        <Stack spacing={2}>
          <Typography variant="h4">
            {t('previewMetrics', { days: '14' })}
          </Typography>
          {/*TODO: What data to show from summary, will be handled in another story.*/}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MetricsCard;
