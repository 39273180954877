import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Portal from '@mui/material/Portal';

const Wrapper = ({
  hasPurchasedRecently = false,
  children = null,
  container = {},
}: PropsWithChildren<{
  container?: object;
  hasPurchasedRecently?: boolean;
}>) => {
  const { t } = useTranslation('site');

  return (
    <>
      {hasPurchasedRecently && container && (
        // @ts-ignore
        <Portal container={container.current}>
          <Alert data-testid="alert-banner" severity="warning">
            {t(`advancedServer.alerts.serverStatus.description`)}
          </Alert>
        </Portal>
      )}
      {children}
    </>
  );
};

export default Wrapper;
