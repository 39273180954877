import Button, { ButtonProps } from '@mui/material/Button';

import useResponsive from '~/hooks/useResponsive';

export default function ButtonAlt(props: ButtonProps) {
  const { isMobile, isTablet } = useResponsive();
  return (
    <Button
      sx={{
        textTransform: 'none',
        letterSpacing: 0,
        borderColor: 'grey.200',
        color: 'grey.800',
        ':hover': {
          // borderColor: 'primary.500',
          // backgroundColor: 'grey.50'
          color: 'grey.900',
        },
        flex: '0 1 auto',
        width: isMobile() ? 128 : isTablet() ? 154 : 161,
        p: 2,
        ...props.sx,
      }}
      variant="outlined"
      {...props}
    >
      {props.children}
    </Button>
  );
}
