import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useMFEContext } from '~/components/MFEProvider';

export default function Empty() {
  const {
    // @ts-expect-error
    data: { addHostingAccountUrl },
  } = useMFEContext();

  return (
    <Alert
      //@ts-expect-error
      border="none"
      severity="info"
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <Stack spacing={2}>
        <Typography>No hosting accounts found</Typography>
        <Button variant="outlined" href={addHostingAccountUrl}>
          Buy Hosting Package
        </Button>
      </Stack>
    </Alert>
  );
}
