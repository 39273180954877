import { useTranslation } from 'react-i18next';

import InfoListItem from '~/components/InfoListItem';
import SsoLink from '~/components/SsoLink';
import useFeature from '~/hooks/useFeature';

const FtpAccessInfo = () => {
  const { t } = useTranslation('site');
  const { ftp } = t('scenes.advanced', { returnObjects: true });

  const [hasSites] = useFeature('sites');
  if (!hasSites) {
    return null;
  }

  return (
    <InfoListItem
      title={ftp?.title}
      tooltipText={ftp?.tooltip}
      {...{
        inlineCTA: (
          <SsoLink variant="text" type={'hosting'} queryParams={{ app: 'ftp' }}>
            {ftp?.manageBtn}
          </SsoLink>
        ),
      }}
    />
  );
};

export default FtpAccessInfo;
