import { init } from './bootstrap';
import hostAppProps from './mocks/data/host-app';

const startDevMode = () => {
  // If we are in development and in isolation,
  // call init immediately
  if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
      module.hot.accept();
    }

    const devRoot = document.querySelector(`#${process.env.REACT_APP_ROOT_ID}`);
    if (devRoot) {
      // enable mock mode
      if (process.env.REACT_APP_MOCK) {
        const { worker } = require('./mocks/browser');
        worker.start();
      }

      init(hostAppProps);
    }
  }
};

export default startDevMode;
