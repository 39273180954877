import { useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import { useSitesErrorLogs } from '@newfold/huapi-js';

import LogsCard from './components/LogsCard';

export default function Logs() {
  const { siteId } = useParams();
  const { data, isLoading, isError } = useSitesErrorLogs(Number(siteId));
  const errorLogsInfo = data?.data;

  const accessLogsInfo = {
    date_end: '2024-02-10 03:08:51',
    date_start: '2024-01-31 03:08:51',
    total: 920,
    logs: [
      {
        body_bytes_sent: 0,
        cached: false,
        date: '2024-04-19T06:54:53.805Z',
        http_host: 'example.com',
        http_referrer: '-',
        http_user_agent:
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3',
        http_x_forwarded_for: '138.68.130.46',
        renderer: 'php',
        request_completion: 'OK',
        request_time: 0.15,
        request_type: 'GET',
        request_url: '/wp-admin/admin-ajax.php',
        scheme: 'https',
        status: 200,
        timestamp: 1713509693,
        type: 'nginx_json',
        user_ip: '1.3.3.7',
      },
    ],
  };

  return (
    <Stack spacing={2}>
      <LogsCard
        type="error"
        total={errorLogsInfo?.total!}
        isLoading={isLoading}
        isError={isError}
      />
      <LogsCard
        type="access"
        total={accessLogsInfo?.total}
        isLoading={false}
        isError={false}
      />
    </Stack>
  );
}
