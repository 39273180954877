import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

const testingEnv = process.env.NODE_ENV === 'test';

const cacheTime = 1000 * 60 * 60 * 24; // 24 hours;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  logger: testingEnv
    ? {
        log: console.log,
        warn: console.warn,
        // ✅ no more errors on the console for tests
        error: () => {}, // noop for tests
      }
    : undefined,
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

// The following values are persisted in local storage
const PERSIST_QUERIES_ALLOW_LIST = ['capabilities', 'ctb/hasPurchased'];

persistQueryClient({
  // TODO: in the future we want to bust the cache of our local storage if the app updates to a new version
  // We could use for example REACT_APP_BUILD_HASH or some value that is unique per build, but we don't have this value today
  // buster: REACT_APP_BUILD_HASH, // TODO
  queryClient,
  persister,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) =>
      !!PERSIST_QUERIES_ALLOW_LIST.find(
        (query) => Array.isArray(queryKey) && queryKey[0].includes(query),
      ),
  },
});

export default queryClient;
