import { bool, number, oneOf, oneOfType, string } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import FixSiteModal from '~/components/FixSiteModal';
import SsoLink from '~/components/SsoLink';
import RenameModal from '~/scenes/Site/components/RenameModal';
import {
  checkFailedDelete,
  checkFailedReset,
  checkFailedStatus,
  getCanShowFixSiteModal,
  getCanShowLogin,
  getCanShowManageInMenu,
  getCanShowViewSite,
} from '~/utils/siteMeta';

import DeleteBtn from './DeleteBtn';

const propTypes = {
  id: oneOfType([string, number]),
  disabled: bool,
  status: string,
  type: oneOf(['wordpress', 'generic', 'builder']),
  detected_type: oneOf(['wordpress', 'generic', 'builder']),
  url: string,
};

const ExtraSiteActionMenu = ({
  site = null,
  disabled = false,
  id = undefined,
  name = undefined,
  status = undefined,
  type = undefined,
  detected_type = undefined,
  url = undefined,
}) => {
  const { t } = useTranslation('sites');
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showRenameModal, setRenameShowModal] = useState(false);
  const [openFixSiteModal, setOpenFixSiteModal] = useState(false);
  const checkFailed =
    checkFailedStatus(status) ||
    checkFailedReset(status) ||
    checkFailedDelete(status);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFixSiteModal = () => {
    setOpenFixSiteModal(false);
    setAnchorEl(null);
  };

  const closeRenameModal = () => {
    setRenameShowModal(false);
  };

  if (!id) return null;

  const canShowMenu = !['pending_create', 'delete', 'pending_delete'].includes(
    status,
  );

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={!canShowMenu || disabled}
      >
        <MoreVertRounded />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {getCanShowViewSite({ status }) && url && (
          <MenuItem
            href={url}
            target="_blank"
            rel="noreferrer noopener"
            component="a"
            onClick={handleClose}
          >
            {t('viewSiteBtn')}
          </MenuItem>
        )}
        {getCanShowFixSiteModal({ status, type, detected_type }) && (
          <MenuItem
            data-testid={`sso-menu-item-${id}`}
            onClick={() => setOpenFixSiteModal(true)}
          >
            {t('loginBtn')}
          </MenuItem>
        )}
        {getCanShowLogin({ status, type, detected_type }) && (
          <SsoLink
            variant="menuItem"
            type="site"
            id={id}
            onAction={handleClose}
            disabled={disabled}
          >
            {t('loginBtn')}
          </SsoLink>
        )}
        {getCanShowManageInMenu({ status, type }) && (
          <MenuItem onClick={() => navigate(`/sites/${id}`)}>
            {t('settingsBtn')}
          </MenuItem>
        )}

        {checkFailed && (
          <DeleteBtn siteId={id} name={name} onAction={handleClose} />
        )}
      </Menu>
      <RenameModal
        showModal={showRenameModal}
        closeModal={closeRenameModal}
        siteName={name}
        siteId={id}
      />
      {getCanShowFixSiteModal({ status, type, detected_type }) && (
        <FixSiteModal
          siteId={id}
          open={openFixSiteModal}
          setOpen={handleFixSiteModal}
        />
      )}
    </>
  );
};

ExtraSiteActionMenu.propTypes = propTypes;
export default ExtraSiteActionMenu;
