import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Loading() {
  return (
    <Card>
      <CardHeader
        variant="cardlistheader"
        title={<Skeleton width={120} />}
        action={
          <IconButton disabled>
            <Skeleton width={12} />
          </IconButton>
        }
      />
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <Typography component="span">Status:</Typography>
            <Skeleton width={60} />
          </Stack>
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <Typography component="span">Account:</Typography>
            <Skeleton width={80} />
          </Stack>
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <Typography component="span">Renewal Date:</Typography>
            <Skeleton width={80} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
