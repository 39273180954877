import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useHostingAdvancedPassword } from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import { Validation } from '~/utils/PasswordValidations';

import useAlerts from '../../../../../../src/components/Alerts/alertsStore';

const ResetPasswordModal = ({
  openPasswordResetModal,
  setOpenPasswordResetModal,
}) => {
  const [validPassword, setValidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [, { generateAlert }] = useAlerts();
  const { id: accountId } = useAccount();

  const { t } = useTranslation('settings', {
    keyPrefix: 'resetPasswordCard.resetPassword.modal',
  });

  const { mutate: resetPassword, isLoading } = useHostingAdvancedPassword({
    mutation: {
      onSuccess: (data) => {
        generateAlert({
          severity: 'success',
          description: t('successMsg'),
          showCloseBtn: true,
        });
      },
      onError: (error) => {
        generateAlert({
          severity: 'error',
          description: t('failureMsg'),
          showCloseBtn: true,
        });
      },
    },
  });

  const handleCancel = () => {
    setValue('password', '');
    setValidPassword(false);
    setOpenPasswordResetModal(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
  });
  const handleChange = (event) => {
    event.preventDefault();
    if (!event.target.value) {
      setValue('password', '');
      return setValidPassword(true);
    }
    let valid = Validation(event.target.value);
    setValidPassword(valid);
  };

  const onSubmit = (formData) => {
    resetPassword({
      hostingId: accountId,
      data: {
        password: formData.password,
      },
    });
    setValue('password', '');
    setValidPassword(false);
    setOpenPasswordResetModal(false);
  };

  return (
    <Dialog
      open={openPasswordResetModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            <Trans i18nKey="settings:resetPasswordCard.resetPassword.modal.desc" />
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1}>
              <TextField
                fullWidth
                label="Password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                readOnly
                required
                {...register('password')}
                error={errors.password ? (validPassword ? false : true) : false}
                helperText={validPassword ? '' : t('helperText')}
                autoComplete="new-password"
                inputProps={{
                  maxLength: 14,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t('iconText')}
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
              />
            </Stack>
            <Stack spacing={1} direction="row" justifyContent="flex-end" mt={2}>
              <Button variant="text" onClick={handleCancel}>
                {t('cancelBtn')}
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                disabled={!validPassword}
              >
                {t('changeBtn')}
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordModal;
