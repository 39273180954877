import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';

import { useHostingAccount } from '@newfold/huapi-js';

import { useMFEContext } from '~/components/MFEProvider';
import LoadingScene from '~/components/MFEProvider/lib/ReactSuspense/LoadingScene';
import useFeature from '~/hooks/useFeature';
import MultipleChoiceSsoDialog from '~/scenes/Hosting/scenes/HostingDetail/components/QuickLinks/MultipleChoiceSsoDialog';
import useServerEmailChoices from '~/scenes/Hosting/scenes/HostingDetail/components/QuickLinks/ServerEmailChoices';

const ServerEmail = () => {
  // @ts-ignore
  const { hostingId } = useMFEContext();
  const navigate = useNavigate();
  const { data, isInitialLoading } = useHostingAccount(hostingId);

  const { t } = useTranslation('quickLinks');

  const [hasAdvancedHosting, , isClientReady] = useFeature('advanced_hosting');
  const [hasCpanel] = useFeature('cpanel');
  const isReseller = data?.data?.type === 'reseller';

  const choices = useServerEmailChoices();

  // validation the user can see "Server Email" content before showing
  if (
    !isInitialLoading &&
    isClientReady &&
    (!hasCpanel || hasAdvancedHosting || isReseller)
  )
    // otherwise, send the user to the hosting page
    return navigate('/');

  return (
    <Container>
      {!isClientReady || isInitialLoading || !choices ? (
        // LOADING
        <LoadingScene />
      ) : (
        // EMAIL SELECTION DIALOG
        <MultipleChoiceSsoDialog
          isOpen
          onClose={() => navigate('/')}
          title={t('serverEmail.title')}
          description={t('serverEmail.description')}
          choices={choices}
        />
      )}
    </Container>
  );
};

export default ServerEmail;
