import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingAdvancedServer } from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import useFeature from '~/hooks/useFeature';

import AdvancedHostingIpInfo from './AdvancedHostingIpInfo';
import AdvancedHostingResetPassword from './AdvancedHostingResetPassword';

const AdvancedHostingServerInfo = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard',
  });

  const [isPlesk, plesk] = useFeature('plesk');
  const [isWindows, windows] = useFeature('windows');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id: hostingId } = useAccount();

  const { data: serverData } = useHostingAdvancedServer(hostingId, {
    query: {
      refetchOnWindowFocus: false,
      enabled: !!hostingId,
    },
  });

  const serverStatus = serverData?.data?.status;
  useEffect(() => {
    const statuses = ['active', 'stopped', 'failed_start', 'decom'];
    if (serverStatus && statuses.includes(serverStatus)) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [serverStatus]);

  return (
    <Stack spacing={2}>
      <Typography variant="h2">
        {t('advHostingServerInfo.advHostingServerInfoTitle')}
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" color="grey.500">
                  {t('advHostingServerInfo.advHostingOs')}
                </Typography>
                {isLoading ? (
                  <Skeleton width="90%" />
                ) : (
                  <Typography variant="body2" textAlign={'left'}>
                    {/* @ts-ignore*/}
                    {serverData?.data?.os_name ?? ''}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" color="grey.500">
                  {t('advHostingServerInfo.advHostingIp')}
                </Typography>
                <AdvancedHostingIpInfo
                  serverData={serverData}
                  isLoading={isLoading}
                />
              </Stack>
            </Grid>
            {/* @ts-ignore*/}
            {serverData?.data?.hostname && (
              <Grid item xs={12} sm={6} md={3}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2" color="grey.500">
                    {t('advHostingServerInfo.advHostingHostName')}
                  </Typography>
                  {isLoading ? (
                    <Skeleton width="90%" />
                  ) : (
                    <Typography
                      variant="body2"
                      textAlign={'left'}
                      sx={{ wordWrap: 'break-word' }}
                    >
                      {/* @ts-ignore*/}
                      {serverData?.data?.hostname ?? ''}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body2" color="grey.500">
                  {t('advHostingServerInfo.advHostingRootPass')}
                </Typography>
                <AdvancedHostingResetPassword serverData={serverData} />
              </Stack>
            </Grid>
            {(isPlesk || isWindows) && (
              <Grid item xs={12} sm={6} md={3}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2" color="grey.500">
                    {t('advHostingServerInfo.advHostingUserName')}
                  </Typography>
                  <Typography variant="body2" textAlign={'left'}>
                    {plesk?.username || windows?.username}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default AdvancedHostingServerInfo;
