import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import {
  bool,
  func,
  node,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { useEffect, useState } from 'react';

import { isDevMode, isTestMode } from '~/components/DevMode';
import { useJwtAutoRefresh } from '~/hooks/useJwtAutoRefresh';

import queryClient from './queryClient';

const propsObject = shape({
  className: string,
  style: object,
  onClick: func,
});

const propTypes = {
  children: oneOfType([node, func]),
  disableReactQueryDevTools: bool,
  reactQueryDevToolsConfig: shape({
    initialIsOpen: bool,
    panelProps: propsObject,
    closeButtonProps: propsObject,
    toggleButtonProps: propsObject,
    position: oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
  }),
  apiBaseUrl: string,
  accessToken: string.isRequired,
  refreshAccessToken: func.isRequired,
  setAccessToken: func.isRequired,
  appEnv: oneOf(['development', 'staging', 'production']),
};

const HttpClient = ({
  children = undefined,
  disableReactQueryDevTools = false,
  reactQueryDevToolsConfig = undefined,
  apiBaseUrl = undefined,
  accessToken,
  refreshAccessToken,
  setAccessToken,
  appEnv = process.env.REACT_APP_ENV,
}) => {
  const [ready, setReady] = useState(false);

  const getApiBaseUrl = (env, serverUrl) => {
    if (serverUrl) return serverUrl;
    switch (env) {
      case 'development':
        return 'https://hosting-beta.uapi.newfold.com';
      case 'beta':
        return 'https://hosting-beta.uapi.newfold.com';
      case 'staging':
        return 'https://hosting-alpha.uapi.newfold.com';
      default:
        return 'https://hosting.uapi.newfold.com';
    }
  };

  useEffect(() => {
    // Set initial header with token received from the instantiating app
    axios.defaults.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    // NOTE: added to ensure CTB can fetch the latest token from localStorage
    localStorage.setItem('token', accessToken);
    // We only want this to run once, afterwards the hook handles updating the header
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isTokenExpired, refreshToken } = useJwtAutoRefresh(
    setAccessToken, // state setter
    refreshAccessToken, // refresh callback from AMM
  );

  useEffect(() => {
    if (isTestMode) {
      setReady(true);
      return;
    }

    axios.defaults.baseURL = getApiBaseUrl(appEnv, apiBaseUrl);

    // This wasn't applying before our axios calls were being made. Using axios defaults for now.
    // const instance = axios.create({
    //   baseURL: getApiBaseUrl(appEnv),
    //   headers: {
    //     Authorization: `Bearer ${accessToken}`,
    //   }
    // });

    // intercept bad tokens before they make huapi calls
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        // validate jwt has not expired before request is sent
        if (isTokenExpired(accessToken) === true) {
          // token is expired, so call the hook's refresh function manually
          const newToken = await refreshToken();

          axios.defaults.headers = {
            Authorization: `Bearer ${newToken}`,
          };

          // Update the token for the *in-flight* request in
          // addition to the token for the default header
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${newToken}`,
          };
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const config = error?.config;
        if (error?.response?.status === 401 && !config._retry) {
          config._retry = true;
          const newToken = await refreshToken();

          // Update the token for the *in-flight* request in
          // addition to the token for the default header
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${newToken}`,
          };
          return axios(config);
        }
        return Promise.reject(error);
      },
    );

    setReady(true);

    // Clean up interceptors at least with each new accessToken
    // to prevent spawning multiple concurrent interceptors
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
      // Simply ejecting the interceptors doesn't clean up the lists, so
      // we should clear them afterward to prevent infinite growth
      axios.interceptors.request.handlers = [];
      axios.interceptors.response.handlers = [];
    };
  }, [accessToken, apiBaseUrl, appEnv, isTokenExpired, refreshToken]);

  if (isDevMode && !isTestMode) {
    console.log(
      'REACT_QUERY_OFFLINE_CACHE',
      window.JSON.parse(
        window.localStorage.getItem('REACT_QUERY_OFFLINE_CACHE'),
      )?.clientState?.queries,
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      {/* Delay children until axios is properly configured */}
      {ready && children}
      {!disableReactQueryDevTools && (
        <ReactQueryDevtools {...reactQueryDevToolsConfig} />
      )}
    </QueryClientProvider>
  );
};

HttpClient.propTypes = propTypes;
export default HttpClient;
