import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useHostingNameserversV2 } from '@newfold/huapi-js';

import Copy from '~/components/Copy';
import useAccount from '~/hooks/useAccount';
import useHostingInfo from '~/hooks/useHostingInfo';

const NameserverInfo = () => {
  const { id } = useAccount();

  const {
    hostingAccount: hostingData,
    isDisabled,
    isLoading: isHostingLoading,
    isError: isHostingError,
  } = useHostingInfo();

  const isReseller =
    !isHostingLoading && !isHostingError
      ? hostingData?.data?.type === 'reseller'
      : false;

  const {
    isLoading: isDnsLoading,
    isError,
    data: dnsData,
  } = useHostingNameserversV2(id);

  const dnsList = dnsData?.data?.hosts;

  if (isDnsLoading) {
    return (
      <Stack direction="column" spacing={0.5}>
        <Stack direction="row" spacing={1}>
          <Skeleton width={185} height={24} />
          <Skeleton width={64} height={24} />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Skeleton width={155} height={24} />
          <Skeleton width={64} height={24} />
        </Stack>
      </Stack>
    );
  }

  //TODO: ask UX what to output when no name servers get found
  if (!dnsList || isError) return null;

  return (
    <Stack spacing={0.5}>
      {dnsList?.map((nameserverInfo) =>
        isReseller ? (
          <Typography key={nameserverInfo?.host} variant="body1">
            {nameserverInfo?.host}
            {` (${nameserverInfo?.ips[0]})`}
          </Typography>
        ) : (
          <Copy key={nameserverInfo?.host} disable={isDisabled}>
            <Typography sx={{ wordBreak: 'break-all' }} variant="body1">
              {nameserverInfo?.host}
            </Typography>
          </Copy>
        ),
      )}
    </Stack>
  );
};

export default NameserverInfo;
