import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { OverridableStringUnion } from '@mui/types';

import {
  useHostingAdvancedServer,
  useHostingInfoDiskUsage,
} from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import {
  productTypes,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';

import AdvancedHostingQuickLinks from './AdvancedHostingQuickLinks';
import AdvancedHostingUsageOutOfSpace from './AdvancedHostingUsageOutOfSpace';
import AdvancedHostingUsageView from './AdvancedHostingUsageView';

interface HostingData {
  disklimit: string;
  diskused: string;
}

type DiskValue = {
  percentUsed: number;
  variant: OverridableStringUnion<'success' | 'error' | 'warning' | 'primary'>;
};

const AdvancedHostingDiskUsageStats = () => {
  const { id: accountId } = useAccount();
  const { t } = useTranslation('settings', { keyPrefix: 'usageStatsCard' });
  const [value, setValue] = useState<DiskValue>({
    percentUsed: 0,
    variant: 'primary',
  });
  const [isPlesk, , clientReady] = useFeature('plesk');
  const [isWindows] = useFeature('windows');
  const [showServerControls, , isClientReady] = useFeature('server_controls');
  const { isDisabled } = useHostingInfo();
  const { data: serverData } = useHostingAdvancedServer(accountId, {
    query: {
      refetchOnWindowFocus: false,
      enabled: !!accountId && isClientReady && showServerControls,
    },
  });

  // CTB upgrade status
  const productInfo = useCtbProductInfo(productTypes.HOSTING_UPGRADE);
  const { hasPurchasedRecently } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
  );
  const doNotShowDiskUsage = isPlesk || isWindows || !clientReady;
  const isRestrictDiskUsageCall = isDisabled ? false : !doNotShowDiskUsage;
  const {
    data,
    isFetching,
    isError,
    refetch: refetchDiskData,
  } = useHostingInfoDiskUsage(accountId, {
    query: {
      enabled: !!accountId && isRestrictDiskUsageCall,
      select: ({ data }) => data as HostingData,
      onSuccess: (data) => {
        if (data?.disklimit !== 'Unlimited') {
          const percentUsed =
            (Number(data?.diskused.slice(0, -2)) /
              Number(data?.disklimit.slice(0, -2))) *
            100;
          let variant = 'success';
          if (percentUsed >= 85) variant = 'error';
          if (percentUsed < 85 && percentUsed >= 70) variant = 'warning';

          return setValue({ percentUsed, variant } as DiskValue);
        }
        return setValue({ percentUsed: 0, variant: 'primary' } as DiskValue);
      },
    },
  });

  useEffect(() => {
    if (serverData?.data?.status === 'active') {
      refetchDiskData();
    }
  }, [refetchDiskData, serverData?.data?.status]);

  if (
    hasPurchasedRecently ||
    isError ||
    doNotShowDiskUsage ||
    serverData?.data?.status === 'stopped'
  ) {
    return null;
  }

  if (value.percentUsed >= 85) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2} columns={2}>
                <Grid item md={2} lg={1}>
                  <Stack spacing={3}>
                    <Stack spacing={1}>
                      <Typography variant="h3">{t('subTitle')}</Typography>
                      <Typography variant="body2">
                        {isFetching ? (
                          <Skeleton width="80%" />
                        ) : (
                          t('advHostingDesc', {
                            percentUsed: (value?.percentUsed).toFixed(2),
                          })
                        )}
                      </Typography>
                    </Stack>
                    <Stack>
                      <AdvancedHostingUsageView
                        isFetching={isFetching}
                        value={value}
                        disklimit={data?.disklimit}
                        diskused={data?.diskused}
                      />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item md={2} lg={1}>
                  <AdvancedHostingUsageOutOfSpace />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <AdvancedHostingQuickLinks />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Card variant="outlined">
          <CardHeader
            titleTypographyProps={{ variant: 'h3' }}
            title={t('subTitle')}
            subheader={
              isFetching ? (
                <Skeleton width="80%" />
              ) : (
                t('advHostingDesc', {
                  percentUsed: (value?.percentUsed).toFixed(2),
                })
              )
            }
            subheaderTypographyProps={{ variant: 'body2' }}
          />
          <CardContent>
            <AdvancedHostingUsageView
              isFetching={isFetching}
              value={value}
              disklimit={data?.disklimit}
              diskused={data?.diskused}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <AdvancedHostingQuickLinks />
      </Grid>
    </Grid>
  );
};
export default AdvancedHostingDiskUsageStats;
