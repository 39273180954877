import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

type HostingSearchOptions = {
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
};

export default function HostingSearch({
  setSearchQuery,
}: HostingSearchOptions) {
  return (
    <form>
      <TextField
        variant="standard"
        id="hosting-search"
        placeholder="Search..."
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}
