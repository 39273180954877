import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export default function Loading() {
  return (
    <TableBody>
      {[1, 2, 3].map((key) => (
        <TableRow key={`loader-row-${key}`} data-testid={`table-loader-${key}`}>
          <TableCell>
            <Skeleton variant="text" width={120} />
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton variant="circular" height={24} width={24} />
              <Skeleton variant="text" height={24} width={60} />
            </Stack>
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={60} />
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={100} />
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Stack direction="row" alignItems="center" justifyContent="end">
              <Skeleton variant="rounded" width={100} height={36} />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
