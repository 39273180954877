import { bool, shape, string } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PublicIcon from '@mui/icons-material/Public';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { useSitesDomain } from '@newfold/huapi-js';

import StatusListItem from '~/components/StatusListItem';
import { useSiteContext } from '~/scenes/Site';

import BackupStatus from './BackupStatus';
import SslStatus from './SslStatus';

const propTypes = {
  siteInfo: shape({
    url: string,
    version: string,
  }),
  loading: bool,
};

const SiteInformation = ({
  siteInfo = {
    url: '',
    version: '',
  },
  loading = false,
}) => {
  const { t } = useTranslation('site');
  const navigate = useNavigate();

  // @ts-ignore
  const { isSiteOrHostingDisabled: isDisabled } = useSiteContext();

  const handleLink = (url) => {
    if (siteInfo?.pending_url)
      return navigate(url, { state: { visited: true } });
    navigate(url);
  };

  const { data: dnsStatus } = useSitesDomain(
    siteInfo?.id,
    {},
    {
      query: {
        retry: 5,
        retryDelay: (attempt) => attempt * 15000, // add 15 seconds per retry
        enabled: !!siteInfo?.id && !!siteInfo?.pending_url,
        select: ({ data }) => ({
          aMatch: data?.a_match,
        }),
      },
    },
  );

  const domainUpdateInProgress = siteInfo?.pending_url && dnsStatus?.aMatch;

  const urlStatus =
    (!siteInfo?.pending_url && !siteInfo?.url && 'empty') ||
    (domainUpdateInProgress && 'updateInProgress') ||
    (siteInfo?.pending_url && 'pendingUrl') ||
    'url';

  const siteUrlCta = {
    text: t(`urlCta.${urlStatus}.text`),
    link: () =>
      handleLink(t(`urlCta.${urlStatus}.link`, { siteId: siteInfo?.id })),
  };

  return (
    <Grid item xs={4} sm={4} md={4}>
      <Typography variant="h3">{t('webInfoTitle')}</Typography>
      <List dense>
        <StatusListItem
          disableCTA={isDisabled}
          isLoading={loading}
          avatarIcon={PublicIcon}
          avatarIconColor={urlStatus === 'empty' ? 'error' : 'success'}
          tooltipText={
            siteInfo?.pending_url && (
              <Trans
                i18nKey="site:pendingUrlTooltip"
                values={{
                  pendingUrl: siteInfo?.pending_url,
                }}
              />
            )
          }
          ctaText={siteUrlCta.text}
          handleCTA={siteUrlCta.link}
          tooltipTitle={`${t('urlLabel')} ${
            siteInfo?.pending_url || siteInfo?.url
          }`}
          updateInProgress={domainUpdateInProgress}
        >
          {t('urlLabel')}{' '}
          {siteInfo?.pending_url || siteInfo?.url ? (
            <Link href={siteInfo?.pending_url || siteInfo?.url} target="_blank">
              {siteInfo?.pending_url || siteInfo?.url}
            </Link>
          ) : (
            <>{t('urlNotAvailable')}</>
          )}
        </StatusListItem>
        <SslStatus siteId={siteInfo?.id} />
        <BackupStatus siteId={siteInfo?.id} siteType={siteInfo?.type} />
      </List>
    </Grid>
  );
};

SiteInformation.propTypes = propTypes;
export default SiteInformation;
