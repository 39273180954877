import { bool, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { number } from 'yup';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

import SiteInformation from './SiteInformation';
import SiteThumbnail from './SiteThumbnail';
import UpdateStatus from './UpdateStatus';

const propTypes = {
  siteInfo: shape({
    id: number,
    url: string,
    version: string,
    type: string,
  }),
  loading: bool,
};

const OverviewCard = ({
  siteInfo = {
    id: '',
    url: '',
    version: '',
    type: '',
  },
  loading = false,
}) => {
  const { t } = useTranslation('site');

  return (
    <Card>
      <CardHeader title={t('cardTitle')} />
      <CardContent>
        <Grid
          container
          spacing={4}
          columns={{ xs: 4, sm: 4, md: 12 }}
          alignItems="flex-start"
        >
          <SiteThumbnail isLoadingSite={loading} siteInfo={siteInfo} />
          <SiteInformation loading={loading} siteInfo={siteInfo} />
          <UpdateStatus siteInfo={siteInfo} />
        </Grid>
      </CardContent>
    </Card>
  );
};

OverviewCard.propTypes = propTypes;
export default OverviewCard;
