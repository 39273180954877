import { bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InfoListItem from '~/components/InfoListItem';
import useFeature from '~/hooks/useFeature';

import NameserverInfo from '../../../../components/NameserverInfo';
import ServerManagement from '../../../../components/ServerManagement';
import SshAccessInfo from '../../../../components/SshAccessInfo';
import FtpAccessInfo from '../FtpAccessInfo';
import IPAddressInfo from '../IPAddressInfo';
import ResetPassword from '../ResetPassword';
import Wrapper from './Wrapper';

const propTypes = {
  container: object,
  hasPurchasedRecently: bool,
};
const ServerInfo = ({ container = {}, hasPurchasedRecently = false }) => {
  const { t } = useTranslation('site');
  // @ts-ignore
  const [isSites] = useFeature('sites');
  const isShared = isSites;

  const [isPlesk, plesk] = useFeature('plesk');
  const [isWindows, windows] = useFeature('windows');
  const [isAdvancedHosting] = useFeature('advanced_hosting');
  const [showServerControls] = useFeature('server_controls');
  const [isReseller] = useFeature('reseller');

  /* @ts-ignore */
  const { nameservers } = t('quickConnect.items', { returnObjects: true });

  //hosting type reseller
  if (isReseller) {
    return (
      <Wrapper hasPurchasedRecently={hasPurchasedRecently}>
        <Grid container columns={12} display="flex">
          <Grid item xs={12} md={6} flexDirection="column">
            {/* @ts-ignore */}
            <InfoListItem
              key={nameservers?.id}
              title={nameservers?.label}
              tooltipText={nameservers?.tooltipText}
            >
              {/* @ts-ignore */}
              <NameserverInfo />
            </InfoListItem>
            <IPAddressInfo />
          </Grid>
        </Grid>
      </Wrapper>
    );
  }

  // hasPlesk , hasWindows.
  if (isPlesk || isWindows) {
    return (
      <Wrapper hasPurchasedRecently={hasPurchasedRecently}>
        <Grid container columns={12} display="flex">
          <Grid item xs={12} md={6} flexDirection="column" display="flex">
            <Typography variant="body2">
              <b>{t('advancedServer.username')}: </b>
              {plesk?.username || windows?.username}
            </Typography>
            <IPAddressInfo />
          </Grid>
          <Grid item xs={12} md={6} flexDirection="column" display="flex">
            {/* @ts-ignore */}
            <ResetPassword />
          </Grid>
        </Grid>
      </Wrapper>
    );
  }

  // Advanced-hosting => vps,dedi,vps_cpanel,dedi_cpanel,dedi_windows,dedi_plesk.
  if (isAdvancedHosting) {
    return (
      <Wrapper
        container={container}
        hasPurchasedRecently={hasPurchasedRecently}
      >
        {showServerControls && <ServerManagement />}
        <Grid container columns={12} display="flex">
          <Grid item xs={12} md={6} flexDirection="column">
            <IPAddressInfo />
          </Grid>
          <Grid item xs={12} md={6} flexDirection="column" display="flex">
            <SshAccessInfo />
            <ResetPassword />
            <FtpAccessInfo />
          </Grid>
        </Grid>
      </Wrapper>
    );
  }

  // shared
  if (isShared) {
    return (
      <Wrapper hasPurchasedRecently={hasPurchasedRecently}>
        <Grid container columns={12} display="flex">
          <Grid item xs={12} md={6} flexDirection="column">
            {/* @ts-ignore */}
            <InfoListItem
              key={nameservers?.id}
              title={nameservers?.label}
              tooltipText={nameservers?.tooltipText}
            >
              {/* @ts-ignore */}
              <NameserverInfo />
            </InfoListItem>
            <IPAddressInfo />
          </Grid>
          <Grid item xs={12} md={6} flexDirection="column" display="flex">
            <SshAccessInfo />
            <ResetPassword />
            <FtpAccessInfo />
          </Grid>
        </Grid>
      </Wrapper>
    );
  }
};
ServerInfo.propTypes = propTypes;
export default ServerInfo;
